import React, { Component } from 'react'
import config from './config'
import axios from 'axios'
import Swal from 'sweetalert2';

export function alertError(error) {
    Swal.fire({
        title: 'Error',
        text: error,
        icon: 'error'
    });
}
