import React from 'react';
import CTableColumn from '../CTableColumn/CTableColumn';



const cTableRow = (props) => {

    const columns = props.row;

    let attributes = props.attributes;
    let color = props.color;
    let tableID = props.tableID;



    const info = columns.map((label,index) => {
        let attribute = null;
        if(attributes != undefined && attributes != null){
            attribute = attributes[index];
        }





        return (
            <CTableColumn key={tableID + "-cTableColumn-" + index} label={label} attribute={attribute}/>
        );
    });


    return (
        <tr className="highlight-row" style={{backgroundColor: color}}>
            {info}
        </tr>
    );




};

export default cTableRow;
