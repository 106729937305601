import React, { useState }  from 'react';
import { withRouter } from 'react-router';
import config from '../../../utilities/config';
// import './TopUsingRooms.scss';
// import '../DashboardBlocks/GeneralBlocks.scss';
// import TopUsingRoomBlock from './TopUsingRoomBlock.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { CSVLink } from 'react-csv';

const TempHumidityByRoom = (props) => {
    const buildings = props.buildings;
    //console.log(buildings);
    const thDataSet = props.metrics;
    const thKeys = props.keys;
    let buildingsWithRooms = {};
    let usageBlocks = [];
    const [tempBlocks, setTempBlocks] = useState(null);
    let tempDataSet  = [];

    for(let i=0; i<thKeys.length; i++) {
        let curData = thDataSet[thKeys[i]];
        for (let k=0; k<curData.length; k++) {
            const temp = Math.round(((curData[k].temperature * 9 / 5 + 32) * 100) / 100) 
            tempDataSet.push(temp);
        }
    }


    const createUsageBlocks = (building, buildingLocations) => {
        buildingLocations.forEach((location) => {
            // if (buildingsWithRooms[building.label] == null) {
            //     buildingsWithRooms[building.label] = [];
            // }
            // else {
            //     buildingsWithRooms[building.label].push(location.locationName);
            // }
            var randomTemp = tempDataSet[Math.floor(Math.random()*tempDataSet.length)];
            const tempRow = (
                <tr onClick={() => {
                    props.history.push(`/room-view?roomName=${location.locationName}`)
                    config.selectedBuilding = building.id;
                    localStorage.setItem("mlBuildingID", building.id);
                }}
                    style={{ cursor: 'pointer' }}
                >
                    <td>{building.label}</td>
                    <td>{location.locationName}</td>
                    <td>{randomTemp.toLocaleString()}</td>
                </tr>
            )
            usageBlocks.push(tempRow);
        })
        setTempBlocks(usageBlocks);
        //console.log(buildingsWithRooms);
    }

    buildings.forEach((building) => {
        getBuildingLocations(building);
    });

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <p className="blocksLabel">Wasted Water By Location</p>
                </Col>
            </Row>
            <Row style={{ "height": "85%", "overflow": "auto" }} >
                <Table striped responsive >
                    <thead>
                        <tr>
                            <th>Building</th>
                            <th>Location</th>
                            <th>Temperature</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tempBlocks}
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
};

export default withRouter(TempHumidityByRoom);