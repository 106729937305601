import React from 'react';
import './LeakCost.scss';
import '../DashboardBlocks/GeneralBlocks.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const LeakCost = (props) => {

    let leakCost = Number(props.leakCost).toLocaleString();

    return (
        <Container className="leakCostBackground">
            <Row style={{"height": "25%"}} >
                <p className="blocksLabel">Cost of Gallons Leaked</p>
            </Row>
            <Row style={{"height": "75%"}} >
                <p className="leakCostLeaked">${leakCost}</p>
            </Row>
        </Container>
    );
};

export default LeakCost;