import React from 'react';
import './ReportTable.css';
import ReportTableKey from './ReportTableKey';
import ReportTableHead from './ReportTableHead';
import ReportTableBody from './ReportTableBody';
import ReportHead from './ReportHead';
import moment from 'moment';
 



const reportTable = (props) => {

    let reportData = props.data;
    let locationData = reportData["locationStats"][""];

    const roomNum = reportData["roomNum"];

    const totalAlerts = reportData["totalAlerts"];
    const gallonsUsed = reportData["gallonsUsed"];
    const gallonsWasted = reportData["gallonsWasted"];
    const totalCost = reportData["totalCost"];
    const costOfLeaks = reportData["costOfLeaks"];
    const buildingName = reportData["buildingName"];
    const sDate = reportData["sDate"];
    const eDate = reportData["eDate"];

    const sDateF = moment(sDate).format('MM/DD/YYYY');
    const eDateF = moment(eDate).format("MM/DD/YYYY");
    const dateLabel = sDateF + " - " + eDateF; 

    return (
        <div style={{"marginBottom": "200px"}}>
            <ReportHead dateLabel={dateLabel} buildingName={buildingName} roomNum={roomNum} alertsRaised={totalAlerts} gallonsUsed={gallonsUsed} leakGallonsUsed={gallonsWasted} cost={totalCost} leakGallonsCost={costOfLeaks} />
            <ReportTableKey />
            <table className='blueTable' width='1000'>
                <ReportTableHead dateLabel={dateLabel}/>
                <ReportTableBody locationData={locationData} />
            </table>
        </div>
        
    );


}

export default reportTable;