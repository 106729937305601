var AdvSch = {
    "User": null,
    "Org":null,
    "Prop":null,
    "Building":null,
    "Locs":null,
    "Device":null
  }
  
  
  export default AdvSch;