import React from 'react';
import Table from '../Table/Table';
import {numberWithCommas} from '../../UniversalFunctions/UniversalFunctions';


const leakTable = (props) => {

    let leakData = props.leakData;

    let data = [];
    let rows = [];

    let leakKeys = Object.keys(leakData);
    let objBD = {}

 

    for(let i =0 ; i < leakKeys.length ; i++){

        let curBuilding = leakKeys[i];
        let obj = leakData[curBuilding];
        let roomKeys = Object.keys(obj);
        for(let j = 0 ; j < roomKeys.length ; j++){
            let roomKey = roomKeys[j];
            let roomObj = obj[roomKey];
            let parts = roomKey.split("###");
            let date = parts[0];

            
            if (date in objBD){
                let tmpRoomObj = objBD[date];
                tmpRoomObj.push(roomObj);
                objBD[date] = tmpRoomObj;
            }
            else{
                objBD[date] = [roomObj];
            }
        

        }


    }

   


 
    let dateKeys = Object.keys(objBD);
    dateKeys.sort();
    let colors = []
    let attributes = [];
    for(let i = 0 ; i < dateKeys.length; i++){
        let dateKey = dateKeys[i];
        let curObjs = objBD[dateKey];

        for(let j =0 ; j < curObjs.length; j++){
            let curObj = curObjs[j];
            let date = curObj["date"];
            let building = curObj["buildingLabel"];
            let location = curObj["locationLabel"];
            let gallonsWN = numberWithCommas( parseInt(curObj["gallons"]));
            let gallons = parseInt(curObj["gallons"]);
            let severityNum = curObj["severityNum"];
            
            let color = null;
            let locationID = curObj["locationID"];
            if(severityNum == 1){
                color = "#00FFFF";
            }
            if(severityNum == 2){
                color = "yellow";
            }
            if(severityNum == 3){
                color = "orange";
            }
            if(severityNum >= 4){
                color = "tomato";
            }
            
            let asb = {"name": "alertStatButton" , "id" : locationID};
            let row = [date , building , location, gallonsWN , "filler"];


            if (gallons > 0){

                colors.push(color);
                attributes.push([null , null , null , null , asb]);
                rows.push(row);
            }
            
            
        }


    }



   let headers = ["Date" , "Building" , "Room" , "Gallons Wasted"];
   let tableID = "leak-table";
   let styles = {width:"100%" , marginBottom: "50px"};
    




   return (
       <Table id={tableID} rows={rows} headers={headers} styles={styles} colors={colors} attributes={attributes} />
   )


    
};



export default leakTable;