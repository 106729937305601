import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Table from 'react-bootstrap/Table';
import DataGraph from '../../components/RawData/DataGraph';
import Dropdown from 'react-bootstrap/Dropdown';
import "./RawData.css";

var batteryData = [];
var signalData = [];
var keys = [];

class RawDataViewGraphs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            loadeddevices: false,
            deviceDetails: null,
            allDevices: [],
        };
    }

    handleSubmit = (timespan) => {
        const devSerialNum = config.DeviceID.name;
        if (devSerialNum == null || devSerialNum == undefined) {
            return;
        }
        var endTime = null;
        var startTime = null;
        switch (timespan) {
            case 1:
                endTime=-1;
                startTime=-1;
                break;
            case 7:
                endTime = Date.now();
                startTime = endTime - (7 * 86400000);
                
                break;
            case 30:
                endTime = Date.now();
                startTime = endTime - (30 * 86400000);
                break;
        }
        if (devSerialNum.length < 13) {
            this.getDeviceSQL(devSerialNum, startTime,endTime);
        }
        else {
            this.getDevice(devSerialNum, startTime,endTime);
        }
    }

    handleCustomSubmit = (start,end) => {
        //console.log(start, end);
        const devSerialNum = config.DeviceID.name;
        if (devSerialNum == null || devSerialNum == undefined) {
            return;
        }
        if (devSerialNum.length < 13) {
            this.getDeviceSQL(devSerialNum, start,end);
        }
        else {
            this.getDevice(devSerialNum, start,end);
        }
    }

    

    getDevice(deviceSerialNum, startTime,endTime) {
        //Call to /device/<deviceSerialNumber> 
        //where <deviceSerialNumber> is a single device's serial number (previously called the Device ID)
        //to get SQL ID of the device and the device details
        axios({
            method: 'get',
            url: `${config.api}devices/${deviceSerialNum}`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            }
        })
            .then((response) => {
                if (response.data.length == 0) {
                    Swal.fire({
                        icon: "error",
                        title: "No Data Found for Device",
                        text: 'Try a different timespan'
                    })
                    return;
                }

                const sqlID = response.data[0].id;

                this.getRawMetrics(sqlID, startTime,endTime, response.data[0]);
            })
            .catch((error) => {
                //console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Error Finding Device",
                    text: error
                })
            });
    }

    getDeviceSQL(deviceSerialNum, startTime,endTime) {
        //Call to /device/<deviceID> 
        //where <deviceID> is a single device's SQL ID
        //to get the device details
        axios({
            method: 'get',
            url: `${config.api}devices/${deviceSerialNum}`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            }
        })
            .then((response) => {
                if (response.data.length == 0) {
                    Swal.fire({
                        icon: "error",
                        title: "No Data Found for Device",
                        text: 'Try a different timespan'
                    })
                    return;
                }

                this.getRawMetrics(deviceSerialNum, startTime,endTime, response.data[0]);
            })
            .catch((error) => {
                //console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Error Finding Device",
                    text: error
                })
            });
    }

    getRawMetrics(sqlID, startTime,endTime, deviceInfo) {
        let urlDated = `${config.api}metrics/raw/devices?deviceIDs=${sqlID}&startTime=1622649426000&endTime=1624982226000&limit=100000`;
        signalData = [];
        batteryData = [];
        keys = [];

        if(endTime==-1 && startTime==-1){
            urlDated = `${config.api}metrics/raw/devices?deviceIDs=${sqlID}`
        }
        else{
            urlDated = `${config.api}metrics/raw/devices?deviceIDs=${sqlID}&startTime=${startTime}&endTime=${endTime}&limit=100000`
        }


        //Call to /metrics/raw/devices?deviceIDs=<sqlID>
        //where <sqlID> is the ID of the device in the database
        //to get the latest metrics sent by the seletced device
        axios({
            method: 'get',
            url: urlDated,
            headers: {
                'Authorization': `Bearer ${config.token}`,
            }
        })
            .then((response) => {
                if (response.data.length == 0) {
                    Swal.fire({
                        icon: "error",
                        title: "No Data Found for Device",
                        text: 'Try a different timespan'
                    })
                    return;
                }
                const resObj = response.data;

                //console.log(resObj);

                resObj.forEach((obj, index) => {

                    let batteryVoltage = obj.batteryV;
                    if (batteryVoltage != null) {
                        batteryVoltage = String(batteryVoltage);
                        batteryVoltage = batteryVoltage.substring(0, 1) + '.' + batteryVoltage.substring(1);
                    }
                    let deliveredTime = obj.deliveredTime;
                    if (deliveredTime != null) {
                        deliveredTime = new Date(deliveredTime).toLocaleString()
                    }

                    if (index == 0) {
                        const deviceDetails = [
                            <tr>
                                <td>Battery Voltage:</td>
                                <td>{batteryVoltage}</td>
                            </tr>,
                            <tr>
                                <td>Last Communicated:</td>
                                <td>{deliveredTime}</td>
                            </tr>,
                            <tr>
                                <td>Location:</td>
                                <td>{obj.locationID}</td>
                            </tr>,
                            <tr>
                                <td>Signal Strength:</td>
                                <td>{obj.rssi}</td>
                            </tr>,
                        ]
                        this.getDeviceLocationInfo(deviceInfo.locationID, deviceDetails, deviceInfo);
                    }

                    signalData.push(Math.abs(obj.rssi));
                    batteryData.push(Number(batteryVoltage));
                    keys.push(deliveredTime);

                })
                this.createGraphs();

                this.setState({
                    loaded: true
                });
            })
            .catch((error) => {
                //console.log(error);
                if (String(sqlID).includes('-')) {
                    Swal.fire({
                        icon: "error",
                        title: "Can't find that device",
                        text: 'Double check your Device ID'
                    })
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Error Getting Raw Data",
                        text: error
                    })
                }
            });
    }

    getDeviceLocationInfo = (locID, deviceDetails, deviceInfo) => {
        axios({
            method: 'get',
            url: `${config.api}get-all-buildings`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            }
        })
            .then((response) => {
                const buildingData = response.data;

                axios({
                    method: 'get',
                    url: `${config.api}locations/${locID}`,
                    headers: {
                        'Authorization': `Bearer ${config.token}`
                    }
                })
                    .then((response) => {
                        var curLocation="";
                        var buildingID="";
                        if(response.data[0] !=null){
                            curLocation = response.data[0].locationLabel;
                            buildingID = response.data[0].buildingID;
                        }
                        else{
                            curLocation ="None";
                            buildingID = "None";
                        }
                
                        let curBuilding = "";
                        buildingData.forEach((building) => {
                            if (building.id == buildingID) {
                                curBuilding = building.label;
                            }
                        });

                        deviceDetails[2] = (
                            <tr>
                                <td>Location:</td>
                                <td>{curLocation}, {curBuilding}</td>
                            </tr>
                        )
                        this.setState({
                            deviceDetails: deviceDetails
                        });
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: "error",
                            title: "Error Getting Location Info",
                            text: error
                        })
                        this.setState({
                            deviceDetails: deviceDetails
                        });
                    });

            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error Getting Building Info",
                    text: error
                })
                this.setState({
                    deviceDetails: deviceDetails
                });
            });
    }

    createGraphs = () => {
        switch (this.state.combinedGraph) {
            case true:
                this.setState({
                    graphs: (
                        <Col xs={12}>
                            <DataGraph keys={keys} signalData={signalData} batteryData={batteryData} detailedLabels={this.state.detailedLabels} title="Device Info" />
                        </Col>
                    )
                })
                break;
            default:
                this.setState({
                    graphs: (
                        <Col xs={12}>
                            <DataGraph keys={keys} signalData={signalData} detailedLabels={this.state.detailedLabels} title="Signal Strength" />
                            <DataGraph keys={keys} batteryData={batteryData} detailedLabels={this.state.detailedLabels} title="Battery Voltage" />
                        </Col>
                    )
                })
                break;
        }
    }

    render() {
            return (
                <Container>
                    <Row>
                        <Col style={{maxWidth: 400,marginTop: 24 }}>
                            <ButtonGroup>
                                <Button onClick={() => { this.handleSubmit(1) }} variant="primary" id="rawDataSubmitButton" style={{ marginBottom: 24 }}>Get Last</Button>
                                <Button onClick={() => { this.handleSubmit(7) }} variant="primary" id="rawDataSubmitButton" style={{ marginBottom: 24 }}>7 Days</Button>
                                <Button onClick={() => { this.handleSubmit(30) }} variant="primary" id="rawDataSubmitButton" style={{ marginBottom: 24 }}>30 Days</Button>
                            </ButtonGroup>
                            </Col>
                            <Col style={{ marginTop: 24 }}>
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ marginBottom: 24 }} >Custom</Dropdown.Toggle>

                                <Dropdown.Menu >
                                    <Row class="date" style={{marginLeft: 12 }}>
                                        Start date:<input type="datetime-local" id="start_date" class="date" style={{ width: "75%", marginBottom: 24, marginRight: 12, marginLeft: 12 }} />
                                    </Row>
                                    <Row class="date" style={{marginLeft: 12 }}>
                                        End date:<input type="datetime-local" id="end_date" class="date" style={{ width: "75%", marginBottom: 24, marginRight: 12, marginLeft: 12 }} />
                                    </Row>
                                    <Row style={{marginLeft: 12 }}>
                                        <Button onClick={() => { this.handleCustomSubmit(document.getElementById("start_date").valueAsDate.getTime(),document.getElementById("end_date").valueAsDate.getTime()) }} variant="primary" id="rawDataSubmitButton" style={{ marginBottom: 24, marginRight: 12, marginLeft: 12 ,width: "75%"}}>Submit </Button>
                                    </Row>
                                </Dropdown.Menu>
                            </Dropdown>
                            </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col>

                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Table bordered striped>
                                <thead>
                                </thead>
                                <tbody>
                                    {this.state.deviceDetails}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ButtonGroup style={{ marginBottom: 12, marginLeft: 12 }}>
                                <Button variant="light" active={!this.state.combinedGraph} onClick={() => { this.setState({ combinedGraph: false }, this.createGraphs) }} >Seperate Graphs</Button>
                                <Button variant="light" active={this.state.combinedGraph} onClick={() => { this.setState({ combinedGraph: true }, this.createGraphs) }} >Combined Graph</Button>
                            </ButtonGroup>
                            <ButtonGroup style={{ marginBottom: 12, marginLeft: 12 }}>
                                <Button variant="light" active={!this.state.detailedLabels} onClick={() => { this.setState({ detailedLabels: false }, this.createGraphs) }} >Short Labels</Button>
                                <Button variant="light" active={this.state.detailedLabels} onClick={() => { this.setState({ detailedLabels: true }, this.createGraphs) }} >Detailed Labels</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>

                    {this.state.graphs}

                </Container >
            );
    }
}

export default withRouter(RawDataViewGraphs);