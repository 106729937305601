import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';
import Reaptcha from 'reaptcha';
import './ChangePassword.scss';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import config from '../../utilities/config';
import Col from 'react-bootstrap/Col';
import WaterRateTable from './WaterRateTable';



class DemoToolsView extends Component{

    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div>
                <div style={{'paddingTop': '20px', 'textAlign': 'center'}}>
                    <Button variant="outline-primary" href="/calculator" >Water Waste Calculator</Button>
                </div>
                <hr />
                <div style={{'paddingTop': '20px', 'textAlign': 'center'}}>
                    {/* <Button className="settings-logout" variant="outline-primary" href="/calculator" >Change Water Rates</Button> */}
                    <WaterRateTable />
                </div>
            </div>
        );

    }

}


export default withRouter(DemoToolsView);