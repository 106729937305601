import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';
import Reaptcha from 'reaptcha';
import './ChangePassword.scss';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import config from '../../utilities/config';
import Col from 'react-bootstrap/Col';
import BuildingReportView from '../Tools/BuildingReportView';
import { Row } from 'react-bootstrap';
import { getOrgProperties } from '../../utilities/api.js';
import MacAddressReportView from '../Tools/MacAddressReportView';



class ChangePassword extends Component{
    
    constructor(props) {
        super(props);
        this.state = { 
            selectedProperty: null,
            allProperties: null,
            propertyOptions: null,
            display: null
        };
        this.createPropertyOptions();
    }

    async createPropertyOptions() {

        const propertyData = await getOrgProperties();
        if (propertyData.error) {
            Swal.fire({
                title: 'Error',
                text: propertyData.error,
                icon: 'error'
            });
            return;
        }
        let propertyOptions = []
        //console.log("PROPERTY DATA");
        //console.log(propertyData.data);
        if(propertyData.data != "undefined"){
            propertyOptions = propertyData.data.map((property) => {
                return(
                    <option value={property.id}>{property.label}</option>
                );
            });
        }
        this.setState({
            propertyOptions: propertyOptions
        });
    }

    handleChange = (event) => {
        this.setState({
            selectedProperty: event.target.value
        });
    }

    handleClick = (clicked) => {
        this.setState({
            display: clicked
        })
    }
    
    render(){
        switch(this.state.display) {
            case 'property':
                return (
                    <div>
                        <Row style={{paddingTop: '12px', height: '20%'}}>
                            <Col xs={2}>
                                <Button variant='outline-secondary' style={{float: 'left', marginLeft: '12px'}} onClick={() => this.handleClick('back')}>Back</Button>
                            </Col>
                            <Col xs={8}>
                                <h3 style={{textAlign: 'center'}}>Property Report</h3>
                            </Col>
                            <Col xs={2}>
                                <Button variant='outline-primary' style={{float: 'right', marginRight: '12px'}} onClick={() => this.exportCSV()}>Export as CSV</Button>
                            </Col>
                        </Row>
                        <hr />
                        <Row style={{paddingTop: '12px', height: '80%'}}>
                            <BuildingReportView propertyID={this.state.selectedProperty} />
                        </Row>
                    </div>
                );
            case 'mac-address':
                return (
                    <div>
                        <Row style={{paddingTop: '12px', height: '20%'}}>
                            <Col xs={2}>
                                <Button variant='outline-secondary' style={{float: 'left', marginLeft: '12px'}} onClick={() => this.handleClick('back')}>Back</Button>
                            </Col>
                            <Col xs={8}>
                                <h3 style={{textAlign: 'center'}}>MAC Addresses Report</h3>
                            </Col>
                            <Col xs={2}>
                                <Button variant='outline-primary' style={{float: 'right', marginRight: '12px'}} onClick={() => this.exportCSV()}>Export as CSV</Button>
                            </Col>
                        </Row>
                        <hr />
                        <Row style={{paddingTop: '12px', height: '80%'}}>
                            <MacAddressReportView propertyID={this.state.selectedProperty} />
                        </Row>
                    </div>
                );
            default:
                return (
                    <div>
                        <div style={{'paddingTop': '20px'}}>
                            <h3 style={{'paddingTop': '20px', 'paddingLeft': '30px'}}>Property Reports</h3>
                            <div style={{width: '50%', marginLeft: '25%', marginRight: '25%'}}>
                                {/* <Form.Label>Select A Property</Form.Label> */}
                                <Form.Control id="selectedProperty" onChange={this.handleChange} as="select">
                                    <option value={false}>Select A Property</option>
                                    {this.state.propertyOptions}
                                </Form.Control>
                            </div>
                        </div>
                        <hr />
                        <div style={{'paddingTop': '20px'}}>
                            <h3 style={{'paddingTop': '20px', 'paddingLeft': '30px'}}>Generate A Report</h3>
                            <div style={{width: '30%', marginLeft: '30%', marginRight: '30%', justifyContent: 'center', alignItems: 'center'}}>
                                <Button variant='outline-primary' disabled={!this.state.selectedProperty} style={{width: '100%'}} onClick={() => this.handleClick('property')}>Daily Property</Button>
                                <Button variant='outline-primary' disabled={!this.state.selectedProperty} style={{width: '100%', marginTop: '24px'}} onClick={() => this.handleClick('mac-address')}>MAC Addresses</Button>
                            </div>
                        </div>
                    </div>
                );
        }
    }

}


export default withRouter(ChangePassword);