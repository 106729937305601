import React, { Component } from 'react';
import classNames from 'classnames';
import './HeatMapChart.css';
import axios from 'axios';
import config from '../../../utilities/config.js';
import ChartComponent from '../ChartComponent/ChartComponent';
import ChartTable from '../../Table/ChartTable/ChartTable';
import gradImg from '../../../images/gradient2.png';
// import Gradient from './Gradient';

class HeatMapChart extends Component {
  // Give some empty data on construction
  constructor() {
    super();
    this.state = {
      mode: 'pressure',
      floor: 1,

      rooms: { 1: [] },
      pressures: { 1: [] },
      leaks: { 1: [] },
    };
  }

  componentDidMount = () => {
    let url = `${config.api}buildings/${config.selectedBuilding}/coordinates`;
        //get all the buildings for user. If user has only one building, get that building (building), 
        //otherwise, get all building information in the summary.

    axios.get(
        url,
        {
            headers: { Authorization: `Bearer ${config.token}` }
        }
      )

      .then((response) => {
        if (response.data) {
          this.updateGraphState(response.data['buildingData']);
        } else {
          this.updateGraphState(null);
        }
        url = `${config.api}portalStats/building/${config.selectedBuilding}/leaks?startDate=${config.startDate}&endDate=${config.endDate}`;
        //get all the buildings for user. If user has only one building, get that building (building), 
        //otherwise, get all building information in the summary.

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
          )

        .then((response) => {
          //console.log(response.data);
          this.updateLeakState(response.data);
        });



        url = `${config.api}portalStats/building/${config.selectedBuilding}/pressures`;
            //get all the buildings for user. If user has only one building, get that building (building), 
            //otherwise, get all building information in the summary.

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )

        .then((response) => {
          //console.log(response.data);
          this.updatePressureState(response.data);
        });
      });



    
  };

  // handlers for traversing through the floors of the building
  nextFloor = () => {
    const floors = Object.keys(this.state.rooms);
    const ind = floors.findIndex((element) => element === this.state.floor);
    if (ind + 1 < floors.length) {
      const newFloor = floors[ind + 1];
      this.setState({ floor: newFloor });
    }
  };

  prevFloor = () => {
    const floors = Object.keys(this.state.rooms);
    const ind = floors.findIndex((element) => element === this.state.floor);
    if (ind - 1 >= 0) {
      const newFloor = floors[ind - 1];
      this.setState({ floor: newFloor });
    }
  };

  updateGraphState = (newRooms) => {
    if (newRooms) {
      this.setState({
        rooms: newRooms,
        floor: Object.keys(newRooms)[0],
      });
    }
  };

  updatePressureState = (newPressures) => {
    this.setState({
      pressures: newPressures,
    });
  };

  updateLeakState = (newLeaks) => {
    this.setState({
      leaks: newLeaks,
    });
  };

  // functions to parse json files
  parsePressures = (event) => {
    const fileList = event.target.files;
    const reader = new FileReader();
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      // fileList.forEach((file) => {
      reader.readAsText(file);
      reader.onload = () => {
        const arr = JSON.parse(reader.result);
        // //console.log(arr);
        this.updatePressureState(arr);
      };
    }
  };

  parseRooms = (event) => {
    const fileList = event.target.files;
    const reader = new FileReader();
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      // fileList.forEach((file) => {
      reader.readAsText(file);
      reader.onload = () => {
        const arr = JSON.parse(reader.result);
        const keys = Object.keys(arr);
        const newRooms = {};

        keys.forEach((key) => {
          newRooms[key] = arr[key];
          // //console.log(key);
          // //console.log(arr[key]);
        });

        // //console.log('new Rooms');
        // //console.log(newRooms);
        this.updateGraphState(newRooms);
      };
    }
  };

  parseLeaks = (event) => {
    const fileList = event.target.files;
    const reader = new FileReader();
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      // fileList.forEach((file) => {
      reader.readAsText(file);
      reader.onload = () => {
        const arr = JSON.parse(reader.result);
        // //console.log(arr);
        this.updateLeakState(arr);
      };
    }
  };

  // handlers for updating data from json
  updateRooms = (event) => {
    // const newData =
    this.parseRooms(event);
    // //console.log(newData);
  };

  updatePressures = (event) => {
    // const newData =
    this.parsePressures(event);
    // //console.log(newData);
  };

  updateLeaks = (event) => {
    this.parseLeaks(event);
  };

  render() {
    const { rooms, floor, mode } = this.state;
    let chartData = null;

    if (mode === 'pressure') {
      chartData = this.state.pressures[this.state.floor];
    } else if (mode === 'leaks') {
      chartData = this.state.leaks[this.state.floor];
    }

    const roomKeys = Object.keys(rooms);

    return (
      <div className="Layout ChartContainer container">
        <div className="Row py-3">
          <img className="RowElement Gradient" src={gradImg} alt="gradient2" />
          <ChartComponent
            className="RowElement"
            rooms={this.state.rooms[this.state.floor]}
            data={chartData}
            mode={mode}
          />
          <div className="RowElement my-0">
            <div className="d-flex">
              <span
                className={classNames("btn chart-nav-icon", {"disabled": floor == roomKeys[0] })}
                onClick={() => { this.prevFloor(); } }
              >&#8249;</span>
              <select
                className="form-control d-flex align-self-stretch"
                onChange={(evt) => { this.setState({floor: evt.target.value}); }}
                value={floor}
              >
                {Object.entries(rooms).map(([k, v]) => 
                  <option key={k} value={k}>Floor {k}</option>
                )}
              </select>
              <span
                className={classNames("btn chart-nav-icon", {"disabled": floor == roomKeys[roomKeys.length - 1] })}
                onClick={() => { this.nextFloor(); } }
              >&#8250;</span>
            </div>
            <div className="">
            <div className="btn-group-custom" aria-label="Chart Mode">
              <button
                type="button"
                className={
                  classNames("btn btn-outline-brand", {
                    active: (mode === 'pressure')
                  })}
                onClick={() => { this.setState({mode: 'pressure'})}}
                >Pressure</button>
              <button
                type="button"
                className={classNames("btn btn-outline-brand", {
                  active: (mode === 'leaks')
                })}
                onClick={() => { this.setState({mode: 'leaks'})}}
              >Leaks</button>
            </div>
            </div>
            <div className="Table">
              <ChartTable data={chartData} mode={this.state.mode} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HeatMapChart;
