import React from 'react';
import './WaterUseBlocks.scss';
import '../DashboardBlocks/GeneralBlocks.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const GallonsCost = (props) => {

    let gallonsCost = Number(props.gallonsCost).toLocaleString();

    return (
        <Container className="waterUseBackground">
            <Row style={{ "height": "25%" }} >
                <p className="blocksLabel">Cost of Gallons Used</p>
            </Row>
            <Row style={{ "height": "75%" }} >
                <p className="totalWaterUse">${gallonsCost}</p>
            </Row>
        </Container>
    );
};

export default GallonsCost;