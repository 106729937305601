import React from 'react';
import './SummaryInfoBlock.css';




const summaryInfoBlock = (props) => {
        let className = props.className;

        return (

            <div className={className} id="card-69">
                <div className="card card-block" style={{height: '150px' , marginBottom: '50px'}}>
                    <h3 align="center" className="card-title">{props.label}</h3>
                    <h3 align="center" className="card-title">{props.stats}</h3>
                </div>
            </div>

        );
    
}

export default summaryInfoBlock;