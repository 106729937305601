import React from 'react';
import './SidebarIcons.scss'

const HelpIcon = (props) => {
return (
        <svg className="svg" fill={props.fill} height="30" width="30" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><circle cx="197.89" cy="304.53" r="17.92"/><path d="M272.22,140.8c0,24.27-7.84,40.24-33.69,63.74-17.19,15.71-22.72,27.8-22.72,44.25v.84a17.12,17.12,0,0,1-.15,2.28,17.91,17.91,0,0,1-35.53,0,17.12,17.12,0,0,1-.15-2.28v-3.28c0-19.73,6.39-37.25,33.94-61.77,19.22-17.23,22.85-30.41,22.85-44.55,0-16.39-10.16-34.3-35.23-34.3s-34.94,16-39.53,38.67c-2.57,12.68-11,18-20.24,16.44a16.9,16.9,0,0,1-14-16.44v-.05c.06-1,.15-2.06.27-3.14,2.55-23.77,19-63.81,74.17-63.66C255.06,77.71,272.22,113.8,272.22,140.8Z"/><path d="M200,38.67A161.33,161.33,0,0,1,314.08,314.08,161.33,161.33,0,0,1,85.92,85.92,160.28,160.28,0,0,1,200,38.67m0-32C93.23,6.67,6.67,93.23,6.67,200S93.23,393.33,200,393.33,393.33,306.77,393.33,200,306.77,6.67,200,6.67Z"/></svg>
        );
};

export default HelpIcon;
