import React, { Component } from 'react'
import { withRouter } from 'react-router'
import config from '../../utilities/config';
import axios from 'axios';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import AlertDetails from './AlertDetails';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';

class AlertLookupView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buildingOptions: null,
            selectedbuilding: null,
            viewing: "buildingSelect",
            alertRows: null,
            alertDetails: null,
            selectedAlert: null
        }
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'AlertLookup_view',
          })
        gtag('event','page_view')
        this.getAllBuildings()
    }

    getAllBuildings = () => {
        axios({
            method: 'get',
            url: `${config.api}get-all-buildings`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            },
        })
            .then((response) => {
                const data = response['data'];
                const buildingOptions = data.map((building) => {
                    return (building);
                });
                this.setState({
                    buildingOptions: buildingOptions
                });
            })
            .catch((error) => {
                //console.log(error);
            });
    }

    selectBuilding = () => {
        const buildingID = this.state.selectedbuilding.id;
        //console.log(buildingID);
        this.getBuildingAlerts(buildingID);
    }

    getBuildingAlerts = (buildingID) => {
        axios({
            method: 'get',
            url: `${config.api}alerts/global/building/${buildingID}`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            }
        })
            .then((response) => {
                const data = response['data'].alerts;
                //console.log(data);
                const dataKeys = Object.keys(data);
                this.createAlertTable(data, dataKeys);
            })
            .catch((error) => {
                //console.log(error);
            });
    }

    createAlertTable = (alertData, alertDataKeys) => {
        const alertRows = alertDataKeys.map((alertKey) => {
            return (
                <tr onClick={() => this.seeAlertDetails(alertData[alertKey].globalAlertID)}>
                    <td>{alertData[alertKey].globalAlertID}</td>
                    <td>{alertData[alertKey].locationID}</td>
                    <td>{alertData[alertKey].summary.gallons}</td>
                    <td>{alertData[alertKey].summary.flushes}</td>
                </tr>
            )
        });
        this.setState({
            alertRows: alertRows,
            viewing: "alertList"
        });
    }

    seeAlertDetails = (alertID) => {
        //console.log(alertID);
        axios({
            method: 'get',
            url: `${config.api}alerts/global/globalAlert/${alertID}`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            }
        })
            .then((response) => {
                const data = response['data'];
                //console.log(data);
                this.setState({
                    viewing: "alertDetails",
                    alertDetails: data,
                    selectedAlert: alertID
                });
            })
            .catch((error) => {
                //console.log(error);
            });
    }

    render() {
        switch (this.state.viewing) {
            case "buildingSelect":
                if (this.state.buildingOptions != null) {
                    Swal.close();
                    return (
                        <Container>
                            <div className="masqueradeForm">
                                <h2 className="select-title">
                                    Select a Building to View Alerts
                                </h2>
                            <div style={{ marginTop: 12, padding: 12, marginBottom: 30, width:"300px" }}>
                                <ReactSearchAutocomplete
                                    styling={{
                                        background: 'rgba(255, 255, 255, 0.9)',
                                        padding: '2px 0',
                                        fontSize: '90%',
                                        position: 'fixed',
                                        overflow: 'auto',
                                        maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                                    }}
                                    resultStringKeyName="label"
                                    fuseOptions={{ keys: ["label"] }}
                                    items={this.state.buildingOptions}
                                    onSearch={() => { }}//ignored
                                    onHover={() => { }}//ignored
                                    onSelect={(item) => {
                                        // the item selected
                                        this.setState({
                                            selectedbuilding: item
                                        });
                                    }}
                                    onFocus={() => { }}//ignored
                                    showNoResultsText="No Buildings found"
                                    formatResult={(item) => {
                                        return (
                                            <span key={item.id}>{item.label}</span>
                                        )
                                    }}
                                />
                                </div>
                                <br />
                                <br />
                                <Button variant="primary" onClick={this.selectBuilding}>
                                    See Alerts
                                </Button>
                            </div>
                        </Container>
                    )
                }
                else {
                    return (
                        <div>
                            <LoadingView />
                        </div>
                    );
                }
            case "alertList":
                return (
                    <Container>
                        <div className="masqueradeForm">
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Alert ID</th>
                                        <th>Location</th>
                                        <th>Gallons</th>
                                        <th>Flushes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.alertRows}
                                </tbody>
                            </Table>
                        </div>
                    </Container>
                )
            case "alertDetails":
                return (
                    <Container>
                        <Row style={{ height: '20%', overflow: 'auto' }}>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Alert ID</th>
                                        <th>Location</th>
                                        <th>Total Gallons</th>
                                        <th>Total Flushes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.state.alertDetails.alerts[this.state.selectedAlert].globalAlertID}</td>
                                        <td>{this.state.alertDetails.alerts[this.state.selectedAlert].locationID}</td>
                                        <td>{this.state.alertDetails.alerts[this.state.selectedAlert].summary.gallons}</td>
                                        <td>{this.state.alertDetails.alerts[this.state.selectedAlert].summary.flushes}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                        <Row style={{ height: '80%', overflow: 'auto' }}>
                            <Container>
                                <AlertDetails alertData={this.state.alertDetails} alertID={this.state.selectedAlert} />
                            </Container>
                        </Row>
                    </Container>
                )
            default:
                return (
                    <div></div>
                )
        }
    }
}

export default withRouter(AlertLookupView);