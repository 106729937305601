import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Col, Row } from 'react-bootstrap';
import config from '../../utilities/config.js';



let autocompleteBuilding = [];

class ModifyBuildingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            allBuildings: null,
            selectedBuildingID: null,
            selectedPropertyID: null,
            modifyForm: null,
            label: null,
            address: null,
            city: null,
            state: null,
            stateOptions: null,
            lat: null,
            lon: null,
            zip: null,
        };
    }



    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'ModifyBuilding_view',
          })
        gtag('event','page_view')
        this.getallBuildings();
        this.setStateOptions();
    }

    

    getallBuildings() {
        //console.log("allBuildings")
        //Get all building IDs
        let url = `${config.api}get-all-buildings`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                const data = response["data"];
                autocompleteBuilding = data.map((item) => {
                    return item;
                });
                this.setState({
                    loaded: true
                });
            },
                (error) => {
                    //console.log(error);
                }
            );
    }

    setStateOptions() {
        const stateArray = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
            'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
            'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska',
            'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
            'Oregon', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
            'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']
        const stateOptions = stateArray.map((item) => {
            return (
                <option value={item}>{item}</option>
            );
        })
        this.setState({
            stateOptions: stateOptions
        });
    }

    createModifyForm(selected) {
        //console.log("hi", selected)
        this.setState({
            selectedBuildingID: selected.id,
            selectedPropertyID: selected.propertyID,
            label: selected.label,
            address: selected.address,
            city: selected.city,
            state: selected.state,
            zip: selected.zip,
            lat: selected.lat,
            lon: selected.lon,
            loaded: false,
            modifyForm: null
        });
        var modifyForm = (
            <Form>
                <Form.Group as={Row} controlId="modifyBuildingName">
                    <Form.Label column sm={2}>Name:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="label" onChange={this.handleInputChange} defaultValue={selected.label} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyBuildingAddress">
                    <Form.Label column sm={2}>Address:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="address" onChange={this.handleInputChange} defaultValue={selected.address} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyBuildingCity">
                    <Form.Label column sm={2}>City:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="city" onChange={this.handleInputChange} defaultValue={selected.city} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyBuildingState">
                    <Form.Label column sm={2}>State:</Form.Label>
                    <Col sm={10}>
                        <Form.Control as="select" id="state" onChange={this.handleInputChange} defaultValue={selected.state}>
                            <option value="0">Change State</option>
                            {this.state.stateOptions}
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyBuildingZip">
                    <Form.Label column sm={2}>Zip:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="zip" onChange={this.handleInputChange} defaultValue={selected.zip} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyBuildingLat">
                    <Form.Label column sm={2}>Lat:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="lat" onChange={this.handleInputChange} defaultValue={selected.lat} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyBuildingLon">
                    <Form.Label column sm={2}>Lon:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="lon" onChange={this.handleInputChange} defaultValue={selected.lon} />
                    </Col>
                </Form.Group>
                <Button variant="primary" onClick={this.handleSubmit} >Update Building</Button>
                <Button variant="danger" onClick={this.handleArchive} >Archive Building</Button>
                <Button variant="primary" onClick={this.clearform} >Clear</Button>
            </Form>
        )
        this.setState({
            modifyForm: modifyForm,
            loaded: true
        })
    }


    clearform = () => {
        this.setState({
            modifyForm: null,
        })
    }

    handleInputChange = (event) => {
        switch (event.target.id) {
            case "label":
                this.setState({
                    label: event.target.value
                });
                break;
            case "address":
                this.setState({
                    address: event.target.value
                });
                break;
            case "city":
                this.setState({
                    city: event.target.value
                });
                break;
            case "state":
                this.setState({
                    state: event.target.value
                });
                break;
            case "lat":
                this.setState({
                    lat: event.target.value
                });
                break;
            case "lon":
                this.setState({
                    lon: event.target.value
                });
                break;
            case "zip":
                this.setState({
                    zip: event.target.value
                });
                break;
        }
    }

    handleSubmit = () => {
        let data = `[{
                "id": ${this.state.selectedBuildingID}, 
                "label": "${this.state.label}", 
                "address": "${this.state.address}",
                "city": "${this.state.city}",
                "state": "${this.state.state}",
                "lat": ${this.state.lat},
                "lon": ${this.state.lon},
                "zip": ${this.state.zip},
                "propertyID": ${this.state.selectedPropertyID}
            }]`

        axios({
            method: 'put',
            url: `${config.api}buildings`,
            headers: {
                'Authorization': `Bearer ${config.token}`,
                'Content-Type': 'text/plain'
            },
            data: data
        })
            .then((response) => {
                const data = response['data'];
                Swal.fire({
                    icon: 'success',
                    title: 'Success 👍🏼'
                });
            })
            .catch((error) => {
                //console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Whoops 😰',
                    text: error
                });
            });

    }

    handleArchive = () => {
        let data = `[{"id": ${this.state.selectedBuildingID}}]`
        var postConfig = {
            method: 'delete',
            url: `${config.api}buildings`,
            headers: { 
                'Authorization': `Bearer ${config.token}`,
                'Content-Type': 'text/plain'
            },
            data : data
        };
        //console.log(postConfig)
        axios(postConfig).then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success 👍🏼'
                });
            })
            .catch((error) => {
                //console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Whoops 😰',
                    text: error
                });
            });

    }

    render() {
        if (this.state.loaded) {
            Swal.close();
            //console.log(this.state.modifyForm)
            return (
                <Container>
                    <h2>Modify A Building</h2>
                    <Col style={{ marginTop: 12, padding: 12, marginBottom: 30 }}>
                        <ReactSearchAutocomplete
                            styling={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: '50%',
                                zIndex: '999',
                            }}
                            items={autocompleteBuilding}
                            resultStringKeyName="label"
                            fuseOptions={{ keys: ["label"] }}
                            onSearch={() => { }}//ignored
                            onHover={() => { }}//ignored
                            onSelect={(item) => {this.createModifyForm(item)}}
                            onFocus={() => { }}//ignored
                            onClear={this.clearform}
                            maxResults={6}
                            showItemsOnFocus={true}
                            showNoResultsText="No Building found"
                            formatResult={(item) => {
                                return (
                                    <span>{item.label}</span>
                                )
                            }}
                        />
                    </Col>
                    {this.state.modifyForm}
                </Container>
            )
        }
        else {
            return (
                <LoadingView />
            )
        }
    }
}

export default withRouter(ModifyBuildingView);