import React from 'react';

const ChartTable = (props) => {
  if (props.data) {
    const rows = [];
    const badRooms = Object();
    Object.keys(props.data).forEach((room) => {
      if (props.data[room] !== 0) {
        if (props.data[room] < 10) {
          badRooms[room] = 'Low Pressure';
          if (props.data[room] < 5) badRooms[room] = 'Very Low Pressure';
        } else if (props.data[room] > 65) {
          badRooms[room] = 'High Pressure';
          if (props.data[room] > 75) badRooms[room] = 'Very High Pressure';
        }
      }
    });

    Object.keys(badRooms).forEach((room) => {
      rows.push(
        <tr>
          <td>{room}</td>
          <td>{badRooms[room]}</td>
        </tr>
      );
    });

    if (Object.keys(badRooms).length === 0) {
      return <h4 className="text-center">No Concerning Rooms</h4>;
    }
    return (
      <div className="text-center px-3">
        <h4>Concerning Rooms</h4>
        <table className="table table-striped mx-auto">
          <thead>
            <tr>
              <th>Room Number</th>
              <th>Problem</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
  return <h4 className="text-center">No Concerning Rooms</h4>;
};

export default ChartTable;
