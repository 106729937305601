import React, { Component } from 'react';
import LoadingView from '../LoadingView/LoadingView';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config.js';
import { getBuildingLocations, getDeviceMacAddress, getlocationMetrics, getPropertyBuildings } from '../../utilities/api';
import { alertError } from '../../utilities/utils';

var buildingInfo = {};
var locationSet = {};

class MacAddressReportView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buildingIDCount: null,
            loaded: false,
            loadCount: 0,
            locSetRows: [],
            selectedBuilding: props.building,
            selectedProperty: props.propertyID,
            selectedBuilding: 61,
            reportTables: []
        }
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'macAdressReport_view',
          })
        gtag('event','page_view')
        this.getPropertyBuildings(this.state.selectedProperty)
    }

    componentWillUnmount() {
        buildingInfo = {};
        locationSet = {};
    }
    
    async getPropertyBuildings(propertyID) {

        const buildings = await getPropertyBuildings(propertyID)
        if (buildings.error) {
            alertError(buildings.error);
            return;
        }

        buildings.data.forEach((building) => {
         this.getLocations(building.id, building.label);
        });
    }

    async getLocations(buildingID, buildingLabel) {

        const locationData = await getBuildingLocations(buildingID);
        if (locationData.error) {
            alertError(locationData.error);
            return;
        }

        var locations = locationData.data.map((item) => {
            return (item);
        });
        this.getMacAdds(buildingID, locations, buildingLabel);
    }

    getMacAdds(buildingID, locations, buildingLabel) {
        let locCounter = 0;
        locations.sort((a,b) => (a.locationName > b.locationName) ? 1 : ((b.locationName > a.locationName) ? -1 : 0));
        locations.forEach(async (location, index, array) => {
            // //console.log(location);
            if (location['deviceID']) {
                const macAddress = await getDeviceMacAddress(location['deviceID']);
                if (macAddress.error) {
                    alertError(macAddress.error);
                    return;
                }
                locCounter++;
                //console.log(macAddress);
                if (locationSet[buildingID] == null) {
                    locationSet[buildingID] = [{location: location.locationName, device: location.deviceID, macAddress: macAddress.data.macAddress}];
                }
                else {
                    locationSet[buildingID].push({location: location.locationName, device: location.deviceID, macAddress: macAddress.data.macAddress});
                }
                if (locCounter === array.length) {
                    this.createLocSetRows(buildingID, buildingLabel);
                }

            }
            else {
                locCounter++;
                if (locCounter === array.length) {
                    this.createLocSetRows(buildingID, buildingLabel);
                }
                if (locationSet[buildingID] == null) {
                    locationSet[buildingID] = [{location: location.locationName, device: location.deviceID, macAddress: '-'}];
                }
                else {
                    locationSet[buildingID].push({location: location.locationName, device: location.deviceID, macAddress: '-'});
                }
            }
        });
    }

    createLocSetRows = (buildingID, buildingLabel) => {
        const curLocArray = locationSet[buildingID];
        const locSetRows = curLocArray.map((locSet, index) => {
            return (
                <tr>
                    <td>{locSet.location}</td>
                    <td>{locSet.device}</td>
                    <td>{locSet.macAddress}</td>
                </tr>
            );
        });

        // return locSetRows;

        const reportTable = (
            <Table bordered hover>
                <thead>
                    <tr>
                        <h3>{buildingLabel}</h3>
                    </tr>
                    <tr>
                        <th>Location</th>
                        <th>Device</th>
                        <th>MAC Address</th>
                    </tr>
                </thead>
                <tbody>
                    {locSetRows}
                </tbody>
            </Table>
        )

        // this.setState({
        //     // locSetRows: locSetRows
        //     reportTable: reportTable
        // })
        this.setState((prevState) => ({
            reportTables: [...prevState.reportTables, reportTable]
        }));
    }

    render() {
        //console.log(locationSet);
        if (this.state.locSetRows != null) {
            Swal.close();
            return (
                // <Container>
                //     <Table bordered hover>
                //         <thead>
                //             <tr>
                //                 <th>Room</th>
                //                 <th>Device</th>
                //                 <th>Coms in Last 24</th>
                //                 <th>Last Coms</th>
                //                 <th>Rssi</th>
                //                 <th>Water in Last 24</th>
                //             </tr>
                //         </thead>
                //         <tbody>
                //             {this.state.locSetRows}
                //         </tbody>
                //     </Table>
                // </Container>
                <Container>
                    {this.state.reportTables}
                </Container>
            );
        }
        else {
            return (
                <div>
                    <LoadingView />
                </div>
            );
        }
    }
}

export default withRouter(MacAddressReportView);
