import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
 

class PageNotFoundView extends Component {
    
    constructor(props){
        super(props);
    }
    
    state = {
        data: null
    };


    render() {
        return (
            <div className='container' style={{fontFamily: "Trebuchet MS"}}>
                <center>
                    <img src="https://mobius-labs-public-imgs.s3.amazonaws.com/flow-active.png" style={{"width" : "80%" , "maxWidth": "520px" }}/>
                    <h1>404 Error, Not Found.</h1>
                    <br />
                    <a href='https://portal.flowactive.com'>Click here to return home</a>
                </center>
            </div>
        )

    }
}

export default withRouter(PageNotFoundView);