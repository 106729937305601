import React from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

const FlowTestTable = (props) => {
    
    const rowData = props.info;
    const deviceID = props.deviceID;

    const tableRows = rowData.map((rd) => {

        return (
            <tr>
                <td>{deviceID}</td>
                <td>{rd.createdAt}</td>
                <td>{rd.label}</td>
                <td><a href={rd.fileURL}>Download</a></td>
            </tr>
        );
    });

    return (
        <Container>
            <Table>
                <thead>
                    <tr>
                        <th>Device ID</th>
                        <th>Created At</th>
                        <th>Label</th>
                        <th>URL</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
        </Container>
    );
};

export default FlowTestTable;
