import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Col, Row } from 'react-bootstrap';
import config from '../../utilities/config.js';
import SetupView from '../SetupView/SetupView'
import WaterWasteCalculatorView from './WaterWasteCalculatorView'

var autocompletDevices = [];

class ModifyDeviceView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            modifyForm: null,
            selectedID: null,
            deviceID: null,
            deviceLabel: null,
        };
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'ModifyDevice_view',
          })
        gtag('event','page_view')
        this.getAllDevices()
    }

    clearform = () => {
        this.setState({
            modifyForm: null,
        })
    }

    getAllDevices() {
        //Get all Users
        let url = `${config.api}get-all-devices`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                //console.log(response)
                const data = response["data"];
                autocompletDevices = data.map((item) => {
                    return item;
                });
                this.setState({
                    loaded: true
                });

            },
                (error) => {
                    //console.log(error);
                }
            );
    }

    createModifyForm(selected) {
        //console.log(selected)
        this.setState({
            selectedID: selected.id,
            deviceID:selected.deviceID,
            deviceLabel: selected.deviceLabel
        });
        var modifyForm = (
            <Form>
                <Form.Group as={Row} controlId="modifyPropertyName">
                    <Form.Label column sm={2}>Name:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="deviceLabel" onChange={this.handleInputChange} defaultValue={selected.deviceLabel} />
                    </Col>
                </Form.Group>
                <Button variant="primary" onClick={this.handleSubmit} >Update Device</Button>
                <Button variant="danger" onClick={this.handleArchive} >Archive Device</Button>
                <Button variant="primary" onClick={this.clearform} >Clear</Button>
            </Form>
        )
        this.setState({
            modifyForm: modifyForm
        })
    }

    handleInputChange = (event) => {
        switch (event.target.id) {
            case "deviceLabel":
                this.setState({
                    deviceLabel: event.target.value
                });
                break;
        }
    }

    handleSubmit = () => {
        let data;
        if(this.state.deviceLabel=="None"){
            Swal.fire({
                icon: 'error',
                title: 'Nothing Updated',
                text: error
            });
            return;
        }
        data = `[{
                "id": ${this.state.selectedID}, 
                "deviceID": "${this.state.deviceID}",
                "deviceLabel":"${this.state.deviceLabel}"
            }]`
       

        axios({
            method: 'put',
            url: `${config.api}devices`,
            headers: {
                'Authorization': `Bearer ${config.token}`,
                'Content-Type': 'text/plain'
            },
            data: data
        })
            .then((response) => {
                const data = response['data'];
                Swal.fire({
                    icon: 'success',
                    title: 'Success 👍🏼'
                });
            })
            .catch((error) => {
                //console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Whoops 😰',
                    text: error
                });
            });

    }

    handleArchive = () => {
        let data = `[{"id": ${this.state.selectedID}}]`

        axios({
            method: 'delete',
            url: `${config.api}devices`,
            headers: {
                'Authorization': `Bearer ${config.token}`,
                'Content-Type': 'text/plain'
            },
            data: data
        })
            .then((response) => {
                const data = response['data'];
                Swal.fire({
                    icon: 'success',
                    title: 'Success 👍🏼'
                });
            })
            .catch((error) => {
                //console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Whoops 😰',
                    text: error
                });
            });

    }

    render() {
        if (this.state.loaded) {
            //console.log("LENGTH")
            //console.log(autocompletDevices.length)
            Swal.close();
            return (
                <Container>
                    <Col style={{ marginTop: 12, padding: 12, marginBottom: 30 }}>
                        <ReactSearchAutocomplete
                            items={autocompletDevices}
                            resultStringKeyName="deviceID"
                            fuseOptions={{ keys: ["deviceID","deviceLabel"] }}
                            onSearch={() => { }}//ignored
                            onHover={() => { }}//ignored
                            onSelect={(item) => { this.createModifyForm(item) }}
                            onFocus={() => { }}//ignored
                            onClear={this.clearform}
                            autoFocus
                            showNoResultsText="No devices found"
                            showItemsOnFocus={true}
                            formatResult={(item) => {
                                return (
                                    item.deviceLabel==null ||item.deviceLabel=="None"?<span>{item.deviceID}</span>:<span>{item.deviceID}<br/><span>{item.deviceLabel}</span></span>
                                )
                            }}
                        />
                    </Col>
                    {this.state.modifyForm}
                </Container>
            );
        }
        else {
            return (
                <div>
                    <LoadingView />
                </div>
            );
        }
    }
}

export default withRouter(ModifyDeviceView);