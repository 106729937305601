import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import config from '../../utilities/config.js';


class AddLocationView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedOrg: false,
            loadedUser: false,
            selectedUser: null,
            selectedOrg: null,
            selectedProp: null,
            location: null,
            selectedBuilding: null,
            allPropOptions: null,
            allUserOptions: null,
            allBuildOptions: null
        };
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'AddLocation_view',
          })
        gtag('event','page_view')
        this.getAllOrgs()
        this.getAllUsers()
    }

    handleInputChange = (event) => {
        this.setState({
            location: event.target.value
        });
    }

    handleOrgChange = (event) => {
        this.setState({
            selectedOrg: event.target.value
        });
        this.getProps(event.target.value)
        
    }
    handlePropChange = (event) => {
        this.setState({
            selectedProp: event.target.value
        });
        this.getBuilding(event.target.value)
    }

    handleBuildingChange = (event) => {
        this.setState({
            selectedBuilding: event.target.value
        });
    }

    handleUserChange = (event) => {
        this.setState({
            selectedUser: event.target.value,
            loadedOrg: false
        });
        this.getAllOrgs(event.target.value)
    }

    handleSubmit = (event) => {
        if (this.state.selectedOrg == 0 || this.state.selectedOrg == null) { 
            Swal.fire({
            icon: 'error',
            title: 'Need Organization'
        });return };
        if (this.state.selectedProp == 0 || this.state.selectedProp == null) { 
            Swal.fire({
            icon: 'error',
            title: 'Need Property'
        });return };
        if (this.state.selectedBuilding == 0 || this.state.selectedBuilding == null) { 
            Swal.fire({
            icon: 'error',
            title: 'Need Building'
        });return };
        if (this.state.location == "" || this.state.location == null) { 
            Swal.fire({
            icon: 'error',
            title: 'Need Location'
        });return };

        //Add Location
        let url = `${config.api}locations`;
        let token = localStorage.getItem("mlToken");

        var postData = `[{"userID": "${config.userID}", "locationLabel": "${this.state.location}", "buildingID": "${this.state.selectedBuilding}"}]`;
        
        //console.log(postData)
        var postConfig = {
            method: 'post',
            url: url,
            headers: { 
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'text/plain'
            },
            data : postData
        };
        axios(postConfig)
            .then((response) => {
                //console.log(response);
                Swal.fire({
                    icon: 'success',
                    title: 'Done'
                })
            },
                (error) => {
                    //console.log(error);
                }
            );

    };


    getAllUsers() {
        //Get all Users
        let url = `${config.api}get-all-users`;
        let token = localStorage.getItem("mlToken");
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                //console.log(response)
                const data = response["data"];
                this.state.allUserOptions = data.map((item) => {
                    return <option value={item.id}>{item.email}</option>;
                });
                this.setState({
                    loadedUser: true,
                });
            },
                (error) => {
                    //console.log(error);
                }
            );

    }

    getAllOrgs(UserID) {
        //Get all Orgs
        let url;
        if(UserID=='0' || UserID==null)url = `${config.api}get-all-organizations`;
        else url = `${config.api}user/${UserID}/organizations`;
        let token = localStorage.getItem("mlToken");
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                //console.log(response)
                const data = response["data"];
                var orgOptions = data.map((item) => {
                    if(item.label!=null)
                        return <option value={item.id}>{item.label}</option>;
                    else
                        return <option value={item.id}>{item.id}</option>;
                });
                this.setState({
                    loadedOrg: true,
                    allOrgOptions: orgOptions
                });
            },
                (error) => {
                    //console.log(error);
                }
            );

    }

    getProps(OrgID) {
        //Get Properties
        //console.log(OrgID)
        let url = `${config.api}organization/${OrgID}/properties`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                //console.log(response)
                const data = response["data"];
                var propOptions = data.map((item) => {
                    return <option value={item.id}>{item.label}</option>;
                });
                this.setState({
                    allPropOptions: propOptions,
                });
            },

                (error) => {
                    //console.log(error);
                }
            );

    }

    getBuilding(PropID) {
        //Get Buildings
        //console.log(PropID)
        let url = `${config.api}property/${PropID}/buildings`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                //console.log(response)
                const data = response["data"];
                var buildingOptions = data.map((item) => {
                    return <option value={item.id}>{item.label}</option>;
                });
                this.setState({
                    allBuildOptions: buildingOptions,
                });
            },

                (error) => {
                    //console.log(error);
                }
            );

    }

    render() {
        if (this.state.loadedOrg && this.state.loadedUser) {
            Swal.close();
            return(
            <Container>
                <h2>Add A Location</h2>
                <br />
                <Form>
                    {/* User Select */}
                    <Form.Group>
                        <Form.Label>Select a User(Optional)</Form.Label>
                        <Form.Control as="select" id="orgSelect" onChange={this.handleUserChange} defaultValue={this.state.selectedUser} >
                            <option value="0">Select a User</option>
                            {this.state.allUserOptions}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Select a Organization</Form.Label>
                        <Form.Control as="select" id="orgSelect" onChange={this.handleOrgChange} defaultValue={this.state.selectedOrg} >
                            <option value="0">Select a Organization</option>
                            {this.state.allOrgOptions}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Select a Property</Form.Label>
                        <Form.Control as="select" id="propSelect" onChange={this.handlePropChange} defaultValue={this.state.selectedProp} >
                            <option value="0">Select a Property</option>
                            {this.state.allPropOptions}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Select a Building</Form.Label>
                        <Form.Control as="select" id="buildingSelect" onChange={this.handleBuildingChange} selected="Choose....." >
                            <option value="0">Select a Building</option>
                            {this.state.allBuildOptions}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Add A New Location</Form.Label>
                        <Form.Control type="text" id="addLocInput" onChange={this.handleInputChange} placeholder="Room 303" />
                    </Form.Group>
                    
                    <Button variant="primary" id="addLocSubmitButton" onClick={this.handleSubmit.bind(this)}>Submit</Button>
                </Form>
                <br />
            </Container>)

        }
        else {
            return (
                <LoadingView />
            )
        }
    }
}

export default withRouter(AddLocationView);