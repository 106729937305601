import React from 'react';
import './RepairBlock.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import { Col } from 'react-bootstrap';

const RepairBlock = (props) => {

    // let repairPercentage = 100;

    const openRepairs = () => {
        const history = props.history;
        history.push("/repairs");
    }

    return (
        <Container style={{ cursor: 'pointer'}} onClick={openRepairs}>
            <Row style={{'height': '25%'}}>
                <Col xs={12}>
                    <p className="blocksLabel">Repairs</p>
                </Col>
            </Row>
            <Row style={{'height': '75%'}}>
                <p className="repairAlerts">{props.repairs}</p>
            </Row>
        </Container>
    );
};

export default RepairBlock;