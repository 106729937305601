import axios from 'axios';
import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config.js';




class StatsWidget extends Component{
    


    constructor(props) {
        super(props);
        this.state = {
            widgetData : null,
        };
    }



    componentDidMount(){

        let url = `${config.api}metrics/widget/all?orientation=horizontal`;

        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
          )

          .then((response) => {

                let data = response["data"]["html"];
                this.setState({widgetData : data});

            },
            (error) => {
                //console.log(error);
            }
          );
    }
    createMarkup(data) {
        return {__html: data};
      }

    render(){
        
        let widgetData = this.state.widgetData;
        if (widgetData !== null){
            return(
               <center><div dangerouslySetInnerHTML={this.createMarkup(widgetData)}></div></center>
            )
            
        }
        else{
            return (
                <div></div>
            );
        }
    }




};

export default withRouter(StatsWidget);
