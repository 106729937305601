import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './MasqueradeView.scss';
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from '../../components/Sidebar/Sidebar.js';
import Button from 'react-bootstrap/Button';
import LoadingView from '../LoadingView/LoadingView';
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import Container from 'react-bootstrap/Container';
import config from '../../utilities/config.js';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Col, Row } from 'react-bootstrap';



class MasquaradeView extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        users: null,
        selectedUserID: null
    };


    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'Masquerade_view',
          })
        gtag('event','page_view')
        //do this call
        let url = `${config.api}get-all-users`;

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )

            .then((response) => {
                this.setState({ users: response["data"] })
            },
                (error) => {
                    //console.log(error);
                }
            );


    }

    setMasquerade = ele => {
        const userID = this.state.selectedUserID;
        if (config.isMasquerading == false) {
            localStorage.setItem("mlOriginalUser", config.userID);
            localStorage.setItem("mlUserID", userID);
            config.userID = userID;
            localStorage.setItem("mlBuildingID", 0);
            config.selectedBuilding = 0;
            localStorage.setItem("mlIsMasquerading", true);
            config.isMasquerading = true;
            //console.log(config);
            window.location.href = "/";
        }
        else {
            localStorage.setItem("mlUserID", userID);
            config.userID = userID;
            //console.log(config);
            window.location.href = "/";
        }

    };


    render() {
        let users = this.state.users;

        if (users != null) {

            Swal.close();
            return (
                <div id="masqueradeOuterContainer" className="masqueradeContainer" >
                    <Sidebar
                        pageWrapId={"masqueradePageWrap"}
                        outerContainerId={"masqueradeOuterContainer"}
                        buttonsVisible={false}
                    />

                    <Container id="masqueradePageWrap" className="masqueradePageWrap" >
                        <div className="masqueradeFormContainer">
                            <div className="masqueradeForm">
                                <h2 className="select-title">
                                    Select a User to Masquerade
                                </h2>
                                <div style={{padding: 12, width: 320 }}>
                                <ReactSearchAutocomplete
                                    styling={{
                                        background: 'rgba(255, 255, 255, 0.9)',
                                        padding: '2px 0',
                                        fontSize: '90%',
                                        position: 'fixed',
                                        overflow: 'auto',
                                        maxHeight: '50%',
                                        zIndex: '999',
                                    }}
                                    items={users}
                                    resultStringKeyName="email"
                                    fuseOptions={{ keys: ["email"] }}
                                    onSearch={() => { }}//ignored
                                    onHover={() => { }}//ignored
                                    onSelect={(item) => { 
                                        
                                        this.setState({ selectedUserID: item.id })
                                        }}
                                    onFocus={() => { }}//ignored
                                    maxResults={6}
                                    showItemsOnFocus={true}
                                    showNoResultsText="No Users found"
                                    formatResult={(item) => {
                                        return (
                                            <span>{item.email}</span>
                                        )
                                    }}
                                />
                                
                                </div>
                                <Button variant="primary" onClick={this.setMasquerade}>
                                    Masquerade
                                </Button>
                                <br />
                                
                            </div>
                        </div>
                    </Container>
                </div>
            )
        }

        else {
            return <LoadingView />;
        }

    }
}

export default withRouter(MasquaradeView);