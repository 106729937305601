import React from 'react';
import './WaterUseBlocks.scss';
import '../DashboardBlocks/GeneralBlocks.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';

const Flushes = (props) => {

    let flushes = Number(props.flushes).toLocaleString();

    return (
        <Container>
            <Row style={{"height": "25%"}} >
                <Col xs={12}>
                    <p className="blocksLabel">Flushes</p>
                </Col>
            </Row>
            <Row style={{"height": "75%"}} >
                <p className="totalWaterUse">{flushes}</p>
            </Row>
        </Container>
    );
};

export default Flushes;