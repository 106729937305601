import React from 'react';
import AlertStatButton from '../../Alert/AlertStatButton/AlertStatButton';
import ArchiveReportButton from '../../ArchiveReportButton/ArchiveReportButton';


const cTableColumn = (props) => {

    let attribute = props.attribute;


    if(attribute == null || attribute == undefined){
        return (
            <td>
                {props.label}
            </td>
        );
    }




    else if(attribute != null && attribute != undefined){
        let name = attribute["name"];
        if(name == "url"){
            let url = attribute["url"];
            return(
                <td>
                    <a href={url}>{props.label}</a>
                </td>
            );

            
        }
        else if(name == "alertStatButton"){
            let id = attribute["id"];

            return (
                <td>
                  <AlertStatButton id={id} />  
                </td>
            )
        }

        else if(name == "archiveReportButton"){
            let id = "report-" + attribute["id"];
            let label = attribute["label"];
            return (
                <td>
                 <ArchiveReportButton id={id} label={label} />
                </td>
            )
        }

        else{
            return (
                <td>
                    {props.label}
                </td>
            );
        }
    } 



};

export default cTableColumn;