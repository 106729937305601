import React, { Component } from 'react'
import Chart from "chart.js";

export default class Graph extends Component {
    chartRef = React.createRef();
    trimData(data){
        let nData = [];
        
        //every fourth
        if(data.length >= 90){
            for(let i =0 ; i < data.length ; i++){
                if(i==0 || i%4 == 0 || (i==data.length -1)){
                    nData.push(data[i]);
                }
            }
        }
        //every third
        else if(data.length > 31){
            for(let i =0 ; i < data.length ; i++){
                if(i==0 || i%3 == 0 || (i==data.length -1)){
                    nData.push(data[i]);
                }
            }
        }
        //every other
        else if(data.length >= 15){
            for(let i =0 ; i < data.length ; i++){
                if(i==0 || i%2 == 0 || (i==data.length -1)){
                    nData.push(data[i]);
                }
            }
        }
        //every point
        else{
            return data;
        }

        return nData;
    }
    
    componentDidMount() {

        const myChartRef = this.chartRef.current.getContext("2d");

        let localData = this.props.data;
        localData["datasets"][0]["data"] = this.trimData(localData["datasets"][0]["data"]);
        localData["labels"] = this.trimData(localData["labels"]);

        let data = localData["datasets"][0]["data"];
        let labels = localData["labels"];

        const options =  {
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        };



        new Chart(myChartRef, {
            type: "line",
            data: localData,
            options: options

        });
    }

    componentDidUpdate(){
        const myChartRef = this.chartRef.current.getContext("2d");

        let localData = this.props.data;
        localData["datasets"][0]["data"] = this.trimData(localData["datasets"][0]["data"]);
        localData["labels"] = this.trimData(localData["labels"]);

        let data = localData["datasets"][0]["data"];
        let labels = localData["labels"];

        const options =  {
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        };



        new Chart(myChartRef, {
            type: "line",
            data: localData,
            options: options
        });     
    }
    render() {

        return (
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                    responsive
                >
                </canvas>
        )
    }
}

