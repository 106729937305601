import React from 'react';
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Route, Redirect} from "react-router-dom";

let theSelf = null;

const summaryCH = () => {
    window.location = '/summary';
};
    
const roomCH = () => {
    window.location = '/room-view';
};

const deviceCH = () => {
    window.location = '/device-view';
};

const deviceAllCH = () => {
    theSelf.setState({deviceView: "all"});
};

const deviceSignalCH = () => {
    theSelf.setState({deviceView: "signal"});
};

const batteryIssuesCH = () => {
    theSelf.setState({deviceView: "battery"});

};

const lastResponseCH = () => {
    theSelf.setState({deviceView: "response"});
};

const roomAll = () => {
    theSelf.setState({leakOnly: false , lastResponseOnly: false});

};

const roomLeakCH = () => {
    theSelf.setState({leakOnly: true , lastResponseOnly: false});
};

const roomLastReported = () => {
    theSelf.setState({leakOnly: false , lastResponseOnly: true});
};




const tabView = (props) => {


    const activeTab = props.tab;
    let classes = 'btn btn-primary-outline showLoadingWhenPressed';
    let summaryClasses = classes;
    let roomClasses = classes;
    let deviceClasses = classes;
    let summaryCheck = "";
    let roomCheck = "";
    let deviceCheck = "";
    let otherTabs = null;
    theSelf = props.this;
    

    if(activeTab === "summary"){
      summaryClasses += " active";
      summaryCheck = "checked";  
    }
    else if(activeTab === "room"){
        let leakOnly = props.leakOnly;
        let lastResponseOnly = props.lastResponseOnly;
        roomClasses += " active";
        roomCheck = "checked";
        let lea = "";
        let aca = "";
        let rca = "";
        if(leakOnly){
            lea = "active";
        }
        else if(lastResponseOnly){
            rca = "active";
        }
        else{
            aca = "active"; 
        }
        otherTabs = (
            <span style={{float:'right' , marginTop:'25px'}} id="hide-toggle" className="btn-group" data-toggle="buttons">
                <label className={"btn btn-primary-outline " + aca} id="show-all">
                    <input type="radio" autoComplete="off" checked  onClick={roomAll}/> All
                </label>
                <label className={"btn btn-primary-outline " + lea} id="leak-only">
                    <input type="radio" autoComplete="off"  onClick={roomLeakCH}/> Leak Only
                </label>
                <label className={"btn btn-primary-outline " + rca} id="response-only">
                    <input type="radio" autoComplete="off"  onClick={roomLastReported}/> Response Only
                </label>
            </span>
        );



    }
    else if(activeTab === "device"){
        deviceClasses += " active";
        deviceCheck = "checked";
        let type = props.deviceView;
        let allActive = "";
        let signalActive = "";
        let batteryActive = "";
        let lastResponseActive = "";

        if(type == "all"){
            allActive = "active";
        }
        else if(type == "signal"){
            signalActive = "active";
        }
        else if(type == "battery"){
            batteryActive = "active";
        }
        else if(type == "response"){
            lastResponseActive = "active";
        }


        otherTabs = (
            <span style={{float: 'right'}} id="hide-toggle" className="btn-group" data-toggle="buttons">
                <label className={"btn btn-primary-outline " + allActive} id="show-all-devices" onClick={deviceAllCH}>
                    <input type="radio" autoComplete="off" checked  /> All
                </label>
                <label className={"btn btn-primary-outline " + signalActive} id="signal-only" onClick={deviceSignalCH}>
                    <input type="radio" autoComplete="off" /> Signal Issues
                </label>
                <label className={"btn btn-primary-outline " + batteryActive} id="battery-only" onClick={batteryIssuesCH}>
                    <input type="radio" autoComplete="off" /> Battery Issues
                </label>
                <label className={"btn btn-primary-outline " + lastResponseActive} id="lastHeard-only" onClick={lastResponseCH}>
                    <input type="radio" autoComplete="off" /> Last Response
                </label>
            </span>
        );
    }

    return (
        <div style={{marginBottom: '100px' }}>
            <span style={{float: 'left' , marginTop: '25px'}} id="hide-toggle" className="btn-group" data-toggle="buttons">
                <label style={{zIndex:"0"}} className={summaryClasses} id="basic-view" >
                    <input type="radio" autoComplete="off" checked={summaryCheck } readOnly={true} onClick={summaryCH} /> Summary
                </label>
                <label style={{zIndex:"0"}} className={roomClasses} id="location-view-user" >
                    <input type="radio" autoComplete="off" checked={roomCheck} readOnly={true} onClick={roomCH} /> Room View
                </label>
                { <label className={deviceClasses} id="device-view-user" >
                    <input type="radio" autoComplete="off" checked={deviceCheck} readOnly={true} onClick={deviceCH} /> Device View
                </label> }
            </span>
            {otherTabs}
        </div>
    );


};

export default tabView;




