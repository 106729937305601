import React from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

const AllDevicesTable = (props) => {
    
    const devices = props.devices;

    var installed = "no";
    const tableRows = devices.map((device) => {
        if (device.locationID == null) {
            installed = "No";
        }
        else {
            installed = "Yes";
        }

        return (
            <tr>
                <td>{device.deviceID}</td>
                <td>{installed}</td>
                <td>{device.userID}</td>
            </tr>
        );
    });

    return (
        <Container>
            <Table>
                <thead>
                    <tr>
                        <th>Device ID</th>
                        <th>Installed?</th>
                        <th>User ID</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
        </Container>
    );
};

export default AllDevicesTable;
