import React from 'react';
import './AlertHistoryGraph.scss'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';

const AlertHistoryGraph = (props) => {
    const alertData = props.alertData;
    const keys = Object.keys(alertData);

    let labels = keys.map((key) => {
        let parts = key.split("-");
        for (var i=0; i<3; i++) {
            parts[i] = parts[i].replace(/^0+/, '');
        }
        return parts[1] + "/" + parts[2] + "/" + parts[0].substring(2,4);
    });

    let alertTotalArray = keys.map((key) => {
        return(alertData[key]);
    });

    const data = {
        labels: labels,
        datasets: [{
            label: 'Alerts',
            data: alertTotalArray,
            type: 'bar',
            backgroundColor: (
                'rgba(53, 121, 189, 0.5)'
            ),
            borderColor: (
                'rgba(53, 121, 189, 1)'
            ),
            borderWidth: 2,
            fill: 1
        }]
    };

    const options =  {
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero:true,
                    userCallback: function(label, index, labels) {
                        if (Math.floor(label) === label) {
                            return label;
                        }
                    },
                },
            }]
        },
        maintainAspectRatio: false
    };

    return (
        <Container className="alertHistoryGraphBackground"> 
                <Row style={{'height': '10%'}} >
                    <p className="alertHistoryGraphLabel">Alert History</p>
                </Row>
                <Row className="alertHistoryGraph" >
                {keys.length === 0 ?
                    <p className="noAlertsLabel">No Alerts</p>
                :   
                    <Bar data={data} options={options} />
                }
                </Row>
        </Container>
    );
};

export default AlertHistoryGraph;