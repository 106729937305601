import React, { Component } from 'react';
import axios from 'axios';
import config from '../../utilities/config';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { ButtonGroup } from 'react-bootstrap';

class AlertSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alerts: null,
            buildings: null,
            showModal: false,
            buildingID: null, 
            gallons: null, 
            hours: null, 
            email: 0,
            text: 0,
            waterButton: true,
            tempButton: false,
            humidButton: false,
            buildingLocations: null,
            locationOptions: null
        };
    }

    componentDidMount() {
        this.getUserBuildings();
    }

    getUserBuildings() {
        var getConfig = {
            method: 'get',
            url: `${config.api}user/${config.userID}/buildings`,
            headers: { 
              'Authorization': `Bearer ${config.token}`
            }
        };
        
        axios(getConfig)
        .then((response) => {
            let data = response.data;
            //console.log(data);
            var buildings = [];
            var buildingOptions = [];
            data.forEach((building) => {
                buildings[building.id] = building.label;
                buildingOptions.push(
                    <option key={building.id} value={building.id}>{building.label}</option>
                );
            });
            this.setState({
                buildings: buildings,
                buildingOptions: buildingOptions
            })
            this.getUserAlertSettings();
            this.getUserTemperatureAlertSettings();
            this.getUserHumidityAlertSettings();
        })
        .catch((error) => {
            //console.log(error);
        });
    }

    getUserAlertSettings = () => {
        var getConfig = {
            method: 'get',
            url: `${config.api}user/${config.userID}/settings/alerts`,
            headers: { 
              'Authorization': `Bearer ${config.token}`
            }
        };
        
        axios(getConfig)
        .then((response) => {
            let data = response.data;
            //console.log(data);
            this.createTableRows(data);
        })
        .catch((error) => {
            //console.log(error);
        });
    }
    getUserTemperatureAlertSettings = () => {
        var getConfig = {
            method: 'get',
            url: `${config.api}users/settings/alerts/preferences?userID=${config.userID}&type=temperature`,
            headers: { 
              'Authorization': `Bearer ${config.token}`
            }
        };
        
        axios(getConfig)
        .then((response) => {
            let data = response.data;
            //console.log(data);
            this.createTemperatureTableRows(data);
        })
        .catch((error) => {
            //console.log(error);
        });
    }
    getUserHumidityAlertSettings = () => {
        var getConfig = {
            method: 'get',
            url: `${config.api}users/settings/alerts/preferences?userID=${config.userID}&type=humidity`,
            headers: { 
              'Authorization': `Bearer ${config.token}`
            }
        };
        
        axios(getConfig)
        .then((response) => {
            let data = response.data;
            //console.log(data);
            this.createHumidityTableRows(data);
        })
        .catch((error) => {
            //console.log(error);
        });
    }

    createTableRows = (alertData) => {
        //console.log(alertData);
        //console.log(this.state.buildings);
        const tableRows = alertData.map((alert) => {
            const buildingLabel = this.state.buildings[alert.buildingID];
            let text = " ";
            if (alert.sendText == 1) {
                text = "\u2713"
            }
            let email = " ";
            if (alert.sendEmail == 1) {
                email = "\u2713"
            }
            return (
                <tr key={buildingLabel}>
                    <td>{buildingLabel}</td>
                    <td>{alert.gallons} gallons in {alert.hours} hours</td>
                    <td style={{'textAlign': 'center'}}>{text}</td>
                    <td style={{'textAlign': 'center'}}>{email}</td>
                </tr>
            );
        });
        this.setState({
            alerts: tableRows
        });
    }

    createTemperatureTableRows = (alertData) => {
        //console.log(alertData);
        //console.log(this.state.buildings);
        const tableRows = alertData.map((alert) => {
            const buildingLabel = this.state.buildings[alert.buildingID] ? this.state.buildings[alert.buildingID] : 'Hotel 1';
            const locationLabel = alert.locationName ? alert.locationName : '147';
            const minTemp = (Math.round(((alert.lowerLimit * 9 / 5 + 32) * 100) / 100)) + "°F";
            const maxTemp = (Math.round(((alert.upperLimit * 9 / 5 + 32) * 100) / 100)) + "°F";
            let text = " ";
            if (alert.sendTextMessage == 1) {
                text = "\u2713"
            }
            let email = " ";
            if (alert.sendEmailMessage == 1) {
                email = "\u2713"
            }
            return (
                <tr key={locationLabel}>
                    <td>{buildingLabel}</td>
                    <td>{locationLabel}</td>
                    <td>{minTemp} - {maxTemp}</td>
                    <td style={{'textAlign': 'center'}}>{text}</td>
                    <td style={{'textAlign': 'center'}}>{email}</td>
                </tr>
            );
        });
        this.setState({
            temperatureAlerts: tableRows
        });
    }

    createHumidityTableRows = (alertData) => {
        //console.log(alertData);
        //console.log(this.state.buildings);
        const tableRows = alertData.map((alert) => {
            const buildingLabel = this.state.buildings[alert.buildingID] ? this.state.buildings[alert.buildingID] : 'Hotel 1';
            const locationLabel = alert.locationName ? alert.locationName : '147';
            const minHumid = Math.round(alert.lowerLimit) + "%";
            const maxhumid = Math.round(alert.upperLimit) + "%";
            let text = " ";
            if (alert.sendTextMessage == 1) {
                text = "\u2713"
            }
            let email = " ";
            if (alert.sendEmailMessage == 1) {
                email = "\u2713"
            }
            return (
                <tr key={locationLabel}>
                    <td>{buildingLabel}</td>
                    <td>{locationLabel}</td>
                    <td>{minHumid} - {maxhumid}</td>
                    <td style={{'textAlign': 'center'}}>{text}</td>
                    <td style={{'textAlign': 'center'}}>{email}</td>
                </tr>
            );
        });
        this.setState({
            humidityAlerts: tableRows
        });
    }


    commitNewAlert = () => {
        Swal.showLoading()
        
        var data = `[{"userID": ${config.userID}, "buildingID": ${this.state.buildingID}, "hours": ${this.state.hours}, "gallons": ${this.state.gallons}, "sendEmail": ${this.state.email}, "sendText": ${this.state.text}}]`;
        //console.log(data);
        
        var postConfig = {
        method: 'post',
        url: `${config.api}users/settings/alerts`,
        headers: { 
            'Authorization': `Bearer ${config.token}`, 
            'Content-Type': 'text/plain'
        },
        data : data
        };

        axios(postConfig)
        .then((response) => {
            this.getUserAlertSettings()
            Swal.close();
            Swal.fire({
                'title': "Alert Saved",
                'icon': 'success'
            });
        })
        .catch((error) => {
            Swal.close();
            Swal.fire({
                'title': "Something went wrong",
                'icon': 'error'
            });
            //console.log(error);
        });

    }

    handleChange = (event) => {
        switch (event.target.id) {
            case "textCheckbox":
                //console.log("text");
                //console.log(event.target.checked);
                if (event.target.checked) {
                    this.setState({
                        text: 1
                    });
                }
                else if (!event.target.checked) {
                    this.setState({
                        text: 0
                    });
                }
                break;
            case "emailCheckbox":
                //console.log("email");
                //console.log(event.target.checked);
                if (event.target.checked) {
                    this.setState({
                        email: 1
                    });
                }
                else if (!event.target.checked) {
                    this.setState({
                        email: 0
                    });
                }
                break;
            case "buildingInput":
                //console.log("building");
                //console.log(event.target.value);
                this.getBuildingLocations(event.target.value);
                this.setState({
                    buildingID: event.target.value
                });
                break;
            case "gallonInput":
                //console.log("gallons");
                //console.log(event.target.value);
                this.setState({
                    gallons: event.target.value
                });
                break;
            case "hourInput":
                //console.log("hours");
                //console.log(event.target.value);
                this.setState({
                    hours: event.target.value
                });
                break;
            default:
                //console.log("default");
                break;
        }
    }

    handleMetricChange = (metric) => {
        switch (metric) {
            case "water":
                this.setState({
                    waterButton: true,
                    tempButton: false,
                    humidButton: false
                })
                break;
            case "temp":
                this.setState({
                    waterButton: false,
                    tempButton: true,
                    humidButton: false
                })
                break;
            case "humid":
                this.setState({
                    waterButton: false,
                    tempButton: false,
                    humidButton: true
                })
                break;
            default:
                //console.log("default");
                break;
        }
    }

    handleShow = () => {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        this.setState({
            showModal: false,
            buildingID: null, 
            gallons: null, 
            hours: null, 
            email: 0,
            text: 0
        });
    }

    saveAlert = () => {
        this.setState({
            showModal: false
        }, () => this.commitNewAlert());
        
    }

    getBuildingLocations(selectedBuilding) {
        //console.log(selectedBuilding);
        let url = `${config.api}buildings/${selectedBuilding}/locations`;
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
            .then((response) => {
                const data = response["data"];
                var locationsByID = [];
                var locationOptions = [];
                var locations = data.map((item) => {
                    item.key=item.toiletID+item.userID
                    locationsByID[item.locationID] = item;
                    return (item);
                });
                locations.sort((a, b) => a.locationName - b.locationName);
                locations.forEach((location) => {
                    locationOptions.push(
                        <option key={location.locationID} value={location.locationID}>{location.locationLabel}</option>
                    );
                });
                this.setState({
                    buildingLocations: locationsByID,
                    locationOptions: locationOptions
                });
            },
                (error) => {
                    Swal.close();
                    Swal.fire({
                        title: "Error Getting Building Locations",
                        text: error,
                        icon: 'error'
                    });
                }
            );
    }
    
    render() {
        return (
            <Container style={{'height': '100%'}}>
                <Row style={{'paddingTop': '50px'}}>
                    <Col xs={12} md={6}>
                        <h2 style={{'marginLeft': '0px'}}>Custom Alerts</h2>
                    </Col>
                    <Col xs={12} md={6}>
                        <Button onClick={this.handleShow} style={{'backgroundColor': '#3578bd', 'borderColor': '#3578bd', 'position': 'absolute', 'right': '0'}} >&#65291; New Custom Alert </Button>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        <Table striped responsive bordered >
                            <thead>
                                <tr>
                                    <th>Building</th>
                                    <th>Alert when you have used</th>
                                    <th>Text</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.alerts}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <br />
                {this.state.temperatureAlerts &&
                <Row>
                    <Col xs={12}>
                        <Table striped responsive bordered >
                            <thead>
                                <tr>
                                    <th>Building</th>
                                    <th>Location</th>
                                    <th>Alert when temperature is outside of</th>
                                    <th>Text</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.temperatureAlerts}
                            </tbody>
                        </Table>
                    </Col>
                </Row>}
                <br />
                {this.state.humidityAlerts && 
                <Row>
                    <Col xs={12}>
                        <Table striped responsive bordered >
                            <thead>
                                <tr>
                                    <th>Building</th>
                                    <th>Location</th>
                                    <th>Alert when humidity is outside of</th>
                                    <th>Text</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.humidityAlerts}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                }

                <Modal 
                    show={this.state.showModal} 
                    onHide={this.handleClose} 
                    style={{opacity:1}} 
                    animation={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>New Custom Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Row>
                                <Form.Group style={{'paddingLeft': '30px'}}>
                                    {/* <Form.Label style={{'fontWeight': 'bold'}} >This alert is for</Form.Label> 
                                    <br /> */}
                                        {/* <ButtonGroup>
                                            <Button variant='light' active={this.state.waterButton} onClick={() => this.handleMetricChange('water')} >Water</Button>
                                            <Button variant='light' active={this.state.tempButton} onClick={() => this.handleMetricChange('temp')} >Temperature</Button>
                                            <Button variant='light' active={this.state.humidButton} onClick={() => this.handleMetricChange('humid')} >Humidity</Button>
                                        </ButtonGroup>
                                    <br /> */}
                                    <Form.Label style={{'fontWeight': 'bold'}} >Send me a</Form.Label> 
                                    <br />
                                        <Form.Check id="textCheckbox" onChange={this.handleChange} type="checkbox" label="Text Message" />
                                        <Form.Check id="emailCheckbox" onChange={this.handleChange} type="checkbox" label="Email" /> 
                                    <br />
                                    <Form.Label style={{'fontWeight': 'bold'}} >When a Location at</Form.Label> 
                                    <br />
                                    <Form.Control id="buildingInput" onChange={this.handleChange} as="select">
                                        <option>-</option>
                                        {this.state.buildingOptions}
                                    </Form.Control>
                                    {/* <br />
                                    {this.state.locationOptions &&
                                    <Form.Control id="buildingLoctionsInput" onChange={this.handleChange} as="select">
                                        <option>-</option>
                                        {this.state.locationOptions}
                                    </Form.Control> */}
                                    {/* } */}
                                    <br />
                                    <Form.Label style={{'fontWeight': 'bold'}} >Has used</Form.Label> 
                                    <br />
                                    <Row >
                                            <Col xs="auto">
                                                <Form.Control id="gallonInput" onChange={this.handleChange} size='sm' as="select">
                                                    <option>-</option>
                                                    <option>50</option>
                                                    <option>100</option>
                                                    <option>150</option>
                                                    <option>200</option>
                                                    <option>250</option>
                                                    <option>300</option>
                                                    <option>350</option>
                                                </Form.Control>
                                            </Col>
                                            <Col xs="auto">
                                                gallons in 
                                            </Col>
                                            <Col xs="auto">
                                            <Form.Control id="hourInput" onChange={this.handleChange} size='sm' as="select">
                                                    <option>-</option>
                                                    <option>4</option>
                                                    <option>8</option>
                                                    <option>12</option>
                                                    <option>16</option>
                                                    <option>20</option>
                                                    <option>24</option>
                                                </Form.Control>
                                            </Col>
                                            <Col xs="auto">
                                                hours.
                                            </Col>
                                    </Row>
                                </Form.Group>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={this.saveAlert}>
                            Save Alert
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

export default AlertSettings;