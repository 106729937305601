import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import config from '../../utilities/config';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

class RetireDeviceView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDevice: null,
            scannedDevices: [],
            deviceRows: null
        };
    }    

    componentDidMount(){
        gtag('config', config.gtag, {
            page_title: 'RetireDevice_view',
          })
        gtag('event','page_view')
    }
    handleInputChange = (event) => {
        event.preventDefault();
        this.setState({
            selectedDevice: event.target.value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.selectedDevice == null || this.state.selectedDevice == "") {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'You have to enter an Device ID.',
            });
            document.getElementById('retireInput').value = "";
            return; 
        }

        const selectedDevice = this.state.selectedDevice;
        this.state.scannedDevices.push(selectedDevice);
        this.updateRows();

        axios({
            method: 'get',
            url: `${config.api}devices/${selectedDevice}`,
            headers: { 
                    'Authorization': `Bearer ${config.token}`,
                }
            })
        .then((response) => {
            const data = response["data"][0];
            const locationID = data["locationID"];
            if (locationID == null || locationID == undefined) {
                this.unassign(data);
            }
            else {
                this.uninstall(data);
            }
        })
        .catch((error) => {
            //console.log(error);
        });
    }

    uninstall = (device) => {

        axios({
            method: 'put',
            url: `${config.api}locations`,
            headers: { 
                'Authorization': `Bearer ${config.token}`, 
                'Content-Type': 'text/plain'
            },
            data : `[{"id": ${device.locationID}, "deviceID": null}]`
        })
        .then((response) => {
            const data = response["data"][0];
            if (data.errorCode == null) {
                this.unassign(device);
            }
        })
        .catch((error) => {
            //console.log(error);
        });

    }

    unassign = (device) => {
        
        axios({
            method: 'put',
            url: `${config.api}devices`,
            headers: { 
                'Authorization': `Bearer ${config.token}`, 
                'Content-Type': 'text/plain'
            },
            data : `[{"id": ${device.id}, "userID": 38, "installed": 0}]`
        })
        .then((response) => {
            const data = response["data"][0];
            if (data.errorCode == null) {
                Swal.fire({
                    icon: 'success',
                    title: 'Device has been reset'
                })
            }
        })
        .catch((error) => {
            //console.log(error);
        });
    
    }

    updateRows = () => {
        const rows = this.state.scannedDevices.map((curDevice) => {
            return (
                <tr>
                    <td>{curDevice}</td>
                </tr>
            )
        })
        this.setState({
            deviceRows: rows
        })
    }
    
    render() {
        return (
            <Container>
                    <Row>
                        <Col xs={6} sm={4}>
                            <h2>Retire Devices</h2>
                            <br />
                            <br />
                            <Form onSubmit={this.handleSubmit} >
                                <Form.Group>
                                    <Form.Label>Device ID</Form.Label>
                                    <Form.Control type="text" id="retireInput" placeholder="123456-123456" onChange={this.handleInputChange} />
                                </Form.Group>
                                <Button variant="primary" id="retireSubmitButton" type="submit" >Submit</Button>
                            </Form>
                            <br />
                            <br />
                        </Col>
                        <Col xs={6} sm={8}>
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>Device ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.deviceRows}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
        );
    }
}

export default withRouter(RetireDeviceView);