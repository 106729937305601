import React, { Component } from 'react';
import axios from 'axios';
import config from '../../utilities/config';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';


class PropertySettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            properties: null,
            propertyCards: null,
            buildings: null,
            newPropertyName: null,
            selectedProperty: null,
            orgUsers: []
        };
    }

    componentDidMount() {
        this.getOrgProperties();
    }

    getOrgProperties = () => {
        var getConfig = {
            method: 'get',
            url: `${config.api}organization/${config.orgID}/properties`,
            headers: { 
              'Authorization': `Bearer ${config.token}`
            }
        };
        
        axios(getConfig)
        .then((response) => {
            let data = response.data;
            //console.log(data);
            this.createPropertyCards(data);
        })
        .catch((error) => {
            //console.log(error);
        });
    }

    createPropertyCards = (propData) => {
        var properties = [];
        const propertyCards = propData.map((property) => {
            properties[property.id] = property.label;
            return (
                <Card style={{ width: '18rem' }} propID={property.id} onClick={this.handleEditClick} >
                    <Card.Body>
                        <Card.Title>{property.label}</Card.Title>
                    </Card.Body>
                </Card>
            );
        });

        var propCardArray = [];
        var tempArray = [];
        var cardCount = 0;
        for(let i=0; i<propertyCards.length; i++) {
            tempArray.push(propertyCards[i]);
            cardCount++;
            if (cardCount == 3 || i == propertyCards.length - 1) {
                propCardArray.push(
                    <div>
                        <CardGroup>
                            {tempArray}
                        </CardGroup>
                        <br />
                    </div>
                );
                propCardArray.push(
                );
                tempArray = [];
                cardCount = 0;
            }
        }

        this.setState({
            propertyCards: propCardArray,
            properties: properties
        });
    }

    handleEditClick = (event) => {
        const selProp = event.currentTarget.getAttribute('propID');
        //console.log(selProp);
        this.setState({
            selectedProperty: selProp
        });
        this.getPropertyBuildings(selProp);
        this.getPropertyRoles(selProp);
    }

    getPropertyBuildings = (propID) => {
        var getConfig = {
            method: 'get',
            url: `${config.api}property/${propID}/buildings`,
            headers: { 
                'Authorization': `Bearer ${config.token}`
            }
        };
        axios(getConfig)
        .then((response) => {
            let data = response.data;
            //console.log(data);
            const buildingRows = data.map((building) => {
                return(
                    <tr>
                        <td>{building.label}</td>
                        <td>{building.address}, {building.city}, {building.zip}</td>
                    </tr>
                );
            });
            this.setState({
                buildingRows: buildingRows
            });
        })
        .catch((error) => {
            //console.log(error);
        });
    }

    getPropertyRoles = (propID) => {
        var getConfig = {
            method: 'get',
            url: `${config.api}property/${propID}/user_roles`,
            headers: { 
                'Authorization': `Bearer ${config.token}`
            }
        };
        axios(getConfig)
        .then((response) => {
            let data = response.data;
            //console.log(data);
            const dataKeys = Object.keys(data);
            const propRoleRows = dataKeys.map((userKey) => {
                const userInfo = data[userKey];
                var userPropRole = ""
                switch(userInfo.propertyRoleID) {
                    case 4:
                        userPropRole = "Owner"
                        break;
                    case 3:
                        userPropRole = (
                            <Form.Control id="userRoleInput" onChange={this.handleChange.bind(this)} size='sm' as="select">
                                <option selected value={3} userID={userInfo.id}>Admin</option>
                                <option value={2} userID={userInfo.id}>User</option>
                            </Form.Control>
                        );
                        break;
                    case 2:
                        userPropRole = (
                            <Form.Control id="userRoleInput" onChange={this.handleChange.bind(this)} size='sm' as="select">
                                <option value={3} userID={userInfo.id}>Admin</option>
                                <option selected value={2} userID={userInfo.id}>User</option>
                            </Form.Control>
                        );
                        break;
                    case 1:
                        userPropRole = (
                            <Form.Control id="userRoleInput" onChange={this.handleChange.bind(this)} size='sm' as="select">
                                <option value={3} userID={userInfo.id}>Admin</option>
                                <option selected value={2} userID={userInfo.id}>User</option>
                            </Form.Control>
                        );
                        break;
                    default:
                        break;
                }
                return(
                    <tr>
                        <td>{userInfo.firstName} {userInfo.lastName}</td>
                        <td>{userPropRole}</td>
                    </tr>
                );
            });
            this.setState({
                propRoleRows: propRoleRows
            });
        })
        .catch((error) => {
            //console.log(error);
        });
    }

    commitNewProperty = () => {
        Swal.showLoading();
        var data = `[{"label": \"${this.state.newPropertyName}\", "organizationID": ${config.orgID}}]`;
        //console.log(data);
        
        var postConfig = {
        method: 'post',
        url: `${config.api}property`,
        headers: { 
            'Authorization': `Bearer ${config.token}`, 
            'Content-Type': 'text/plain'
        },
        data : data
        };

        axios(postConfig)
        .then((response) => {
            const data = response.data;
            const propID = data[0].id;
            this.setPropRole(propID);
        })
        .catch((error) => {
            Swal.close();
            Swal.fire({
                'title': "Something went wrong",
                'icon': 'error'
            });
            //console.log(error);
        });

    }

    setPropRole = (propID) => {
        var data = `[{"propertyID": ${propID}, "userID": ${config.userID}, "propertyRoleID": 4}]`;
        //console.log(data);
        
        var postConfig = {
        method: 'post',
        url: `${config.api}user-property-roles`,
        headers: { 
            'Authorization': `Bearer ${config.token}`, 
            'Content-Type': 'text/plain'
        },
        data : data
        };

        axios(postConfig)
        .then((response) => {
            Swal.close();
            Swal.fire({
                'title': "Property Created",
                'icon': 'success'
            });
            this.getOrgProperties();
        })
        .catch((error) => {
            Swal.close();
            Swal.fire({
                'title': "Something went wrong",
                'icon': 'error'
            });
            //console.log(error);
        });

    }

    commitNewBuilding = () => {
        Swal.showLoading();
        var data = `[{"userID": ${config.userID}, "label": \"${this.state.newBuildingName}\", "propertyID": ${this.state.selectedProperty}}]`;
        //console.log(data);
        const t = this
        var postConfig = {
        method: 'post',
        url: `${config.api}buildings`,
        headers: { 
            'Authorization': `Bearer ${config.token}`, 
            'Content-Type': 'text/plain'
        },
        data : data
        };
        const rb = this.getPropertyBuildings
        axios(postConfig)
        .then((response) => {
            this.getPropertyBuildings(t.state.selectedProperty);
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Creating Building',
            //     showConfirmButton: false,
            //     timer: 5000
            // }).then(function () {
            //     if (result.dismiss === Swal.DismissReason.timer) {

            //         Swal.fire({
            //             'title': "Building Created",
            //             'icon': 'success'
            //         }).then(function () {
            //             t.setState({ state: t.state });
            //         });
            //     }
            // });
            setTimeout(function(){
                Swal.close()
                Swal.fire({
                        'title': "Building Created",
                        'icon': 'success'
                    }).then(function () {
                        t.setState({ state: t.state });
                });
            }, 5000);




        })
        .catch((error) => {
            Swal.close();
            Swal.fire({
                'title': "Something went wrong",
                'icon': 'error'
            });
            //console.log(error);
        });

    }

    handleChange = (event) => {
        switch (event.target.id) {
            case "newPropertyInput":
                //console.log("property");
                //console.log(event.target.value);
                this.setState({
                    newPropertyName: event.target.value
                });
                break;
            case "newBuildingInput":
                //console.log("building");
                //console.log(event.target.value);
                this.setState({
                    newBuildingName: event.target.value
                });
                break;
            default:
                //console.log("default");
                break;
        }
    }

    handleShow = (event) => {
        //console.log(event.target.id);
        switch(event.target.id) {
            case 'addProperty':
                this.setState({
                    showAddPropertyModal: true
                });
                break;
            case 'addBuilding':
                this.setState({
                    showAddBuildingModal: true
                });
                break;
            case 'addPropRole':
                this.setState({
                    showPropertyRoleModal: true
                });
                break;
            default:
                //console.log("default");
                break;
        }
    }

    handleClose = () => {
        this.setState({
            showAddPropertyModal: false,
            showAddBuildingModal: false,
            showPropertyRoleModal: false
        });
    }

    saveProperty = () => {
        this.setState({
            showAddPropertyModal: false
        }, () => this.commitNewProperty());
    }
    
    saveBuilding = () => {
        this.setState({
            showAddBuildingModal: false
        }, () => this.commitNewBuilding());
    }

    goBack = () => {
        this.setState({
            selectedProperty: null
        });
    }
    
    render() {
        //console.log("RERENDERING")
        //console.log(this.state.selectedProperty)
        if (this.state.selectedProperty == null) {//There is no Property selected
            
            return (
                <Container style={{'height': '100%'}}>
                    <Row style={{'paddingTop': '50px'}}>
                        <Col sm={6}>
                            <h2 style={{'marginLeft': '0px'}}>Properties</h2>
                        </Col>
                        <Col sm={6}>
                        <Button id="addProperty" onClick={this.handleShow} style={{'backgroundColor': '#3578bd', 'borderColor': '#3578bd', 'position': 'absolute', 'right': '0'}} >&#65291; New Property</Button>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Row style={{"justifyContent": "center", "alignItems": "center", "paddingTop": "20px"}}>
                        {this.state.propertyCards}
                    </Row>
    
                    {/* Add Proeprty Modal */}
                    <Modal 
                        show={this.state.showAddPropertyModal} 
                        onHide={this.handleClose} 
                        style={{opacity:1}} 
                        animation={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>New Property</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={this.handleSubmit}>
                                {/* Name */}
                                <Form.Group>
                                    <Form.Label>Property Name</Form.Label>
                                    <Form.Control id="newPropertyInput" type="text" onChange={this.handleChange} placeholder="New Property" />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={this.saveProperty}>
                                Add Property
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            );

        }
        else { //A Property is selected

            const propertyLabel = this.state.properties[this.state.selectedProperty];

            return (
                <Container style={{'height': '100%'}}>
                    <Row style={{'paddingTop': '50px'}}>
                        <Col sm={12}>
                            <h2 style={{'marginLeft': '0px'}}><a href="#" onClick={this.goBack} >All Properties</a> / {propertyLabel}</h2>
                        </Col>
                    </Row>
                    <Row style={{"justifyContent": "center", "alignItems": "center", "paddingTop": "20px"}}>
                        <Col xs={12} style={{"height": "60vh", "overflow": "auto"}}>
                            <Row>
                                <Col xs={12} md={6}>
                                    <h4 style={{'marginLeft': '0px'}}>Buildings</h4>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Button id="addBuilding" onClick={this.handleShow} style={{'backgroundColor': '#3578bd', 'borderColor': '#3578bd', 'position': 'absolute', 'right': '0'}} >&#65291; New Building</Button>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row style={{'paddingTop': '10px'}}>
                                <Table striped responsive bordered size="sm">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Address</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.buildingRows}
                                    </tbody>
                                </Table>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <h4 style={{'marginLeft': '0px'}}>Property Roles</h4>
                                </Col>
                                {/* <Col sm={6}>
                                <Button id="addPropRole" onClick={this.handleShow} style={{'backgroundColor': '#3578bd', 'borderColor': '#3578bd', 'position': 'absolute', 'right': '0'}} >&#65291; New Role</Button>
                                </Col> */}
                            </Row>
                            <Row style={{'paddingTop': '10px'}}>
                                <Table striped responsive bordered size="sm">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Role</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.propRoleRows}
                                    </tbody>
                                </Table>
                            </Row>
                        </Col>
                    </Row>
    
                    {/* Add Building Modal */}
                    <Modal show={this.state.showAddBuildingModal} onHide={this.handleClose} style={{opacity:1}} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Building</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={this.handleSubmit}>
                                {/* Name */}
                                <Form.Group>
                                    <Form.Label>Building Name</Form.Label>
                                    <Form.Control id="newBuildingInput" type="text" onChange={this.handleChange} placeholder="New Building" />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={this.saveBuilding}>
                                Add Building
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            );

        }
        
    }
}

export default PropertySettings;