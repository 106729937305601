import React from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

const AllLocationsTable = (props) => {
    const buildingIDs = props.buildingIDs;
    const buildingNames = props.buildingNames;

    var locationTables = [];
    const buildingKeys = Object.keys(buildingIDs);
    buildingKeys.forEach((id) => {
        const locations = buildingIDs[id];
        const locationRows = locations.map((loc) => {
            return (
                <tr>
                    <td>{loc.locationName}</td>
                </tr>
            );
        });
        locationTables.push(
            <Container>
                <h3>{buildingNames[id]}</h3>
                <Table striped bordered hover>
                    <thead>
                    </thead>
                    <tbody>
                        {locationRows}
                    </tbody>
                </Table>
                <br />
            </Container>
        )
    });

    return (
        <Container>
            {locationTables}
        </Container>
    );
};

export default AllLocationsTable;