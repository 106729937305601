import React from 'react'


import Container from 'react-bootstrap/Container'

import AlertLookupBlock from './AlertLookupBlock';

export default function AlertDetails(props) {
    const alertData = props.alertData;
    const alertID = props.alertID;

    const alertDetails = alertData.alerts[alertID];
    const alertList = alertDetails.staticAlerts;

    let alertBlocks = alertList.map((curAlert) => {
        const problem = {
            "date": curAlert.aDate,
            "building": curAlert.buildingID,
            "locationLabel": curAlert.locationID,
            "gallons": curAlert.lpGallons,
            "buildingLabel": curAlert.buildingID,
            "severityNum": curAlert.lpSeverityNum,
            "locationID": curAlert.locationID,
            "flushCount": curAlert.lpFlushes,
            "severity": curAlert.lpSeverity,
            "diagnosis": curAlert.lpDiagnosis
        }
        
        let severityLabel = "";
        let severityColor = "";
        switch(problem.severity) {
            case 5:
                severityLabel = "Critical"
                severityColor = "#E73C3E"
                break;
            case 4:
                severityLabel = "High"
                severityColor = "#E73C3E"
                break;
            case 3:
                severityLabel = "Medium"
                severityColor = "#FF8132"
                break;
            case 2:
                severityLabel = "Low"
                severityColor = "#f8de7e"
                break;
            case 1:
                severityLabel = "Very Low"
                severityColor = "#f8de7e"
                break;
            default:
                break;
        }

        //console.log(problem);
        return (
            <AlertLookupBlock 
                severityColor={severityColor} 
                problem={problem}
                flushData={null} 
                showAlertHistory={null}
            />
        )
    });

    return (
        <Container>
            {alertBlocks}
        </Container>
    )
}
alertCount: 2
building: 29
buildingLabel: "Demo Building1"
date: "2021-12-20"
gallons: 1567
locationID: 918
locationLabel: "303"
severityNum: 5