import React from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

const AllPropertiesTable = (props) => {
    const properties = props.properties;
    var propertyRows=[];
    properties.forEach((name) => {
            propertyRows.push (
                <tr key={name.id}>
                    <td>{name.name}</td>
                    <td>{name.Orgid}</td>
                </tr>
            );
    });
    return (
        <Container>
            <Table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>ID</th>
                </tr>
            </thead>
            <tbody>
                {propertyRows}
            </tbody>
        </Table>
        </Container>
    );
};

export default AllPropertiesTable;