import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import LoadingView from '../LoadingView/LoadingView';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import RawDataViewTable from './RawDataViewTable';
import RawDataViewGraphs from "./RawDataViewGraphs";
import { Button, ButtonGroup } from 'react-bootstrap';

var autocompletDevices = [];

class RawDataView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadeddevices: false,
            deviceSerialNum: null,
            viewtype: true,
            allDevices: [],
        };
    }



    handleInputStrChange = (event) => {
        //console.log(event);
        for (let x of autocompletDevices) {
            if (x.name == event) {
                config.DeviceID = event;
                this.setState({
                    deviceSerialNum: event
                });
                return;
            }
        }
        Swal.fire({
            icon: "error",
            title: "Can't find that device",
            text: 'Double check your Device ID'
        })

    }

    handleInputObjChange = (event) => {
        //console.log(event.name);
        config.DeviceID = event;
        //console.log(config.DeviceID)
        this.setState({
            deviceSerialNum: event.name
        });
    }

    getAllDevices() {
        //Get all Users
        let url = `${config.api}get-all-devices`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                //console.log(response)
                const data = response["data"];
                const devices = data.map((item) => {
                    autocompletDevices.push({ name: item.deviceID, id: item.id });
                    return item;
                });
                this.setState({
                    allDevices: devices,
                    loadeddevices: true
                });

            },
                (error) => {
                    //console.log(error);
                }
            );
    }
    handleShow = () => {

    }
    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'RawData_view',
          })
        gtag('event','page_view')
        this.getAllDevices()
    }

    render() {
        //console.log(this.state.loadeddevices)
        if (this.state.loadeddevices) {
            //console.log(this.state.loadeddevices)
            //console.log("LENGTH", autocompletDevices)
            //console.log(autocompletDevices.length)
            if (this.state.viewtype) {
                var view = <RawDataViewTable />
            }
            else {
                var view = <RawDataViewGraphs />
            }
            Swal.close();
            return (
                <Container>
                    <Row>
                        <Col style={{ marginTop: 12, padding: 12, marginBottom: 30 }}>
                            <ReactSearchAutocomplete
                                styling={{
                                    background: 'rgba(255, 255, 255, 0.9)',
                                    padding: '2px 0',
                                    fontSize: '90%',
                                    position: 'fixed',
                                    overflow: 'auto',
                                    maxHeight: '50%',
                                    zIndex: '999',
                                }}
                                items={autocompletDevices}
                                onSearch={() => {this.handleInputStrChange}}//ignored
                                onHover={() => { }}//ignored
                                onSelect={this.handleInputObjChange}
                                onFocus={() => { }}//ignored
                                maxResults={6}
                                showItemsOnFocus={true}
                                showNoResultsText="No devices found"
                                formatResult={(item) => {
                                    return (
                                        <span>{Object.values(item.name)}</span>
                                    )
                                }}
                            />
                        </Col>
                        <Col style={{ marginTop: 12, padding: 12, marginBottom: 30 }}>
                            <ButtonGroup>
                                <Button onClick={() => {
                                    this.setState({
                                        viewtype: false
                                    });
                                }}>Graphs</Button>
                                <Button onClick={() => {
                                    this.setState({
                                        viewtype: true
                                    });
                                }}>Table</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        {view}
                    </Row>
                </Container>
            )
        }
        else {
            return (
                <div>
                    <LoadingView />
                </div>
            );
        }
    }
}
export default withRouter(RawDataView);

