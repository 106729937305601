import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import LineBreak from '../../utilities/static';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView.js';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

function Device(id, sent, confirmed, scanTime) {
    this.id = id;
    this.sent = sent;
    this.confirmed = confirmed;
    this.scanTime = scanTime
}

var deviceID = "";
var scannedDevices = [];
var allDevices = new Map;
var validateTimer = null;

class ShippingModeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceRows: null,
            submitType: "batch",
            resetOnSuccess: false,
            ssid: null,
            password: null,
            interval: 24,
            loaded: true
        }
        
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'ShippingMode_view',
          })
        gtag('event','page_view')
        // validateTimer = setInterval(this.validateDevices, 10000);
    }

    componentWillUnmount() {
        clearInterval(validateTimer);
    }
    
    handleInputChange = (event) => {
        switch (event.target.id) {
            case 'shippingInput':
                //console.log("Input Change");
                event.preventDefault();
                deviceID = event.target.value;
                break;
            default:
                break;
        }
    }

    updateRows = () => {
        //console.log("Update Rows");
        //console.log(scannedDevices);
        const rows = scannedDevices.map((curDevice) => {
            return (
                <tr>
                    <td>{curDevice.id}</td>
                    <td>{curDevice.sent}</td>
                    {/* <td>{curDevice.confirmed}</td> */}
                </tr>
            )
        })
        this.setState({
            deviceRows: rows
        })
    }

    validateDevices = () => {
        //console.log('In validate');

        const deviceArray = scannedDevices.map((dev) => {
            return dev.id;
        });

        //TBD
        axios({
           method: 'post',
           url: `${config.api}/update/check-verified`,
           headers: {
              'Authorization': `Bearer ${config.token}`, 
              'Content-Type': 'text/plain'
           },
           data: `[{"ids": 3827, "windowUpdate": 600000}]`
        })
        .then((response) => {
           const data = response['data'];
           //console.log(data);
        })
        .catch((error) => {
           //console.log(error);
        });
    }

    resetTable() {
        //console.log("reset");
        scannedDevices = [];
        this.updateRows();
    }

    sendShippingMode = () => {
        //console.log("SHIPPING MODE");
        const deviceArray = scannedDevices.map((dev) => {
            return dev.id;
        });
        //console.log(deviceArray);
        // Send an update to put the propvided devices into shipping mode
        axios({
            method: 'post',
            url: `${config.old_api}/update/set-multi`,
            headers: { 
                'Authorization': `Bearer ${config.token}`
            },
            data: {
                "hrDeviceIDs": deviceArray,
                "token": "6ea3c91cc6e67b3b57f0677c593d6527234716e7a4341a53c4b1048c8cd322b0",
                "config": {
                    "espConfig": {
                        "config": {
                            "wifi": {
                                "sta": {
                                  "enable":false
                                }
                            }
                        }
                    },
            
                    "immVal": false ,
                    "shippingMode": 1
                  
                }
            }
        })
        .then((response) => {
            const data = response["data"];
            //console.log(data);
            scannedDevices.forEach((dev) => {
                dev.sent = "✅";
            });
            this.updateRows();
        })
        .catch((error) => {
            //console.log(error);
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        //console.log("Handle Submit");

        const scanTime = Date.now();
        let scannedDevice = new Device(deviceID, "❌", "❌", scanTime);
        scannedDevices.push(scannedDevice)
        this.updateRows();
        document.getElementById('shippingInput').value = "";
    }

    render() {
        if (this.state.loaded) {
            Swal.close();
            return (
                <Container>
                    <Row>
                        <Col xs={6} sm={4}>
                            <h2>Shipping Mode</h2>
                            <br />
                            <br />
                            <Form onSubmit={this.handleSubmit} style={{paddingBottom: 15}}>
                                <Form.Group>
                                    <Form.Label>Device ID</Form.Label>
                                    <Form.Control type="text" id="shippingInput" placeholder="123456-123456" onChange={this.handleInputChange} />
                                </Form.Group>
                            </Form>
                            <LineBreak color={'#babfc9'} />
                            <Button variant="primary" id="shippingSubmitButton" onClick={this.sendShippingMode} style={{marginTop: 15, width: '100%'}}>Submit All</Button>
                            <br />
                            <br />
                        </Col>
                        <Col xs={6} sm={8}>
                            <Row>
                                <Col sm={10}>
                                    <h3>{scannedDevices.length} devices scanned</h3>
                                </Col>
                                <Col sm={2}>
                                    <Button variant="link" onClick={this.resetTable.bind(this)} >Clear</Button>
                                </Col>
                            </Row>
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>Device ID</th>
                                        <th>Sent</th>
                                        {/* <th>Retrieved</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.deviceRows}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            );
        }
        else {
            return(
                <LoadingView />
            );
        }
    }
}

export default withRouter(ShippingModeView);