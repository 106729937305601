import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import WiFiForm from '../../components/WiFiForm.js/WiFiForm'
import './WiFiForm.scss'

import Sidebar from '../../components/Sidebar/Sidebar.js';

export default class WiFiCredentials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#EBF2F8";
        window.addEventListener('resize', () => this.setState({windowWidth: window.innerWidth}));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.setState({windowWidth: window.innerWidth}));
    }
    
  render() {
    return (

        <div id="pageOuterContainer" className="pageOuterContainer" >
            {this.state.windowWidth > 650 &&
                <Sidebar
                    pageWrapId={"pageWrap"}
                    outerContainerId={"pageOuterContainer"}
                    buttonsVisible={false}
                />
            }
                <div id="pageWrap" className="pageWrap" >

                    {this.state.windowWidth <= 650 &&
                        <Sidebar
                            pageWrapId={"pageWrap"}
                            outerContainerId={"pageOuterContainer"}
                            buttonsVisible={false}
                        />
                    }

                    <Container className='wifiFormContainer'>
                        <WiFiForm />
                    </Container>
            </div>
        </div>

    )
  }
}
