import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView.js';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import config from '../../utilities/config.js';


function Device(id, sent, confirmed, scanTime) {
    this.id = id;
    this.sent = sent;
    this.confirmed = confirmed;
    this.scanTime = scanTime
}

var deviceID = "";
var scannedDevices = [];
var allDeviceMap = {};
var validateTimer = null;

class ProvisionDevicesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceRows: null,
            submitType: "batch",
            resetOnSuccess: false,
            interval: 24,
            loaded: false
        }
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'UpdateStatus_view',
          })
        gtag('event','page_view')
        this.getAllDevices();
        validateTimer = setInterval(this.validateDevices.bind(this), 10000);
    }

    componentWillUnmount() {
        clearInterval(validateTimer);
    }
    
    getAllDevices() {
        //Get all Users
        let url = `${config.api}get-all-devices`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
          )
          .then((response) => {
            const data = response["data"];
            for(let i =0 ; i < data.length; i++){
                let obj = data[i];
                let id = obj["id"]
                let name = obj["deviceID"]
                allDeviceMap[name] =   id;
            }
            this.setState({
                loaded: true
            });
          },
          (error) => {
            //console.log(error);
          }
        );

    }
    
    handleInputChange = (event) => {
        switch (event.target.id) {
            case 'provisionInput':
                //console.log("Input Change");
                event.preventDefault();
                deviceID = event.target.value;
                break;

            case 'provisionInterval':
                this.setState({
                    interval: event.target.value
                })
                //console.log(this.state.interval);
                break;

            default:
                break;
        }
    }

    handleSubmit = (event) => {

        //console.log(allDeviceMap);
        event.preventDefault();
        //console.log("Handle Submit");



        const scanTime = Date.now();
        let scannedDevice = new Device(deviceID, false, false, scanTime);
        scannedDevices.push(scannedDevice)
        this.updateRows();
        document.getElementById('provisionInput').value = "";

    }

    updateRows = () => {
        //console.log("Update Rows");
        //debug starting here
        //console.log(scannedDevices);
        //console.log("DEVICE MAP AT UPDATE ROWS");
        //console.log(allDeviceMap);
        const rows = scannedDevices.map((curDevice) => {
            let tbdSent = "❌";
            if (curDevice.sent) { tbdSent = "✅"}
            let tbdConfirmed = "❌";
            if (curDevice.confirmed) { tbdConfirmed = "✅"}
            return (
                <tr>
                    <td>{curDevice.id}</td>
                    <td>{tbdSent}</td>
                    <td>{tbdConfirmed}</td>
                </tr>
            )
        })
        this.setState({
            deviceRows: rows
        })
    }

    provisionDevices() {
        //Show loading pop up
        Swal.fire({
            title: 'Loading...',
            html: '<strong style="color: red;">Do not close this page.</strong>',
            'timer' : 60000,
            'allowOutsideClick': false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        //Construct array of devices to be provisioned
        var inputArray = [];
        scannedDevices.forEach((device) => {
            if (device.sent == false) {
                inputArray.push(device.id);
            }
        });
        if (inputArray.length == 0) {
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'You must have scanned at least one new device.',
            })
            return; 
        }

        //Provision Devices
        let url = `${config.old_api}update/set-multi`;
        let token = localStorage.getItem("mlToken");
        //console.log("INTERVAL TIME");
        //console.log(this.state.interval);
        let statusSeconds = Math.round(this.state.interval * 60 * 60);

        var postData = JSON.stringify({
            "hrDeviceIDs":inputArray,
            "token":token,
            "config":{"espConfig":{"config":{"sntp":{"enable":true}}},
                "normalStatus": (statusSeconds),   
                "immVal":true}
        });

        //console.log(postData);
        let urlDC = `${config.api}devices`;
        let urlPData = [];
        for (let i =0 ; i < inputArray.length; i++){
            let obj = {}
            obj["hrID"] = true;
            obj["id"] = inputArray[i]
            obj["statusInterval"] = statusSeconds;
            urlPData.push(obj);
        }


        // [
        //     {
        //         "hrID": true,
        //         "id": "122205-170000",
        //         "statusInterval": 120
        //     },
        //     {
        //         "hrID": true,
        //         "id": "122205-170005",
        //         "statusInterval": 120
        //     }
        
        
        // ]
        //console.log("URLPDATA");
        //console.log(urlPData);
        

        axios.post(
            url,
            postData
        )
        .then((response) => {
            let data = response["data"];
            if (data[0]['success']) {
                axios.put(
                    urlDC, 
                    urlPData,
                    {headers: { Authorization: `Bearer ${config.token}` }}
                )
                .then((responseDC) => {
                    scannedDevices.forEach((device) => {
                        device.sent = true;    
                    });
                    this.updateRows();
                    Swal.close();
                    if (this.state.submitType == 'batch') {
                        Swal.fire({
                            icon: 'success',
                            title: 'Done',
                            text: 'Provisioning requests were successfully sent.\nDon\'t forget to press the button on each device!'
                        })
                    }
                },
                (error2) => {
                    //console.log(error2);
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error2,
                    })
                }
                );

            }
            else {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: data,
                })
            }
        },
        (error) => {
            //console.log(error);
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error,
            })
        }
        );

    }



    validateDevices() {
        //console.log('In validate');
        //Construct array of devices to be validated
        var inputArray = [];
        scannedDevices.forEach((device) => {
            //console.log(device);
            if (device.sent == true && device.confirmed == false) {
                inputArray.push(device.id);
            }
        });
        //console.log(inputArray.length);
        if (inputArray.length == 0) {
            return; 
        }
        //console.log("SCANNED");
        inputArray.forEach((device) => {

            var dbID = allDeviceMap[device];

            //Validate Devices
            let url = `${config.api}update/check-verified`;
            let token = localStorage.getItem("mlToken");

            axios.post(
                url,
                {
                    "token": `${token}`,
                    "ids": [dbID],
                    "updateWindow": 3600000
                }
            )
            .then((response) => {
                let data = response["data"][0];
                //console.log(data);
                const validated = data.validated;
                //console.log(validated);
                scannedDevices.forEach((dev) => {
                    if (dev.id == device && validated == true) {
                        dev.confirmed = true;
                    }
                });
                this.updateRows();
            },
            (error) => {
                //console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                })
            }
            );
        });
    }

    handleSettingsChange = (event) => {
        switch (event.target.id) {
            case "batch":
                //console.log(event.target.checked);
                document.getElementById('provisionSubmitButton').disabled = false;
                this.setState({
                    submitType: 'batch'
                });
                break;

            default:
                break;
        }
    }

    resetTable() {
        //console.log("reset");
        scannedDevices = [];
        this.updateRows();
    }

    render() {
        if (this.state.loaded) {
            Swal.close();
            return (
                <Container>
                    <Row>
                        <Col xs={6} sm={4}>
                            <h2>Provision Devices</h2>
                            <br />
                            <br />
                            <Form>
                                <Form.Group>
                                    <Form.Label>Status Interval (HOURS)</Form.Label>
                                    <Form.Control type="text" id="provisionInterval" defaultValue={this.state.interval} onChange={this.handleInputChange} />
                                </Form.Group>
                            </Form>
                            <br />
                            <Form onSubmit={this.handleSubmit} >
                                <Form.Group>
                                    <Form.Label>Device ID</Form.Label>
                                    <Form.Control type="text" id="provisionInput" placeholder="123456-123456" onChange={this.handleInputChange} />
                                </Form.Group>
                                <Button variant="primary" id="provisionSubmitButton" onClick={this.provisionDevices.bind(this)}>Submit</Button>
                            </Form>
                            <br />
                            <br />
                        </Col>
                        <Col xs={6} sm={8}>
                            <Row>
                                <Col sm={10}>
                                    <h3>{scannedDevices.length} devices scanned</h3>
                                </Col>
                                <Col sm={2}>
                                    <Button variant="link" onClick={this.resetTable.bind(this)} >Clear</Button>
                                </Col>
                            </Row>
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>Device ID</th>
                                        <th>Sent</th>
                                        <th>Confirmed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.deviceRows}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            );
        }
        else {
            return(
                <LoadingView />
            );
        }
    }
}

export default withRouter(ProvisionDevicesView);