import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import LineBreak from '../../utilities/static';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView.js';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { ButtonGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

function Device(id, sent, model, scanTime, serialNum) {
    this.id = id;
    this.sent = sent;
    this.model = model;
    this.scanTime = scanTime;
    this.serialNum = serialNum;
}

var deviceID = "";
var scannedDevices = [];
var allDevices = new Map;
var validateTimer = null;

class ModelAssignmentView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceRows: null,
            resetOnSuccess: false,
            modelSelected: null,
            interval: 24,
            loaded: true
        }
        
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'Model_Assignment_view',
          })
        gtag('event','page_view')
    }

    componentWillUnmount() {
    }
    
    handleInputChange = (event) => {
        switch (event.target.id) {
            case 'shippingInput':
                //console.log("Input Change");
                event.preventDefault();
                deviceID = event.target.value;
                break;
            default:
                break;
        }
    }

    updateRows = () => {
        //console.log("Update Rows");
        //console.log(scannedDevices);
        const rows = scannedDevices.map((curDevice) => {
            return (
                <tr>
                    <td>{curDevice.serialNum}</td>
                    <td>{curDevice.model}</td>
                    <td>{curDevice.sent}</td>
                </tr>
            )
        })
        this.setState({
            deviceRows: rows
        })
    }

    resetTable = () => {
        //console.log("reset");
        scannedDevices = [];
        this.updateRows();
    }

    getDBID = () => {
        axios({
           method: 'get',
           url: `${config.api}devices/${deviceID}`,
           headers: {
              'Authorization': `Bearer ${config.token}`
           }           
        })
        .then((response) => {
           const data = response['data'];
           const dbID = data[0].id
           const scanTime = Date.now();
           let scannedDevice = new Device(dbID, "❌", this.state.modelSelected, scanTime, deviceID);
           scannedDevices.push(scannedDevice)
           this.updateRows();
           document.getElementById('shippingInput').value = "";
        })
        .catch((error) => {
           //console.log(error);
        });
    }

    sendModelAssignment = () => {
        //console.log("MODEL ASSIGNMENT");

        scannedDevices.forEach((device) => {
            // Send an update to put the propvided devices into shipping mode
            axios({
                method: 'put',
                url: `${config.api}devices`,
                headers: { 
                    'Authorization': `Bearer ${config.token}`, 
                    'Content-Type': 'text/plain'
                },
                data: `[{"id": ${device.id}, "other": "{\\"productModel\\": \\"${device.model}\\"}"}]`
            })
            .then((response) => {
                const data = response["data"];
                //console.log(data);
                scannedDevices.forEach((dev) => {
                    if (dev.id == device.id) {
                        dev.sent = "✅";
                    }
                });
                this.updateRows();
            })
            .catch((error) => {
                //console.log(error);
            });
        })
    }

    handleModelChange = (event) => {
        //console.log(event);
        //console.log(event.target.value);
        this.setState({
            modelSelected: event.target.value
        });
    }

    handleSubmit = (event) => {        
        event.preventDefault();
        //console.log("Handle Submit");

        if (this.state.modelSelected == null ||
            this.state.modelSelected == undefined) {
                Swal.fire({
                    icon: 'error',
                    title: 'Whoops',
                    text: 'Please select a Model Number'
                });
                return;
        }

        this.getDBID();
    }

    render() {
        if (this.state.loaded) {
            Swal.close();
            return (
                <Container>
                    <Row>
                        <Col xs={6} sm={4}>
                            <h2>Model Number Assignment</h2>
                            <br />
                            <br />
                            <Form onSubmit={this.handleSubmit} style={{paddingBottom: 15}}>
                                <Form.Group>
                                    <ToggleButtonGroup type="radio" name="modelOptions">
                                        <ToggleButton variant="primary" value={"8d"} onClick={this.handleModelChange} style={{"margin-right": "10px"}}>8D</ToggleButton>
                                        <ToggleButton variant="primary" value={"8bf"} onClick={this.handleModelChange} >8B+F</ToggleButton>
                                    </ToggleButtonGroup>
                                    <br />
                                    <br />

                                    <ToggleButtonGroup type="radio" name="modelOptions2">
                                        <ToggleButton variant="primary" value={"M0+OREGON+MARCO"} onClick={this.handleModelChange} style={{"margin-right": "10px"}}>M0+OREGON+MARCO</ToggleButton>
                                    </ToggleButtonGroup>
                                    <br />
                                    <br />
                                    <ToggleButtonGroup type="radio" name="modelOptions2">
                                        <ToggleButton variant="primary" value={"M0+CHINA+MARCO"} onClick={this.handleModelChange} >M0+CHINA+MARCO</ToggleButton>
                                    </ToggleButtonGroup>

                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Device ID</Form.Label>
                                    <Form.Control type="text" id="shippingInput" placeholder="123456-123456" onChange={this.handleInputChange} />
                                </Form.Group>
                            </Form>
                            <LineBreak color={'#babfc9'} />
                            <Button variant="primary" id="shippingSubmitButton" onClick={this.sendModelAssignment} style={{marginTop: 15, width: '100%'}}>Submit All</Button>
                            <br />
                            <br />
                        </Col>
                        <Col xs={6} sm={8}>
                            <Row>
                                <Col sm={10}>
                                    <h3>{scannedDevices.length} devices scanned</h3>
                                </Col>
                                <Col sm={2}>
                                    <Button variant="link" onClick={this.resetTable} >Clear</Button>
                                </Col>
                            </Row>
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>Device ID</th>
                                        <th>Model</th>
                                        <th>Sent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.deviceRows}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            );
        }
        else {
            return(
                <LoadingView />
            );
        }
    }
}

export default withRouter(ModelAssignmentView);