import React from 'react';
import { withRouter } from 'react-router';

import './SetupCard.css';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';


const SetupCard = (props) => {


    const maxPage = props.maxPage;
    const page = props.page;

    const handleNext = () => {
        // props.updateInfo();
        props.onNext();
    };
 
    let dots = [];
    for (let i = 1; i <= maxPage; i++) {
        if (i === page) {
            dots.push(<span className="dot activeDot" key = {i}></span>);
        } else {
            dots.push(<span className="dot" key ={i}></span>);
        }
    }
    
    let buttons = [];
    if (page != 1) {
        buttons.push(<Button className="cardPrevButton" onClick={props.onPrev} key='prev'>Prev</Button>)
    }
    if (page === maxPage) {
        buttons.push(<Button className="cardNextButton" onClick={props.onNext} key='done'>Done</Button>)
    } else {
        buttons.push(<Button className="cardNextButton" onClick={props.onNext} key='next'>Next</Button>)
    }

    return (
        <Container className="setupCardBlock" >
            {props.content}
            <div className="cardButtonRow">
                    {buttons}
            </div>
            <div className="dotRow">
                {dots}
            </div>
        </Container>
    );
};

export default withRouter(SetupCard);