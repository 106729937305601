import React from 'react';
import Swal from 'sweetalert2';
import $ from 'jquery';
import axios from 'axios';


const archiveReportButton = (props) => {
    


    let id = props.id;
    let label = props.label

    return (

        <button value={id} className="btn-danger archiveButton" >
            {label}
        </button>

    );




};

export default archiveReportButton;