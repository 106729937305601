import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import config from '../../utilities/config.js';


class AddBuildingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            allOrgOptions: null,
            allPropOptions: null,
            stateOptions: null,
            selectedOrg: null,
            selectedProp: null,
            label: null,
            address: null,
            city: null,
            state: null,
            zip: null,
            lat: null,
            lon: null
        };
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'AddBuilding_view',
          })
        gtag('event','page_view')
        this.setStateOptions()
        this.getAllOrgs()
    }

    getAllOrgs() {
        //Get all Orgs
        let url = `${config.api}get-all-organizations`;
        let token = localStorage.getItem("mlToken");
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                //console.log(response)
                const data = response["data"];
                this.state.allOrgOptions = data.map((item) => {
                    return <option value={item.id}>{item.label}</option>;
                });
                this.setState({
                    loaded: true,
                });
            },
                (error) => {
                    //console.log(error);
                }
            );

    }

    getProps(OrgID) {
        //Get all Orgs
        //console.log(OrgID)
        let url = `${config.api}organization/${OrgID}/properties`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                //console.log(response)
                const data = response["data"];
                var propOptions=data.map((item) => {
                    return <option value={item.id}>{item.label}</option>;
                });
                this.setState({
                    allPropOptions: propOptions,
                });
            },
            
                (error) => {
                    //console.log(error);
                }
            );

    }

    setStateOptions() {
        const stateArray = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
            'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
            'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska',
            'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
            'Oregon', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
            'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']
        const stateOptions = stateArray.map((item) => {
            return (
                <option value={item}>{item}</option>
            );
        })
        this.setState({
            stateOptions: stateOptions
        });
    }

    handleOrgChange = (event) => {
        this.setState({
            selectedOrg: event.target.value
        });
        this.getProps(event.target.value)
        
    }
    handlePropChange = (event) => {
        this.setState({
            selectedProp: event.target.value
        });
    }

    handleInputChange = (event) => {
        //console.log(event.target.id)
        //console.log(event.target.value)
        switch (event.target.id) {
            case "addBuildLabel":
                this.setState({
                    label: event.target.value
                });
                break;
            case "addBuildAddress":
                this.setState({
                    address: event.target.value
                });
                break;
            case "addBuildCity":
                this.setState({
                    city: event.target.value
                });
                break;
            case "stateSelect":
                this.setState({
                    state: event.target.value
                });
                break;
            case "addBuildZip":
                this.setState({
                    zip: event.target.value
                });
                break;
            case "addBuildLat":
                this.setState({
                    lat: event.target.value
                });
                break;
            case "addBuildLon":
                this.setState({
                    lon: event.target.value
                });
                break;
            default:
                break;
        }
    }

    handleSubmit = (event) => {
        //console.log(this.state.selectedOrg)
        if (this.state.selectedOrg == 0 || this.state.selectedOrg == null) { 
            Swal.fire({
            icon: 'error',
            title: 'Need Organization'
        });return };
        if (this.state.selectedProp == 0 || this.state.selectedProp == null) { 
            Swal.fire({
            icon: 'error',
            title: 'Need Property'
        });return };
        if (this.state.label == "" || this.state.label == null) { 
            Swal.fire({
            icon: 'error',
            title: 'Need Label'
        });return };
        
        
        //Add Location
        let url = `${config.api}buildings`;
        let token = localStorage.getItem("mlToken");

        var postData = `[{"userID": "${config.userID}", "organizationID": "${this.state.selectedOrg}", "propertyID": "${this.state.selectedProp}", "label": "${this.state.label}", "address": "${this.state.address}", "city": "${this.state.city}", "state": "${this.state.state}", "zip": "${this.state.zip}", "lat": "${this.state.lat}", "lon": "${this.state.lon}"}]`;
        
        //console.log(postData)
        var postConfig = {
            method: 'post',
            url: url,
            headers: { 
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'text/plain'
            },
            data : postData
        };
          
        axios(postConfig)
            .then((response) => {
                //console.log(response);
                Swal.fire({
                    icon: 'success',
                    title: 'Done'
                })
            },
                (error) => {
                    //console.log(error);
                }
            );

    };

    render() {
        if (this.state.loaded) {
            Swal.close();
            return (
                <Container>
                    <h2>Add A Building</h2>
                    <br />
                    <Form>
                        <Form.Group>
                            <Form.Label>Select a Organization</Form.Label>
                            <Form.Control as="select" id="orgSelect" onChange={this.handleOrgChange} defaultValue={this.state.selectedOrg} >
                                <option value="0">Select a Organization</option>
                                {this.state.allOrgOptions}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Select a Property</Form.Label>
                            <Form.Control as="select" id="propSelect" onChange={this.handlePropChange} defaultValue={this.state.selectedProp} >
                                <option value="0">Select a Property</option>
                                {this.state.allPropOptions}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Building Label</Form.Label>
                            <Form.Control type="text" id="addBuildLabel" onChange={this.handleInputChange} placeholder="Hampton Inn" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" id="addBuildAddress" onChange={this.handleInputChange} placeholder="12 Smith Rd" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" id="addBuildCity" onChange={this.handleInputChange} placeholder="Albany" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>State</Form.Label>
                            <Form.Control as="select" id="stateSelect" onChange={this.handleInputChange} defaultValue={0} >
                                <option value="0">Select a State</option>
                                {this.state.stateOptions}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Zip</Form.Label>
                            <Form.Control type="text" id="addBuildZip" onChange={this.handleInputChange} placeholder="12205" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Latitude</Form.Label>
                            <Form.Control type="text" id="addBuildLat" onChange={this.handleInputChange} placeholder="42.7387485" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Longitude</Form.Label>
                            <Form.Control type="text" id="addBuildLon" onChange={this.handleInputChange} placeholder="-73.6715742" />
                        </Form.Group>
                        <Button variant="primary" id="addBuildSubmitButton" onClick={this.handleSubmit.bind(this)}>Submit</Button>
                    </Form>
                </Container>
            )
        }
        else {
            return (
                <LoadingView />
            )
        }
    }
}

export default withRouter(AddBuildingView);