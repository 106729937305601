import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Col, Row } from 'react-bootstrap';
import config from '../../utilities/config.js';

var autocompleteLocations = [];
var autocompleteDevices = [];
class ModifyLocationView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedloc: false,
            loadeddev: false,
            buildingID: null,
            userID: config.userID,
            id: null,
            locationLabel: null,
            deviceID: null,
            modifyForm: null
        };
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'ModifyLocation_view',
          })
        gtag('event','page_view')
        this.getUserLocations()
        this.getAllDevices()
    }

    clearform = () => {
        this.setState({
            modifyForm: null,
        })
    }
    getAllDevices() {
        //Get all Users
        let url = `${config.api}get-all-devices`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                //console.log(response)
                const data = response["data"];
                autocompleteDevices = data.map((item) => {
                    return item;
                });
                this.setState({
                    loadeddev: true
                });
                
            },
                (error) => {
                    //console.log(error);
                }
            );
    }

    getUserLocations() {
        //Get all Properties
        let url = `${config.api}users/${config.userID}/locations`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                //console.log(response)
                const data = response["data"];
                autocompleteLocations = data.map((item) => {
                    return item;
                });

                this.setState({
                    loadedloc: true
                });

            },
                (error) => {
                    //console.log(error);
                }
            );
    }

    createModifyForm(selected) {
        //console.log(selected)
        this.setState({
            id: selected.id,
            locationLabel: selected.locationLabel,
            deviceID: selected.deviceID,
            buildingID: selected.buildingID   
        });
        var modifyForm = (
            <Form>
                <Form.Group as={Row} controlId="modifyLocationName">
                    <Form.Label column sm={2}>Name:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="locationLabel" onChange={this.handleInputChange} defaultValue={selected.locationLabel} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyLocationDevice">
                    <Form.Label column sm={2}>Device:</Form.Label>
                    <Col sm={10}>
                        {/* <Form.Control type="text" id="deviceID" onChange={this.handleInputChange} defaultValue={selected.deviceID} /> */}
                        <ReactSearchAutocomplete
                            styling={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: '50%',
                                borderRadius: '5px',
                                zIndex: '999',
                            }}
                            items={autocompleteDevices}
                            resultStringKeyName="deviceID"
                            fuseOptions={{ keys: ["deviceID"] }}
                            onSearch={() => { }}//ignored
                            onHover={() => { }}//ignored
                            onSelect={(item) => {this.state.deviceID=item.id}}
                            onFocus={() => { }}//ignored
                            maxResults={6}
                            showItemsOnFocus={true}
                            showNoResultsText="No Devices found"
                            formatResult={(item) => {
                                return (
                                    <span>{item.deviceID}</span>
                                )
                            }}
                        />
                    </Col>
                </Form.Group>
                <Button variant="primary" onClick={this.handleSubmit} >Update Location</Button>
                <Button variant="danger" onClick={this.handleArchive} >Archive Location</Button>
                <Button variant="primary" onClick={this.clearform} >Clear</Button>
            </Form>
        )
        this.setState({
            modifyForm: modifyForm
        })
    }

    handleInputChange = (event) => {
        switch (event.target.id) {
            case "locationLabel":
                this.setState({
                    locationLabel: event.target.value
                });
                break;
        }
    }

    handleSubmit = () => {
        //console.log(this.state.deviceID)
        let data = `[{
                "id": ${this.state.id}, 
                "buildingID": ${this.state.buildingID}, 
                "userID": ${this.state.userID}, 
                "locationLabel": "${this.state.locationLabel}",
                "deviceID": "${this.state.deviceID}"
            }]`
        axios({
            method: 'put',
            url: `${config.api}locations`,
            headers: {
                'Authorization': `Bearer ${config.token}`,
                'Content-Type': 'text/plain'
            },
            data: data
        })
            .then((response) => {
                const data = response['data'];
                Swal.fire({
                    icon: 'success',
                    title: 'Success 👍🏼'
                });
            })
            .catch((error) => {
                //console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Whoops 😰',
                    text: error
                });
            });

    }

    handleArchive = () => {
        let data = `[{"id": ${this.state.id}}]`
        var postConfig = {
            method: 'delete',
            url: `${config.api}locations`,
            headers: { 
                'Authorization': `Bearer ${config.token}`,
                'Content-Type': 'text/plain'
            },
            data : data
        };
        //console.log(postConfig)
        axios(postConfig).then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success 👍🏼'
                });
            })
            .catch((error) => {
                //console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Whoops 😰',
                    text: error
                });
            });

    }

    render() {
        if (this.state.loadedloc && this.state.loadeddev) {
            Swal.close();
            return (
                <Container>
                    <h2>Modify A Location</h2>
                    <Col style={{ marginTop: 12, padding: 12, marginBottom: 30 }}>
                        <ReactSearchAutocomplete
                            styling={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: '50%',
                                zIndex: '999',
                            }}
                            items={autocompleteLocations}
                            resultStringKeyName="locationLabel"
                            fuseOptions={{ keys: ["locationLabel"] }}
                            onSearch={() => { }}//ignored
                            onHover={() => { }}//ignored
                            onSelect={(item) => { this.createModifyForm(item) }}
                            onFocus={() => { }}//ignored
                            onClear={this.clearform}
                            maxResults={6}
                            showItemsOnFocus={true}
                            showNoResultsText="No locations found"
                            formatResult={(item) => {
                                return (
                                    <span>{item.locationLabel}</span>
                                )
                            }}
                        />
                    </Col>
                    {this.state.modifyForm}
                </Container>
            )
        }
        else {
            return (
                <LoadingView />
            )
        }
    }
}

export default withRouter(ModifyLocationView);