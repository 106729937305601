import React from 'react';
import Table from '../../Table/Table/Table';
import {numberWithCommas} from '../../UniversalFunctions/UniversalFunctions';


const summaryTable = (props) => {
    //{"flushes":4640,"gallonsUsed":7623.549400000002,"kwHR":35.79148722546981,"lbCarbon":2.44974910050781,"leaksFound":0,
    //"leakCost":24.914541130000003,"gallonsWasted":3509.0903000000003,"kwHRL":16.47468312404061,"lbCarbonL":20.13206277757763}
    //Flushes	Gallons Used	kW-Hr Used	lb Carbon Release	Leaks Found	Leak Cost	Gallons Wasted	kW-Hr Leaks	lb Carbon Leaks
    let orderedKeys = props.orderedKeys;
    let data = props.data;
    let rows = []
    let tFlushes = 0;
    let tGallons = 0;
    let tKwHR = 0;
    let tLbCarbon = 0;
    let tLeaksFound = 0;
    let tLeakCost = 0;
    let tGallonsWasted = 0;
    let tKwHRL = 0;
    let tLbCarbonL = 0;





    for(let i =0 ; i < Object.keys(data).length ; i++){
        let date = orderedKeys[i];
        let flushes = data[date]["flushes"];
        //console.log("GALLONS")
        //console.log(data[date]["gallonsUsed"]);
        let gallons = parseInt(data[date]["gallonsUsed"]);
        let kwHR = parseFloat(data[date]["kwh"]).toFixed(2);
        let lbCarbon = parseFloat(data[date]["lbCarbon"]).toFixed(2);
        let leaksFound = data[date]["leaksFound"];
        let leakCost = Number(parseFloat(data[date]["leakCost"]).toFixed(2));
        let gallonsWasted = parseInt(data[date]["gallonsWasted"]);
        let kwHRL = parseFloat(data[date]["kwhL"]).toFixed(2);
        let lbCarbonL = parseFloat(data[date]["lbCarbonL"]).toFixed(2);

        tFlushes += flushes;
        tGallons += gallons;

        tKwHR += Number(kwHR);
        tLbCarbon += Number(lbCarbon);
        tLeaksFound += leaksFound;
        tLeakCost += leakCost;
        tGallonsWasted += gallonsWasted;
        tKwHRL += Number(kwHRL);
        tLbCarbonL += Number(lbCarbonL);
        let row = [date , numberWithCommas(flushes) , numberWithCommas(gallons) , kwHR , lbCarbon , leaksFound , "$" + numberWithCommas(leakCost) , numberWithCommas(gallonsWasted) , kwHRL , lbCarbonL];
        rows.push(row);
    }

    let fRow = ["Totals" , numberWithCommas(tFlushes) , numberWithCommas(tGallons) , parseFloat(tKwHR).toFixed(2) , parseFloat(tLbCarbon).toFixed(2) , 
    tLeaksFound , "$" + numberWithCommas(parseFloat(tLeakCost).toFixed(2)) , numberWithCommas(tGallonsWasted) , parseFloat(tKwHRL).toFixed(2) , parseFloat(tLbCarbonL).toFixed(2)];
    rows.push(fRow);

  

    let headers = ["Date" , "Flushes" , "Gallons Used" , "kW-Hr Used" , "lb Carbon Released" , "Leaks Found" , "Leak Cost" , "Gallons Wasted" , "kW-Hr Leaks" , "lb Carbon Leaks"];
    let tableID = "gallon-usage";
    let styles = {width:"100%" , marginBottom: "50px"};


    return (
        <div className="container">
            <Table id={tableID} rows={rows} headers={headers} styles={styles} />
        </div>
    );

    
};



export default summaryTable;