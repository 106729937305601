import React from "react";

const LineBreak = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1,
            width: '90%',
            boxShadow: null,
            borderWidth: 0.5,
            borderRadius: 25,
            borderColor: color,
        }}
    />
);

export default LineBreak;