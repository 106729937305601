import React, { Component } from 'react';
import './Menu.css';

function endMasquerade(e){
    e.preventDefault();
    localStorage.setItem("mlUserID" , localStorage.getItem("mlOriginalUser"));
    localStorage.setItem("mlIsMasquerading" , false);
    window.location.reload();
}

/* Toggle between adding and removing the "responsive" class to topnav when the user clicks on the icon */
function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
}

function getClassNames(menuItem){

}


const menu = (props) => {

    //Home
    //Profile
    //Masquerade
    //End Masquerade
    //Users
    //Detailed Raw
    //RSSI Signal
    //Basic Reports
    //Tools
    //Custom Reports
    //Logout

    let home = (

            <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
    );

    let repairs = (
      <a className="nav-link" href="/repairs">Repairs
          <span className="sr-only">Tools</span>
      </a>
    );

    let profile = (
            <a className="nav-link" href="/profile">Profile<span className="sr-only">Profile</span></a>
    );

    let masquerade = (
            <a className="nav-link" href="/masquerade" >Masquerade
                <span className="sr-only">Masquerade</span>
            </a>
    );



    let detailedRaw = (
            <a className="nav-link" href="/raw/detailed-rawphp"> Detailed Raw
                <span className="sr-only">Detailed Raw
                </span>
            </a>
    );

    let rssi = (
            <a className="nav-link" href="/signal/signal-strength-testphp"> RSSI Stats
                <span className="sr-only">RSSI Stats</span>
            </a>
    )
    

    let tools = (
            <a className="nav-link" href="/tools">Tools
                <span className="sr-only">Tools</span>
            </a>
    );

    let cReports = (
            <a className="nav-link" href="/custom-report">Custom Report
                <span className="sr-only">Custom Report</span>
            </a>
    );

    let logout = (
            <a className="nav-link" href="/login">Logout 
                <span className="sr-only">Logout</span>
            </a>
    );

    let masquerading = localStorage.getItem("mlIsMasquerading");
    let isAdmin = localStorage.getItem("mlIsAdmin");
    let isMasqueradeAdmin = false;
    


    if(masquerading == "true"){
        masquerading = true;
    }
    else{
        masquerading = false;
    }


    //console.log("IS ADMIN " + isAdmin);

    //you are an admin, but you are masquerading as a regular user
    if(isAdmin == "0"){
        //set all here to null
        detailedRaw = null;
        masquerade = null;
        tools = null;
        rssi = null;        

    }
    //you are an admin masquerading as a regular user
    if(isAdmin && !isMasqueradeAdmin && masquerading){
        detailedRaw = null;
        masquerade = (
            <a className="nav-link" href="#" onClick={e => endMasquerade(e)}>
                End Masquerade<span className="sr-only">End Masquerade</span>
            </a>
        );
        tools = null;
        rssi = null;
    }
     //you are an admin masquerading as another admin
     else if(isAdmin && isMasqueradeAdmin && masquerading){
        masquerade = (
            <a className="nav-link" href="#" onClick={e => endMasquerade(e)}>
                End Masquerade<span className="sr-only">End Masquerade</span>
            </a>
        );
    }




    return (
        <div>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />        
        <div className="topnav" id="myTopnav">
            {home}
            {repairs}
            {masquerade}
            
            {tools}
            {cReports}
            {logout} 
            <a href="javascript:void(0);" className="icon" onClick={myFunction}><i className="fa fa-bars"></i></a>
            </div>
        </div>
    );


}


export default menu;

/*

 <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
</head>
<body>

<nav class="navbar navbar-inverse">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>                        
      </button>
      <a class="navbar-brand" href="#">WebSiteName</a>
    </div>
    <div class="collapse navbar-collapse" id="myNavbar">
      <ul class="nav navbar-nav">
        <li class="active"><a href="#">Home</a></li>
        <li class="dropdown">
          <a class="dropdown-toggle" data-toggle="dropdown" href="#">Page 1 <span class="caret"></span></a>
          <ul class="dropdown-menu">
            <li><a href="#">Page 1-1</a></li>
            <li><a href="#">Page 1-2</a></li>
            <li><a href="#">Page 1-3</a></li>
          </ul>
        </li>
        <li><a href="#">Page 2</a></li>
        <li><a href="#">Page 3</a></li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li><a href="#"><span class="glyphicon glyphicon-user"></span> Sign Up</a></li>
        <li><a href="#"><span class="glyphicon glyphicon-log-in"></span> Login</a></li>
      </ul>
    </div>
  </div>
</nav> <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
</head>
<body>

<nav class="navbar navbar-inverse">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>                        
      </button>
      <a class="navbar-brand" href="#">WebSiteName</a>
    </div>
    <div class="collapse navbar-collapse" id="myNavbar">
      <ul class="nav navbar-nav">
        <li class="active"><a href="#">Home</a></li>
        <li class="dropdown">
          <a class="dropdown-toggle" data-toggle="dropdown" href="#">Page 1 <span class="caret"></span></a>
          <ul class="dropdown-menu">
            <li><a href="#">Page 1-1</a></li>
            <li><a href="#">Page 1-2</a></li>
            <li><a href="#">Page 1-3</a></li>
          </ul>
        </li>
        <li><a href="#">Page 2</a></li>
        <li><a href="#">Page 3</a></li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li><a href="#"><span class="glyphicon glyphicon-user"></span> Sign Up</a></li>
        <li><a href="#"><span class="glyphicon glyphicon-log-in"></span> Login</a></li>
      </ul>
    </div>
  </div>


*/