import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import './ProfileView.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import LoadingView from '../LoadingView/LoadingView';
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import config from '../../utilities/config';
import ChangePassword from './ChangePassword';
import AlertSettings from './AlertSettings';
import OrganizationSettings from './OrganizationSettings';
import PropertySettings from './PropertySettings';
import OrderHistory from './OrderHistoryView';
import DemoToolsView from './DemoToolsView';
import Reports from './ReportsView';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';


 

class ProfileView extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            selected: "change-password"
        };
    }


    componentDidMount(){
        gtag('config', config.gtag, {
            page_title: 'Profile_view',
          })
        gtag('event','page_view')
        if (this.state.windowWidth <= 650) {
            document.body.style.backgroundColor = "#fefefe";
        }
        else {
            document.body.style.backgroundColor = "#EBF2F8";
        }
        window.addEventListener('resize', () => this.setState({windowWidth: window.innerWidth}));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.setState({windowWidth: window.innerWidth}));
    }

    componentDidUpdate() {
        if (this.state.windowWidth <= 650) {
            document.body.style.backgroundColor = "#fefefe";
        }
        else {
            document.body.style.backgroundColor = "#EBF2F8";
        }
    }

    // handleSelect = (event) => {
    //     const selected = event.currentTarget.getAttribute("content-key");
    //     //console.log(selected);
    //     this.setState({
    //         selected: selected
    //     });
    // }

    render() {
        // var settingsContent = (<div></div>);
        // let passClass = "settings-button";
        // let alertClass = "settings-button";
        // let orgClass = "settings-button";
        // let propClass = "settings-button";

        // switch(this.state.selected) {
        //     case "change-password":
        //         passClass = "settings-button-selected";
        //         settingsContent = (<ChangePassword />);
        //         break;
        //     case "alert-settings":
        //         alertClass = "settings-button-selected";
        //         break;
        //     case "organization":
        //         orgClass = "settings-button-selected";
        //         break;
        //     case "properties":
        //         propClass = "settings-button-selected";
        //         break;
        //     default:
        //         break;
        // }

         return (

            <div id="pageOuterContainer" className="pageOuterContainer">

                {this.state.windowWidth > 650 &&
                    <Sidebar 
                        pageWrapId={"pageWrap"} 
                        outerContainerId={"pageOuterContainer"} 
                        generateSummary={null}
                        buildings={null} 
                        buttonsVisible={false}
                    />
                }

                <div id="pageWrap" className="pageWrap">

                    {this.state.windowWidth <= 650 &&
                        <Sidebar 
                            pageWrapId={"pageWrap"} 
                            outerContainerId={"pageOuterContainer"} 
                            generateSummary={null}
                            buildings={null} 
                            buttonsVisible={false}
                        />
                    }

                    <div className="profileViewRow">
                        <Col lg={12}  className="settingsCol">
                            <div className="settingsBlock">
                                <Tabs defaultActiveKey="change-password" id="settings-tabs" style={{"width": "100%"}}>
                                    <Tab title="" disabled></Tab>
                                    <Tab eventKey="change-password" title="Account">
                                        <ChangePassword />
                                    </Tab>
                                    <Tab eventKey="reports" title="Reports">
                                        <Reports />
                                    </Tab>
                                    <Tab eventKey="alerts" title="Alerts">
                                        <AlertSettings />
                                    </Tab>
                                    <Tab eventKey="organization" title="Organization">
                                        <OrganizationSettings />
                                    </Tab>
                                    <Tab eventKey="properties" title="Properties">
                                        <PropertySettings />
                                    </Tab>
                                    <Tab eventKey="order-history" title="Orders">
                                        <OrderHistory />
                                    </Tab>
                                    {/* {config.userID == 89
                                    ?
                                        <Tab eventKey="demo-tools" title="Demo Tools">
                                            <DemoToolsView />
                                        </Tab>
                                    :
                                        null
                                    } */}
                                </Tabs>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
         )   
    }
}

export default withRouter(ProfileView);