import React, { useEffect, useRef } from 'react';

const ChartComponent = (props) => {
  const plot = {
    rooms: props.rooms,
    data: props.data,
    mode: props.mode,
  };

  // //console.log(plot);
  // //console.log(plot.mode);

  const canvasRef = useRef(null);

  //= map a pressure value to a heatmap color using a logit function
  const pressureToHue = (val) => {
    if (val > 72.0) return 0;
    if (val == 0) return 128;
    return Math.round(
      242 - (121 * (Math.log(val / (80 - (val + 5))) / Math.log(32)) + 121)
    );
  };

  const leaksToHue = (val) => {
    if (val[0] === 0) return 128;
    if (val[0] < 5) {
      if (val[1] < 100) return 64;
      if (val[1] < 250) return 24;
    }
    if (val[0] < 10 && val[1] < 250) return 24;
    return 0;
  };

  // draw all of the rectangles for each room and label
  const draw = (canvas) => {
    const ctx = canvas.getContext('2d');

    const mWidth = canvas.width;
    const mHeight = canvas.height;

    // clear the canvas
    ctx.clearRect(0, 0, mWidth, mHeight);

    // loop through all of the rooms of the floor
    if (plot.rooms.length !== 0) {
      plot.rooms.forEach((room) => {
        // get coordinates of the rectangle
        const label = room[0];
        const x = room[1] * mWidth;
        const y = (1.0 - room[2] - room[4]) * mHeight;
        const width = room[3] * mWidth;
        const height = room[4] * mHeight;
        let hue = 0;

        // if no pressure found gray the room
        if (plot.data == null) {
          ctx.fillStyle = `hsl(128, 50%, 50%)`;
        } else if (plot.data[label] == null) {
          ctx.fillStyle = `hsl(128, 50%, 50%)`;
        } else if (plot.mode === 'pressure') {
          hue = pressureToHue(plot.data[label]);
          ctx.fillStyle = `hsl(${hue}, 50%, 50%)`;
        } else if (plot.mode === 'leaks') {
          hue = leaksToHue(plot.data[label]);
          ctx.fillStyle = `hsl(${hue}, 50%, 50%)`;
        }

        ctx.fillRect(x, y, width, height);

        // room border
        ctx.lineWidth = '4';
        ctx.beginPath();
        ctx.rect(x, y, width, height);
        ctx.stroke();
        // const txt_width = ctx.measureText(label.toString()).width

        // text labeling coordinates
        ctx.font = '16px trebuchet';
        const txtHeight = ctx.measureText('M').width;
        ctx.textAlign = 'center';
        ctx.fillStyle = '#000000';
        ctx.fillText(label, x + width / 2, y + height / 4);

        ctx.font = '12px trebuchet';

        if (plot.mode === 'pressure') {
          if (plot.data) {
            if (plot.data[label])
              ctx.fillText(
                `${Math.round(plot.data[label])} psi`,
                x + width / 2,
                y + height / 4 + 2 * txtHeight
              );
          }
        }

        if (plot.mode === 'leaks') {
          if (plot.data && plot.data[label]) {
            ctx.fillText(
              `${Math.round(plot.data[label][0])} leaks`,
              x + width / 2,
              y + height / 4 + 2 * txtHeight
            );
            ctx.fillText(
              `${Math.round(plot.data[label][1])} Gal`,
              x + width / 2,
              y + height / 4 + 3 * txtHeight
            );
          }
        }

        ctx.fillStyle = '#ffffff';
      });


    } else {
      ctx.lineWidth = '4';
      ctx.beginPath();
      ctx.rect(0, 0, mWidth, mHeight);
      ctx.stroke();

      ctx.font = '24px trebuchet';
      const txtHeight = ctx.measureText('M').width;
      ctx.textAlign = 'center';
      ctx.fillStyle = '#000000';
      ctx.fillText("No Room Layouts On File", mWidth / 2, mHeight / 4);


    }

  };

  // retrieve canvas ref
  useEffect(() => {
    const canvas = canvasRef.current;
    draw(canvas);
  });

  return (
    <div className="Canvas">
      <canvas id="chart" width="800" height="400" ref={canvasRef} />
    </div>
  );
};

export default ChartComponent;
