import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import './ReportView.scss';
import Container from 'react-bootstrap/Container';
import Sidebar from '../../components/Sidebar/Sidebar';
import ReportTable from '../../components/Table/ReportTableComponents/ReportTable'
import LoadingView from '../LoadingView/LoadingView';
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import tablesorter from 'tablesorter';
import PageNotFoundView from '../PageNotFoundView/PageNotFoundView';
import Row from 'react-bootstrap/Row';
 

class ReportView extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            data: null
        }
    }

    componentDidMount(){
        gtag('config', config.gtag, {
            page_title: 'Report_view',
          })
        gtag('event','page_view')
        document.body.style.backgroundColor = "#EBF2F8";
        this.getReportInformation()
    }

    componentDidUpdate(){
        $('table').tablesorter();
    }

    isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    getReportInformation(){
            let curURL = window.location.href;
            let parts = curURL.split("/")
            let reportID = parts[parts.length-1];
            let url = `${config.api}advanced-building-functions/report/${reportID}`
            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${config.token}` }
                }
              )
    
              .then((response) => {
                //get your information
                this.setState({
                    data: response["data"]
                });
                },
                (error) => {
                    //console.log(error);
                    this.setState({
                        data: {}
                    });
                }
              );
            
    }

    render() {
        
        let data = this.state.data;
        if(data == null){
            return <LoadingView />;
        }
        else if(this.isEmpty(data)){
            Swal.close();
            return <PageNotFoundView />
        }
        else{
            Swal.close();
            return (
                <div id="reportOuterContainer" className="reportOuterContainer" >

                    <Sidebar 
                    pageWrapId={"reportPageWrap"} 
                    outerContainerId={"reportOuterContainer"} 
                    generateSummary={null}
                    buildings={null} 
                    buttonsVisible={false}
                    />

                    <Container id="reportPageWrap" className="reportPageWrap" >
                        <Row className="reportViewRow">
                            <ReportTable data={data} />
                        </Row>
                    </Container>
                </div>
            );
        }

    }
}

export default withRouter(ReportView);