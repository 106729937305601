import React, { Component } from 'react';
import axios from 'axios';
import config from '../../utilities/config';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

class OrderHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableRows: null
        };
    }

    componentDidMount() {
        this.getOrders();
    }

    getOrders() {
        var getConfig = {
            method: 'get',
            url: `${config.api}users/${config.userID}/documents`,
            headers: { 
              'Authorization': `Bearer ${config.token}`
            }
        };
        
        axios(getConfig)
        .then((response) => {
            let data = response.data;
            //console.log("DATA RESPONSE")
            //console.log(data)
            this.createTableRows(data);
        })
        .catch((error) => {
            //console.log(error);
        });
    }

    createTableRows = (data) => {
        let userOrders = Array();
        data.forEach((orderData) => {
            if (orderData.squareSpaceData != null) {
                userOrders.push(orderData);
            }
        });
        //console.log(data);
        //console.log(userOrders);

        const tableRows = userOrders.map((order) => {
            //console.log(order);
            let customData = JSON.parse(order.customData);
            //console.log(customData);
            return (
                <tr key={Math.random()}>
                    <td>{customData.date}</td>
                    <td>{customData.quantity}</td>
                    <td>{customData.total}</td>
                    <td><Button variant='link' href={order.termsOfSales}>View</Button></td>
                    <td><Button variant='link' href={order.purchaseAgreement}>View</Button></td>
                </tr>
            );
        });

        this.setState({
            tableRows: tableRows
        });
    }
    
    render() {
        return (
            <Container style={{'height': '100%'}}>
                <Row style={{'paddingTop': '50px'}}>
                    <Col>
                        <h2 style={{'marginLeft': '0px'}}>Order History</h2>
                    </Col>
                </Row>
                <Row>
                    <Table striped responsive bordered >
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Quantity</th>
                                <th>Total</th>
                                <th>Terms of Sale</th>
                                <th>Purchase Agreement</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tableRows}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        );
    }
}

export default OrderHistory;