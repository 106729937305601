import React from 'react';
import './AlertsBlock.scss';
import './GeneralBlocks.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';

const AlertsBlock = (props) => {

    let totalAlerts = props.alerts
    let alertTextColor = '#f04451';
    if (totalAlerts == 0) {
        alertTextColor = '#4cc0ac'
    }
    totalAlerts = totalAlerts.toLocaleString()

    const handleClick = () => {
        const curPath = window.location.pathname;
        if (curPath.includes('room-view')){
            props.showAlertHistory();
        }
        else {
            const history = props.history;
            history.push("/alerts");
        }
    }

    return (
        <Container style={{ cursor: 'pointer'}} onClick={handleClick}>
            <Row style={{'height': '25%'}} >
                <Col xs={12}>
                    <p className="blocksLabel">Alerts</p>
                </Col>
            </Row>
            <Row style={{'height': '75%'}} >
                <p className="totalAlerts" style={{"color": `${alertTextColor}`}} >{totalAlerts}</p>
            </Row>
        </Container>
    );
};

export default AlertsBlock;