import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import Container from 'react-bootstrap/Container';
import Sidebar from '../../components/Sidebar/Sidebar';
import LoadingView from '../LoadingView/LoadingView';
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import AllMapTable from '../../components/Table/AllMapTable/AllMapTable';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';



class MapView extends Component {

    
    
    constructor(props){
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            loaded: false,
            loaded2:false,
            sDate: null,
            eDate: null,
            maps: [],
            reportType:1,
            buildingID:0


        }
    }




    componentDidMount(){
        //console.log("COMPONENT DID MOUNT");
        gtag('config', config.gtag, {
            page_title: 'Map_view',
          })
        gtag('event','page_view')
        document.body.style.backgroundColor = "#EBF2F8";
        this.timer = setInterval(()=> this.getHeatMapInformation(), 10000)
        this.getHeatMapInformation()
        this.getUserBuildings()

    }

    componentDidUpdate(){
        $('table').tablesorter();

    }




    async getHeatMapInformation() {
       
        //console.log("EXECUTING HEAT MAP INFO");
        let url = `${config.api}get-maps/${config.userID}`;
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
          )
          .then((response) => {
                //console.log(response);
                let data = response.data;
                Swal.close();
                
                this.setState({
                    loaded:true,
                    maps:data
                })

                this.forceUpdate()

            }
          )

        


    }


    handleSubmit = () => {
        //console.log("SUBMITTED");
        const sDate = this.state.sDate;
        const eDate = this.state.eDate;
        const buildingID = this.state.buildingID;
        const reportType = this.state.reportType;
        let errL = false;
        let errM = "";


        if(sDate == null || typeof sDate == "undefined" || eDate == null || typeof eDate == "undefined"){
            errM = "Start or End Date is not defined";
            errL = true;
            
        }

        else if(buildingID == null || typeof buildingID == "undefined" || buildingID == 0){
            errM = "Building ID not defined";
            errL = true;
            
        }
        else if(reportType == null || typeof reportType == "undefined"){
            errM = "Report type is undefined";
            errL = true;
            

        }


        if(errL){
            Swal.fire({
                title:errM,
                icon: 'error'
            });
            return;
        }

        //console.log("VALUES ARE EXPECTED");
        //console.log(sDate)

        const sTime = sDate.getTime();
        const eTime = eDate.getTime();

        //console.log(sTime)
        //console.log(eTime);



        //console.log(sDate);
        //console.log(eDate);
        //console.log(buildingID);
        //console.log(reportType);
        Swal.showLoading();

       let  url = `${config.api}generate-maps`
        //get all the buildings for user. If user has only one building, get that building (building), 
        //otherwise, get all building information in the summary.
        let data = `[{"sTime": "${sTime}", "eTime": "${eTime}" , "buildingID":${buildingID}, "mapType":${reportType}}]` 

        axios({
            method: 'post',
            url: url,
            headers: {
               'Authorization': `Bearer ${config.token}`, 
               'Content-Type': 'text/plain'
            },
            data: data
         })
         .then((response) => {
             //console.log("================================RESPONSE================================")
             //console.log(response)
             window.location.reload();

            
         })
         .catch((e) => {
             //console.log(e);
             Swal.close();
         })


    }

    handleDateChangeS = (date) =>{
        //console.log(date)
        this.setState({
            sDate: date
          });
    }

    handleDateChangeE = (date) =>{
        this.setState({
            eDate: date
          });
    }

    handleInputChange = (buildingID) => {
        this.setState({
            buildingID: buildingID
        })
    }

    getUserBuildings() {

        let url = `${config.api}advanced-building-functions/user/${config.userID}`;
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
            .then((response) => {
                //console.log("EXECUTING BUILDING INFO")
                let buildings = response.data;
                if (buildings.length != 0) {
                    this.setState({
                        buildings: buildings,
                        loaded2:true
                    })



                }
                else {
                    Swal.close();
                    Swal.fire({
                        title: "It Looks Like You Aren't Assigned Any Buildings.",
                        icon: 'error'
                    });
                }
            });
    }

    render() {

        if(!this.state.loaded || !this.state.loaded2){
           
            return (<LoadingView />)
        }


        let b = this.state.buildings;
        let buildingRows = []

        //console.log(b.length)

        for (let i = 0; i < b.length; i++) {
            buildingRows.push(<option value={JSON.stringify(b[i].id)}>{b[i].label}</option>);
        }



        let rows = this.state.maps;
        //console.log("ROWS")
        //console.log(rows)
        return (
            <div id="pageOuterContainer" className="pageOuterContainer" >
            {this.state.windowWidth > 650 &&
                <Sidebar 
                pageWrapId={"pageWrap"}
                outerContainerId={"pageOuterContainer"}
                generateSummary={this.getUserBuildings.bind(this)}
                buildings={this.state.buildings}
                buttonsVisible={false}
                handleExport={this.exportData}

                />
            }


            {this.state.windowWidth <= 650 &&
                    <Sidebar 
                    pageWrapId={"pageWrap"}
                    outerContainerId={"pageOuterContainer"}
                    generateSummary={this.getUserBuildings.bind(this)}
                    buildings={this.state.buildings}
                    buttonsVisible={true}
                    handleExport={this.exportData}
                    />
                }  
                

            <Container id="pageWrap" className="pageWrap" >

                <Form>
                    <Form.Group>
                        <Form.Label><b>Report Type</b></Form.Label>
                        <Form.Control   as="select" 
                                        id="reportType" 
                                        defaultValue={this.state.reportType} 
                                        value={this.state.reportType}
                                        onChange={e => this.setState({ reportType: e.target.value })}
                        >
                            <option value="1">Heat Map</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><b>Building</b></Form.Label>
                        <Form.Control   as="select" 
                                        id="buildingSelect" 
                                        onChange={e => this.setState({ buildingID: e.target.value })}
                                        value={this.state.buildingID}
                                        defaultValue={this.state.buildingID} 
                        >
                            <option value="0">Choose a Building</option>
                            {buildingRows}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><b>Start Date&nbsp;</b></Form.Label>
                        <DatePicker 
                                                id="sDate"
                                                onChange={this.handleDateChangeS}
                                                selected={this.state.sDate}
                                                
                                                format="MMMM d, yyyy"
                                                timeIntervals={15}
                                                timeCaption="Date"
                                                dateFormat="MMMM d, yyyy"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><b>End Date&nbsp;&nbsp;&nbsp;</b></Form.Label>
                        <DatePicker 
                                                id="eDate"
                                                onChange={this.handleDateChangeE}
                                                selected={this.state.eDate}
                                                
                                                timeFormat="MMMM d, yyyy"
                                                timeIntervals={15}
                                                timeCaption="Date"
                                                dateFormat="MMMM d, yyyy"
                        />
                    </Form.Group>
                    <Button variant="primary" id="generateReport" onClick={this.handleSubmit}>Generate Report</Button>    
                </Form>
                <br />

                <AllMapTable rows={rows} />      
            </Container>
            </div>
        );

    }
}

export default withRouter(MapView);