import React from 'react';
import './DataBlock.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import { Col } from 'react-bootstrap';

const DataBlock = (props) => {

    return (
        <Container>
            <Row style={{'height': '25%'}}>
                <Col xs={12}>
                    <p className="blocksLabel">{props.label}</p>
                </Col>
            </Row>
            <Row style={{'height': '75%'}}>
                <p className="block">{props.data}</p>
            </Row>
        </Container>
    );
};

export default DataBlock;