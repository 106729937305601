import React from "react";
import { withRouter } from 'react-router-dom';
// import './VideoWalkthrough.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import TopicCard from './TopicCard';

const Topics = (props) => {

    const portalOverview = [
        "Portal Overview", 
        "Learn about your portal setup to fully utilize all its capabilities.",
        "https://flowactive-public-documents.s3.amazonaws.com/Help/Portal+Guide_Tour+v3.pdf"
    ]
    // const printPDF = [
    //     "How to Print a PDF Portal Report", 
    //     "Learn how to print a PDF report directly from the portal within your web browser.", 
    //     ""
    // ]
    const aboutAlerts = [
        "About Alerts", 
        "Learn how to read, understand, and customize alerts sent to you from your FirstDrops devices.", 
        "https://flowactive-public-documents.s3.amazonaws.com/Help/Portal+Guide_Alerts+v3.pdf"
    ]
    const aboutLocation = [
        "About Location", 
        "Learn how the Location section of your portal can allow you to pinpoint areas of concern in your properties.", 
        "https://flowactive-public-documents.s3.amazonaws.com/Help/Portal+Guide_Locations+v3.pdf"
    ]
    const aboutDevices = [
        "About Devices", 
        "Learn about the activity of your FirstDrops devices.", 
        "https://flowactive-public-documents.s3.amazonaws.com/Help/Portal+Guide_Devices+v3.pdf"
    ]
    const aboutRepairs = [
        "About Repairs", 
        "Learn how to document and review problems or repairs to your toilet fixtures.", 
        "https://flowactive-public-documents.s3.amazonaws.com/Help/Portal+Guide_Repairs+v3.pdf"
    ]
    // const myAccount = ["My Account", "", ""]
    const portalUserGuide = [
        "Portal Guide", 
        "View the full user guide for the FlowActive portal.", 
        "https://flowactive-public-documents.s3.amazonaws.com/Help/Portal+User+Guide+v4.pdf"
    ]
    const fullInstallationGuide = [
        "Installation Guide", 
        "View the full installation guide for FlowActive devices.", 
        "https://flowactive-public-documents.s3.amazonaws.com/Help/FullUserGuide_v4_0827.pdf"
    ]
    const appUserGuide = [
        "Mobile App Guide", 
        "View the full user guide for the FlowActive mobile app.", 
        "https://flowactive-public-documents.s3.amazonaws.com/Help/App+User+Guide+v3.pdf"
    ]
    const deviceWiFi = [
        "WiFi Troubleshooting", 
        "Troubleshoot weak WiFi signal and connection problems.", 
        "https://flowactive-public-documents.s3.amazonaws.com/Help/Device+Connectivity+Guide+v3.pdf"
    ]
    const wifiRequirements = [
        "Commercial WiFi Requirements", 
        "View the WiFi network requirements for FlowActive devices.", 
        "https://flowactive-public-documents.s3.amazonaws.com/Help/Wireless+Connectivity+Requirements+v2.pdf"
    ]
    


    return( 
    <Container>
        <Row>
            <TopicCard text={portalOverview} />
            {/* <TopicCard text={printPDF} /> */}
            <TopicCard text={aboutAlerts} />
            <TopicCard text={aboutLocation} />
            <TopicCard text={aboutDevices} />
            <TopicCard text={aboutRepairs} />
            <TopicCard text={portalUserGuide} />
            <TopicCard text={fullInstallationGuide} />
            <TopicCard text={appUserGuide} />
            <TopicCard text={deviceWiFi} />
            <TopicCard text={wifiRequirements} />
        </Row>
    </Container>
    )

};

export default Topics;