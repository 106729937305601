import React from 'react';
import CTableRow from '../CTableRow/CTableRow';
import $ from 'jquery';




const table = (props) => {

    let headers = props.headers;
    let rows = props.rows;
    let tableID = props.id;
    let styles = props.styles;
    let attributes = props.attributes;
    let colors = props.colors;

    
    let ths = headers.map((header,index) => {
        return (
            <th key={tableID + "-th-" + index}>{header} <img width="22" height="17" src="https://mobius-labs-public-imgs.s3.amazonaws.com/Sort_both.svg.png" /></th>
        );
    })

    return (
        <table id={tableID} style={styles} className="table tablesorter" align="center" >
            
                <thead className={"header-"+tableID}>
                    <tr>
                    {ths}
                    </tr>
                </thead>
            
            <tbody>
                {rows.map((row,index) => {
                    let rAttributes = null;
                    let color = null;

                    if(attributes != undefined && attributes != null){
                        rAttributes = attributes[index];
                    }

                    if(colors != undefined && colors != null){
                        color = colors[index];
                    }

                    return (
                        <CTableRow key={tableID + "-cTableRow-" + index}  tableID={tableID} row={row} attributes={rAttributes} color={color} />
                    );
                    
                })}
            </tbody>
        </table>
    );
    
};



export default table;