import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import LoadingView from '../LoadingView/LoadingView';
import Sidebar from '../../components/Sidebar/Sidebar.js';
import Swal from 'sweetalert2';
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
import './AlertView.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import AlertIcon from '../../images/alerts.js';
import AlertBlock from '../../components/Alert/AlertBlock/AlertBlock.js';
import AlertHistoryBlock from '../../components/Alert/AlertBlock/AlertHistoryBlock.js';
import AlertHistoryGraph from '../../components/Alert/AlertBlock/AlertHistoryGraph.js';
import config from '../../utilities/config';
import Modal from 'react-bootstrap/Modal';

class AlertView extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            data : null,
            selectedBuilding: null,
            buildingTitle: null,
            leakData: null,
            alertTotalsByDay: {},
            alertHistoryMap: {},
            alertHistoryBlocks: null,
            showModal: false,
            buildings: null,
            startDate: null,
            endDate: null,
            widgetData: null,
            totalRepairs: 0,
            leakTotal: 0,
            dateRange: null,
            loaded: false,
            numAlerts: 0
        };

    }


    componentDidMount(){
        document.body.style.backgroundColor = "#EBF2F8";
        this.generateAlertSummary(config.dateRange);
        window.addEventListener('resize', () => this.setState({windowWidth: window.innerWidth}));
        gtag('config', config.gtag, {
            page_title: 'Alert_view',
          })
        gtag('event','page_view')
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.setState({windowWidth: window.innerWidth}));
    }

    generateAlertSummary(numDays) {
        this.setState({
            dateRange: numDays,
            loaded: false
        });
        localStorage.setItem("mlDateRange" , numDays);
       
        let url = `${config.api}advanced-building-functions/user/${config.userID}`;
        //console.log(url);
        //get all the buildings for user. If user has only one building, get that building (building), 
        //otherwise, get all building information in the summary.

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
          )

          .then((response) => {
            let buildings = response.data;

            if (buildings.length == 1){//User owns one building
                let building = buildings[0];
                let buildingID = building["id"];
                localStorage.setItem("mlBuildingID" , buildingID);
                config.selectedBuilding = buildingID;
                
                this.getBuildingInformation(buildings , config.startDate , config.endDate);
                
                

            }
            else if(buildings.length == 0){
                //this shouldn't be the case but possible and we should handle it
                Swal.fire(
                    {"title" : "You have no buildings setup for this account. Click OK to return to the profile page"}
                ).then((result) => {
                    /* Read more about handling dismissals below */
                    window.location.href ="/profile";
                    
                });



            }
            else{//User owns multiple buildings

                if (config.selectedBuilding == 0) {
                    this.getUserBuildingInformation(buildings , config.startDate , config.endDate);
                }
                else {
                    this.getBuildingInformation(buildings , config.startDate , config.endDate);
                }
            }
        });
    }

    getBuildingInformation(buildings , startDate , endDate){

        let url = `${config.api}metrics/portalStats/building/${config.selectedBuilding}?startDate=${startDate}&endDate=${endDate}`;
        //console.log(url);

        let sCall = Date.now();
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
          )

          .then((response2) => {
            let eCall = Date.now();



            let lData = response2["data"];
            let keys = Object.keys(lData);
            let fKey = keys[0];
            let iData = lData[fKey]["statsByDate"];
            let rData = {};

            let iDataKeys = Object.keys(iData);
            for(let i =0 ; i < iDataKeys.length; i++){
                let curKey = iDataKeys[i]
                let curData = iData[curKey];

                let date = curKey;
                let gallons = curData["gallonsUsed"];
                let flushes = curData["flushes"];
                let gallonsWasted = curData["gallonsWasted"];
                let leakCost = curData["leakCost"];
                rData[date] = curData;

            }
            let leakData = {}
            let leakTotal = 0
            leakData[fKey] = lData[fKey]["statsForProblems"];
            leakTotal = Object.keys(leakData[fKey]).length;

            let flushData = {};
            flushData[fKey] = lData[fKey]["locationStatus"];

            this.setState({
                data: rData,
                leakData: leakData,
                leakTotal: leakTotal,
                flushData: flushData,
                buildings: buildings,
                selectedBuilding: config.selectedBuilding,
                startDate: startDate,
                endDate: endDate,
                loaded: true
            });
            this.createTableRows(leakData, flushData);


            },
            (error) => {
                //console.log(error);
            }
          );
    }

    getUserBuildingInformation(buildings , startDate , endDate){

        var now = new Date();
        var timestamp = now.getTime();
    
        
        let url = `${config.api}metrics/portalStats/user/${config.userID}?&startDate=${startDate}&endDate=${endDate}`;
        //console.log(url);

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
          )

          .then((response) => {


            //TODO AGGREGATE DATA BETWEEN THE DIFFERENT BUILDINGS AND THE DATES
            let lData = response["data"];
            let keys = Object.keys(lData);
            let rData = {};

            for(let j =0 ; j < keys.length ; j++){
                let fKey = keys[j];
                let iData = lData[fKey]["statsByDate"];

                let iDataKeys = Object.keys(iData);
                for(let i =0 ; i < iDataKeys.length; i++){
                    let curKey = iDataKeys[i]
                    let curData = iData[curKey];

                    let date = curKey;
 

                    //there are better ways of merging the objects, but this is the way 
                    if(rData[date] == undefined){
                        rData[date] = curData;
                    }
                    else{
                        rData[date] = this.mergeTwoObjectValues(rData[date] , curData , Object.keys(curData));
                    }


                }
            }

            let leakData = {};
            let leakTotal = 0;
            for (let i =0 ; i < keys.length ; i++){
                const curKey = keys[i];
                leakData[curKey] = lData[curKey]["statsForProblems"];
                leakTotal += Object.keys(leakData[curKey]).length
            }

            let flushData = {};
            for (let i =0 ; i < keys.length ; i++){
                const curKey = keys[i];
                flushData[curKey] = lData[curKey]["locationStatus"];
            }


            this.setState({
                data: rData,
                leakData: leakData,
                leakTotal: leakTotal,
                flushData: flushData,
                buildings: buildings,
                selectedBuilding: config.selectedBuilding,
                startDate: startDate,
                endDate: endDate,
                loaded: true
            });
            this.createTableRows(leakData, flushData);
            
            },
            (error) => {
                //console.log(error);
            }
          );
    }


    exportData = (api, token, title) => {
        //console.log(this.state);

        if (this.state.loaded) {
            Swal.fire({
                'title' : 'Exporting File ...',
                'timer' : 60000,
                'allowOutsideClick': false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            });

            const exportAlertData = []

            for (let i = 0; i < this.state.usageBlocks.length; i++) {
                let severityLabel = "";
                switch (this.state.usageBlocks[i].props["problem"]['severityNum']) {
                    case 5:
                        severityLabel = "high"
                        break;
                    case 4:
                        severityLabel = "high"
                        break;
                    case 3:
                        severityLabel = "medium"
                        break;
                    case 2:
                        severityLabel = "low"
                        break;
                    case 1:
                        severityLabel = "low"
                        break;
                    default:
                        severityLabel = "Unknown"
                        break;
                }
                const locationLabel = this.state.usageBlocks[i].props["problem"]["locationLabel"];
                const building = this.state.usageBlocks[i].props["problem"]["building"];
                exportAlertData.push({
                    "building" : this.state.usageBlocks[i].props["problem"]["buildingLabel"],
                    "date" : this.state.usageBlocks[i].props["problem"]["date"],
                    "room" : locationLabel,
                    "num_gallons" : this.state.usageBlocks[i].props["problem"]["gallons"].toLocaleString(),
                    'severity': severityLabel,
                    'num_flushes': this.state.flushData[building][locationLabel]["flushes"]
                });
            }


            const requestBody = {
                "reportType" : "alerts",
                "data": {
                    "building_title": title,
                    "building_subtitle": "",
                    "start_date": this.state.startDate,
                    "end_date": this.state.endDate,
                    "alerts": exportAlertData
                }  
            };

            let url = api+`pdf-page-export`;
            let auth = `Bearer `+token;
            var config = {
                method: 'post',
                url: url,
                headers: { 
                  'Authorization': auth, 
                  'Content-Type': 'application/json', 
                },
                data : requestBody
              };
              
              axios(config)
              .then(function (response) {
                //console.log(JSON.stringify(response.data));
                let data = response["data"];
                let uploadFile = data["uploadFile"];
                let url = uploadFile["uploadFile"];
                Swal.close();
                Swal.fire({
                    title: 'Report Created',
                    text: 'Clicking the download button will open a new tab or window to download your file.',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3578bd',
                    confirmButtonText: 'Download'
                  }).then(() => {
                    window.open(url);
                  })
              });

        }
    }; 

    createTableRows(leakData, flushData) {
        const dataKeys = Object.keys(leakData);

        //Recreate the roomUsage object with the list of rooms/totals as a map
        let alertsToDisplay = {};
        let alertTotalsByDay = {};

        let alertHistoryMap = {};

        for (let i=0; i<dataKeys.length; i++) {
            const problems = leakData[dataKeys[i]];
            const problemKeys = Object.keys(leakData[dataKeys[i]]);
            alertsToDisplay[dataKeys[i]] = new Map();
            for (let k=0; k<problemKeys.length; k++) {
                const curAlert = problems[problemKeys[k]]
                if (curAlert.gallons != 0) {
                    const newAlert = {
                        "date": curAlert.date,
                        "building": curAlert.building,
                        "locationLabel": curAlert.locationLabel,
                        "gallons": curAlert.gallons,
                        "buildingLabel": curAlert.buildingLabel,
                        "severityNum": curAlert.severityNum,
                        "locationID": curAlert.locationID,
                        "alertCount": 0
                    };
                    const newAlertForHistory = {
                        "date": curAlert.date,
                        "building": curAlert.building,
                        "locationLabel": curAlert.locationLabel,
                        "gallons": curAlert.gallons,
                        "buildingLabel": curAlert.buildingLabel,
                        "severityNum": curAlert.severityNum,
                        "locationID": curAlert.locationID
                    };

                    if(alertHistoryMap[newAlertForHistory.locationID] == null) { //No alerts added yet
                        alertHistoryMap[newAlertForHistory.locationID] = [newAlertForHistory];
                    }
                    else {
                        alertHistoryMap[newAlertForHistory.locationID].push(newAlertForHistory)
                    }
                    if (alertsToDisplay[dataKeys[i]].has(newAlert.locationLabel)) {
                        let curAlert = alertsToDisplay[dataKeys[i]].get(newAlert.locationLabel);
                        if (Date.parse(curAlert.date) > Date.parse(newAlert.date)) { //if the alert is newer, increment alertCount
                            curAlert.alertCount += 1;
                            alertsToDisplay[dataKeys[i]].set(newAlert.locationLabel, curAlert);
                        }
                        else { //if the alert is older, replace it, increment alertCount
                            newAlert.alertCount = (curAlert.alertCount + 1);
                            newAlert.gallons += curAlert.gallons
                            alertsToDisplay[dataKeys[i]].set(newAlert.locationLabel, newAlert);
                        }
                    }
                    else {
                        alertsToDisplay[dataKeys[i]].set(newAlert.locationLabel, newAlert);
                    }
                    
                    if (alertTotalsByDay[curAlert.date] == null) {
                        alertTotalsByDay[curAlert.date] = 1;
                    }
                    else {
                        alertTotalsByDay[curAlert.date] += 1;
                    }
                }
            }
        }

        //console.log(alertHistoryMap);
        //console.log(alertsToDisplay);
        //console.log(alertTotalsByDay);

        let usageBlocks = [];
        let problems = [];
        let highProblems = [];
        let medProblems = [];
        let lowProblems = [];
        for (let i=0; i<dataKeys.length; i++) {
            alertsToDisplay[dataKeys[i]].forEach((alert) => {
                ////console.log(alert);
                switch (alert.severityNum) {
                    case 5:
                        highProblems.push(alert);
                        break;
                    case 4:
                        highProblems.push(alert);
                        break;
                    case 3:
                        medProblems.push(alert);
                        break;
                    case 2:
                        lowProblems.push(alert);
                        break;
                    case 1:
                        lowProblems.push(alert);
                        break;
                    default:
                        break;
                }
            });
        }
        highProblems.sort((a, b) => (a.date < b.date) ? 1 : -1);
        medProblems.sort((a, b) => (a.date < b.date) ? 1 : -1);
        lowProblems.sort((a, b) => (a.date < b.date) ? 1 : -1);
        problems = problems.concat(highProblems.concat(medProblems).concat(lowProblems));

        if (problems.length > 0) {
            let tempBlocks = problems.map((problem) => {
                let severityClass = "severity-" + problem.severityNum;
                let severityLabel = "";
                let severityColor = ""
                switch(severityClass) {
                    case "severity-5":
                        severityLabel = "Critical"
                        severityColor = "#E73C3E"
                        break;
                    case "severity-4":
                        severityLabel = "High"
                        severityColor = "#E73C3E"
                        break;
                    case "severity-3":
                        severityLabel = "Medium"
                        severityColor = "#FF8132"
                        break;
                    case "severity-2":
                        severityLabel = "Low"
                        severityColor = "#f8de7e"
                        break;
                    case "severity-1":
                        severityLabel = "Very Low"
                        // severityColor = "#F1F72F"
                        severityColor = "#f8de7e"
                        break;
                    default:
                        break;
                }

                //console.log(problem);
                return (
                    <AlertBlock 
                        severityColor={severityColor} 
                        problem={problem}
                        flushData={flushData} 
                        showAlertHistory={this.showAlertHistory}
                    />
                )
            })
            usageBlocks = usageBlocks.concat(tempBlocks);
        }

        this.setState({
            usageBlocks: usageBlocks,
            alertTotalsByDay: alertTotalsByDay,
            numAlerts: problems.length,
            alertHistoryMap: alertHistoryMap
        });

    }

    showAlertHistory = (locationID) => {
        let alertHistory = this.state.alertHistoryMap[locationID];
        //console.log(alertHistory);

        //Fetch the location metrics for detailed flush information
        var getConfig = {
            method: 'get',
            url: `${config.api}metrics/locations/${locationID}?startDate=${config.startDate}&endDate=${config.endDate}`,
            headers: { 
                'Authorization': `Bearer ${config.token}`
            }
        };
        //console.log(getConfig.url);

        axios(getConfig)
        .then((response) => {//Success
            const data = response.data[0];
            //Grab flushes per day from data
            const dayBreakdown = data.dayBreakDown;
            let flushesByDay = {};
            dayBreakdown.forEach((day) => {
                flushesByDay[day.dDate] = day.totalFlushes; 
            });

            //Combine flush and alert history
            let alerts = alertHistory.map((curAlert) => {
                return(
                    {
                        "date": curAlert.date,
                        "building": curAlert.building,
                        "locationLabel": curAlert.locationLabel,
                        "gallons": curAlert.gallons,
                        "buildingLabel": curAlert.buildingLabel,
                        "severityNum": curAlert.severityNum,
                        "locationID": curAlert.locationID,
                        "flushCount": flushesByDay[curAlert.date]
                    }
                );
            });

            //Sort alerts with newest first
            alerts.sort((a, b) => (a.date < b.date) ? 1 : -1);

            //Create alert blocks
            let alertHistoryBlocks = alerts.map((alert) => {
                let severityClass = "severity-" + alert.severityNum;
                let severityLabel = "";
                let severityColor = ""
                switch(severityClass) {
                    case "severity-5":
                        severityLabel = "Critical"
                        severityColor = "#E73C3E"
                        break;
                    case "severity-4":
                        severityLabel = "High"
                        severityColor = "#E73C3E"
                        break;
                    case "severity-3":
                        severityLabel = "Medium"
                        severityColor = "#FF8132"
                        break;
                    case "severity-2":
                        severityLabel = "Low"
                        severityColor = "#f8de7e"
                        break;
                    case "severity-1":
                        severityLabel = "Very Low"
                        severityColor = "#f8de7e"
                        break;
                    default:
                        break;
                }
                return (
                    <AlertHistoryBlock 
                        severityColor={severityColor} 
                        problem={alert}
                        flushCount={alert.flushCount} 
                    />
                )
            })

            this.setState({
                showModal: true,
                alertHistoryBlocks: alertHistoryBlocks
            });
        },
        (error) => {//Error fetching location details
            //console.log(error);
            Swal.fire({
                title: 'Sorry',
                text: 'We couldn\'t get the alert history'
            });
        })
        .catch((error) => {//Error fetching location details
            //console.log(error);
            Swal.fire({
                title: 'Sorry',
                text: 'Something went wrong while getting the alert history'
            });
        });
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    //assuming the keys are the same for each , otherwise, you should use the {...obj1 , ...obj2} operator first
    mergeTwoObjectValues(obj1 , obj2 , keys){
        let obj3 = {};
        for(let i = 0 ; i < keys.length ; i++){
            let curKey = keys[i];
            let val = obj1[curKey] + obj2[curKey];
            obj3[curKey] = val;
        }

        return obj3;


    }

    render(){

        if (this.state.loaded) {
            let sevenDayGallons = 0;
            let sevenDayFlushes = 0;
            let sevenDayGallonsLost = 0;
            let sevenDayGallonsCost = 0;

            let data = this.state.data;
            let leakData = this.state.leakData;
            let gData = null;
            let gallonData = [];
            let wastedData = [];
            let keys = null;

            if(data != null){

                keys = Object.keys(data).sort();
                gallonData = [];
                wastedData = [];
                for(let i =0 ; i < keys.length ; i++){
                    let key = keys[i];
                    let gallons = parseInt(data[key]["gallonsUsed"]);

                    let flushes = data[key]["flushes"];
                    let gallonsWasted = parseInt(data[key]["gallonsWasted"]);
                    let leakCost = Number(parseFloat(data[key]["leakCost"]).toFixed(2));
                    gallonData.push(gallons);
                    wastedData.push(gallonsWasted);
                    sevenDayGallons += gallons;
                    sevenDayFlushes += flushes;
                    sevenDayGallonsLost += gallonsWasted;
                    sevenDayGallonsCost += leakCost;
                }
            }

            Swal.close()
            return ( 
                <div id="pageOuterContainer" className="pageOuterContainer" >
                    {this.state.windowWidth > 650 &&
                        <Sidebar 
                            pageWrapId={"pageWrap"} 
                            outerContainerId={"pageOuterContainer"} 
                            generateSummary={this.generateAlertSummary.bind(this)}
                            buildings={this.state.buildings} 
                            buttonsVisible={true}
                            handleExport={this.exportData}
                        />
                    }

                    <div id="pageWrap" className="pageWrap" >

                    {this.state.windowWidth <= 650 &&
                        <Sidebar 
                            pageWrapId={"pageWrap"} 
                            outerContainerId={"pageOuterContainer"} 
                            generateSummary={this.generateAlertSummary.bind(this)}
                            buildings={this.state.buildings} 
                            buttonsVisible={true}
                            handleExport={this.exportData}
                        />
                    }
                        <Container>
                            <Row className="alertView-top-row">
                                <Col xs={12}>
                                    <AlertHistoryGraph alertData={this.state.alertTotalsByDay} />
                                </Col>
                            </Row>
                            <Row className="alertView-bottom-row">
                                <Col xs={12}>
                                    {/* <ButtonGroup>
                                        <Button >Location</Button>
                                        <Button >Day</Button>
                                    </ButtonGroup> */}
                                    {this.state.usageBlocks}
                                </Col>
                            </Row>

                            <Modal 
                                show={this.state.showModal} 
                                onHide={this.handleClose.bind(this)} 
                                style={{opacity: 1}}
                                dialogClassName={this.state.windowWidth > 650 ? "modal-75w" : "modal-100W"}
                                animation={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Alert History</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {this.state.alertHistoryBlocks}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                    </div>
    
                </div>
            );
        }
        else {
            return(
                <LoadingView />
            );
        }

    }
}


export default withRouter(AlertView);