import React from 'react';
import './FAQ.scss';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';



const FAQ = () => {
    return (
        <Container>

            {/* QUESTION 1 */}
            <Dropdown className='Dropdown'>
                <Dropdown.Toggle className="FAQView" id="question_1">
                    What is the recommended browser for the FlowActive Portal?
                </Dropdown.Toggle>

                <Dropdown.Menu className="FAQView">
                    We recommend you use Chrome. Download Chrome using the following link: https://www.google.com/chrome/
                </Dropdown.Menu>
            </Dropdown>

            {/* QUESTION 2 */}
            <Dropdown className='Dropdown'>
                <Dropdown.Toggle className="FAQView" id="question_2">
                    How do I change my alert settings?
                </Dropdown.Toggle>

                <Dropdown.Menu className="FAQView">
                    Begin by going to the profile section of the portal which is located in the lower left corner on the navigation bar -{'>'} Select “Alerts”.
                </Dropdown.Menu>
            </Dropdown>

            {/* QUESTION 3 */}
            <Dropdown className='Dropdown'>
                <Dropdown.Toggle className="FAQView" id="question_3">
                    How do I change my account password?
                </Dropdown.Toggle>

                <Dropdown.Menu className="FAQView">
                    Begin by going to the profile section of the portal which is located in the lower left corner
                        on the navigation bar -{'>'} Select “Account” -{'>'} Select “Change Password”.
                </Dropdown.Menu>
            </Dropdown>

            {/* QUESTION 4 */}
            <Dropdown className='Dropdown'>
                <Dropdown.Toggle className="FAQView" id="question_4">
                How do I view a specific location/device?
                </Dropdown.Toggle>

                <Dropdown.Menu className="FAQView">
                    Either click on the toilet icon, or click on the three stacked lines on the left of the screen to open your navigation bar and select “Locations”. Search the location of the toilet by typing in the name of the location, or use the dropdown menu to select the location you wish to view.
                </Dropdown.Menu>
            </Dropdown>

            {/* QUESTION 5 */}
            <Dropdown className='Dropdown'>
                <Dropdown.Toggle className="FAQView" id="question_5">
                How do I view a specific building?
                </Dropdown.Toggle>

                <Dropdown.Menu className="FAQView">
                    If you have multiple buildings on your account, you can use the dropdown menu in the top right of the portal to select a different building. You can change this in any section throughout the portal. You may view data on all buildings or an individual building.
                </Dropdown.Menu>
            </Dropdown>

            {/* QUESTION 6 */}
            <Dropdown className='Dropdown'>
                <Dropdown.Toggle className="FAQView" id="question_6">
                What are the minimum phone requirements for the FlowActive App?
                </Dropdown.Toggle>

                <Dropdown.Menu className="FAQView">
                    A smartphone running Android 9 or higher, or iOS 13 or higher.
                </Dropdown.Menu>
            </Dropdown>

            {/* QUESTION 7 */}
            <Dropdown className='Dropdown'>
                <Dropdown.Toggle className="FAQView" id="question_7">
                How do I add a new property on the FlowActive App?
                </Dropdown.Toggle>

                <Dropdown.Menu className="FAQView">
                    In the Installation Menu, go to either “Set Up New Device” or “Change Device Location/Toilet” you can add a new property by selecting “Add New...” at the bottom of the list.
                </Dropdown.Menu>
            </Dropdown>

            {/* QUESTION 8 */}
            <Dropdown className='Dropdown'>
                <Dropdown.Toggle className="FAQView" id="question_8">
                How do I add a new building on the FlowActive App?
                </Dropdown.Toggle>

                <Dropdown.Menu className="FAQView">
                    In the Installation Menu, go to either “Set Up New Device” or “Change Device Location/Toilet”. You can add a new building by selecting “Add New...” at the bottom of the list.
                </Dropdown.Menu>
            </Dropdown>

            {/* QUESTION 9 */}
            <Dropdown className='Dropdown'>
                <Dropdown.Toggle className="FAQView" id="question_9">
                How do I update a device WiFi?
                </Dropdown.Toggle>

                <Dropdown.Menu className="FAQView">
                    Have the device close by. After logging into the app using your credentials, you will be brought to the Installation Menu, click on “Change Device WiFi”. You will then be prompted to scan the QR code found on the label on the device. Follow the instructions within the app to update your WiFi settings.
                </Dropdown.Menu>
            </Dropdown>

            {/* QUESTION 10 */}
            <Dropdown className='Dropdown'>
                <Dropdown.Toggle className="FAQView" id="question_10">
                How do I move a device to a new/different location?
                </Dropdown.Toggle>

                <Dropdown.Menu className="FAQView">
                    In the Installation Menu, select “Change Device Location/Toilet”. Select which property and building you would like to change the location to, and scan the QR code on the label on the device you are changing. Continue to follow the installation instructions within the app to finish the process.
                </Dropdown.Menu>
            </Dropdown>

        </Container>
    );
};
export default FAQ;