import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import './TopicCard.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const TopicCard = (props) => {
  // const [showModal, setShowModal] = useState(false);

  const openInNewTab = (url) => {
    window.open(url, "_blank")
  }

  return( 
    <Container className="topicCard">
      <Row className="topicRow1" onClick={() => openInNewTab(props.text[2])}>
          <Container>
            {props.text[0]}
          </Container>
      </Row>
      <Row className="topicRow2" onClick={() => openInNewTab(props.text[2])}>
          <Container>
            {props.text[1]}
          </Container>
      </Row>


      {/* <Modal 
          show={showModal} 
          onHide={() => setShowModal(false)} 
          style={{opacity: 1}}
          fade={false}
          dialogClassName="modal-75w"
      >
          <Modal.Header closeButton>
              <Modal.Title>
                {props.text[0]}
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {props.text[2]}
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Close
              </Button>
          </Modal.Footer>
      </Modal> */}
    </Container>
  )

};

export default TopicCard;