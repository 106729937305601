import React from "react";
import { withRouter } from 'react-router-dom';
import './VideoWalkthrough.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const InstallVideo = (props) => {

  return( 
    <Container className="video-responsive">
      <Row>
        <iframe
          width="500"
          height="250"
          src={`https://www.youtube.com/embed/bVu5FyWPwu0`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Row>
    </Container>
  )

};

export default InstallVideo;