import React, { Component } from 'react';
import LoadingView from '../LoadingView/LoadingView';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config.js';
import { getBuildingLocations, getDeviceInfo, getDeviceRawMetrics, getlocationMetrics, getPropertyBuildings } from '../../utilities/api';
import { alertError } from '../../utilities/utils';

var buildingInfo = {};
var locationSet = {};

class BuildingReportView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buildingIDCount: null,
            loaded: false,
            loadCount: 0,
            locSetRows: [],
            selectedBuilding: props.building,
            // selectedProperty: props.propertyID,
            selectedProperty: 73,
            selectedBuilding: 61,
            reportTables: []
        }
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'BuildingReport_view',
          })
        gtag('event','page_view')
        // this.getAllBuildings();
        // this.getLocations(this.state.selectedBuilding);
        this.getPropertyBuildings(this.state.selectedProperty)
    }

    componentWillUnmount() {
        buildingInfo = {};
        locationSet = {};
    }
    
    async getPropertyBuildings(propertyID) {

        const buildings = await getPropertyBuildings(propertyID)
        if (buildings.error) {
            alertError(buildings.error);
            return;
        }

        buildings.data.forEach((building) => {
         this.getLocations(building.id, building.label);
        });
    }

    async getLocations(buildingID, buildingLabel) {

        const locationData = await getBuildingLocations(buildingID);
        if (locationData.error) {
            alertError(locationData.error);
            return;
        }

        var locations = locationData.data.map((item) => {
            return (item);
        });
        this.getLocationData(buildingID, locations, buildingLabel);
    }

    getLocationData(buildingID, locations, buildingLabel) {
        let locCounter = 0;
        //console.log(locations);
        // locations.sort((a, b) => (a.locationName > b.locationName) ? 1 : -1);
        locations.sort((a,b) => (a.locationName > b.locationName) ? 1 : ((b.locationName > a.locationName) ? -1 : 0));
        locations.forEach(async (location, index, array) => {
            //console.log(location);
            if (location['deviceID']) {
                const deviceInfo = await getDeviceInfo(location["deviceID"]);
                if (deviceInfo.error) {
                    alertError(deviceInfo.error);
                    return;
                }
    
                // this.getRssi(deviceInfo.data, buildingID);
                const deviceMetrics = await getDeviceRawMetrics(location['deviceID']);
                if (deviceMetrics.error) {
                    alertError(deviceMetrics.error);
                    return;
                }
    
                if (deviceInfo.data && deviceMetrics.data) {
                    this.getWaterInfo(deviceInfo.data, buildingID, deviceMetrics.data).then(() => {
                         locCounter++;
                         if (locCounter === array.length) {
                             this.createLocSetRows(buildingID, buildingLabel);
                         }
                    });
                }
                else {
                    locCounter++;
                    if (locCounter === array.length) {
                        this.createLocSetRows(buildingID, buildingLabel);
                    }
                    if (locationSet[buildingID] == null) {
                        locationSet[buildingID] = [{location: location.locationName, device: location.deviceID, timeLast: '-', rssi: '-', water48: '-'}];
                    }
                    else {
                        locationSet[buildingID].push({location: location.locationName, device: location.deviceID, timeLast: '-', rssi: '-', water48: '-'});
                    }
                }
            }
            else {
                locCounter++;
                if (locCounter === array.length) {
                    this.createLocSetRows(buildingID, buildingLabel);
                }
                if (locationSet[buildingID] == null) {
                    locationSet[buildingID] = [{location: location.locationName, device: location.deviceID, timeLast: '-', rssi: '-', water48: '-'}];
                }
                else {
                    locationSet[buildingID].push({location: location.locationName, device: location.deviceID, timeLast: '-', rssi: '-', water48: '-'});
                }
            }
        });
    }

    async getWaterInfo(locationData, buildingID, deviceData) {
        //Get location metrics

        const now = new Date();
        const offset = now.getTimezoneOffset();
        let endDate = new Date(now.getTime() - (offset*60*1000));
        let startDate = new Date(now.getTime() - ((offset*60*1000) + 86400000));
        endDate = endDate.toISOString().split('T')[0];
        startDate = startDate.toISOString().split('T')[0];
        
        const locationMetrics = await getlocationMetrics(locationData.locationID, startDate, endDate);
        if (locationMetrics.error) {
            alertError(locationMetrics.error);
            return;
        }

        const dayBreakdown = locationMetrics.data['dayBreakDown'];
        // //console.log(dayBreakdown);
        let water48 = '❌';
        dayBreakdown.forEach((day) => {
             if (day.gallons > 0) {
                 water48 = '✅';
             }
        });

        //console.log(deviceData);
        if (locationSet[buildingID] == null) {
            locationSet[buildingID] = [{location: locationData["locationLabel"], device: locationData["deviceID"], timeLast: deviceData.deliveredTime, rssi: deviceData.rssi, water48: water48}];
        }
        else {
            locationSet[buildingID].push({location: locationData["locationLabel"], device: locationData["deviceID"], timeLast: deviceData.deliveredTime, rssi: deviceData.rssi, water48: water48});
        }

        // return this.createLocSetRows(buildingID);
    }

    createLocSetRows = (buildingID, buildingLabel) => {
        const curLocArray = locationSet[buildingID];
        const locSetRows = curLocArray.map((locSet, index) => {
            const timeCheck = Date.now();
            const timeStamp = new Date(locSet.timeLast).toLocaleString();
            // //console.log(timeCheck);
            let timeStatus = '❌';
            if (locSet.timeLast > (timeCheck - 86400000)) {
                timeStatus = '✅';
            }
            // if (index == 0) {
            //     return (
            //         <thead>
            //             <tr>
            //                 <th>{buildingID}</th>
            //             </tr>
            //         </thead>
            //     )
            // }
            return (
                <tr>
                    <td>{locSet.location}</td>
                    <td>{locSet.device}</td>
                    <td>{timeStatus}</td>
                    <td>{timeStamp}</td>
                    <td>{locSet.rssi}</td>
                    <td>{locSet.water48}</td>
                </tr>
            );
        });

        // return locSetRows;

        const reportTable = (
            <Table bordered hover>
                <thead>
                    <tr>
                        <h3>{buildingLabel}</h3>
                    </tr>
                    <tr>
                        <th>Location</th>
                        <th>Device</th>
                        <th>Coms in Last 24</th>
                        <th>Last Coms</th>
                        <th>Rssi</th>
                        <th>Water in Last 24</th>
                    </tr>
                </thead>
                <tbody>
                    {locSetRows}
                </tbody>
            </Table>
        )

        // this.setState({
        //     // locSetRows: locSetRows
        //     reportTable: reportTable
        // })
        this.setState((prevState) => ({
            reportTables: [...prevState.reportTables, reportTable]
        }));
    }

    render() {
        //console.log(locationSet);
        if (this.state.locSetRows != null) {
            Swal.close();
            return (
                // <Container>
                //     <Table bordered hover>
                //         <thead>
                //             <tr>
                //                 <th>Room</th>
                //                 <th>Device</th>
                //                 <th>Coms in Last 24</th>
                //                 <th>Last Coms</th>
                //                 <th>Rssi</th>
                //                 <th>Water in Last 24</th>
                //             </tr>
                //         </thead>
                //         <tbody>
                //             {this.state.locSetRows}
                //         </tbody>
                //     </Table>
                // </Container>
                <Container>
                    {this.state.reportTables}
                </Container>
            );
        }
        else {
            return (
                <div>
                    <LoadingView />
                </div>
            );
        }
    }
}

export default withRouter(BuildingReportView);
