import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import config from '../../utilities/config';
import LoadingView from '../LoadingView/LoadingView';
import { Button } from 'react-bootstrap';

class FullDeviceReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'FullDeviceReport',
          })
        gtag('event','page_view')
        this.generateFullDeviceReport()
    }

    generateFullDeviceReport() {
        axios({
            method: 'get',
            url: `${config.api}device-excel-report`,
            headers: { 
                'Authorization': `Bearer ${config.token}`
            }
        })
        .then((response) => {
            const url = response.data.reportFileURL.uploadFile;

            Swal.fire({
                title: 'Report Created',
                text: 'Clicking the download button will open a new tab or window to download your file.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3578bd',
                confirmButtonText: 'Download'
            }).then(() => {
                window.open(url);
            })
        })
        .catch((error) => {
            //console.log(error);
            Swal.close()
            Swal.fire({
                title: 'Error',
                text: error,
                icon: 'error'
            })
        });

    }

    render() {
        return (
            <div style={{justifyContent: 'center', alignItems: 'center'}}>
                <Button href="/tools"> Back to All Tools </Button>
                <LoadingView />
            </div>
        );
    }
}

export default withRouter(FullDeviceReport);