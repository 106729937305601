import React from 'react';
import {numberWithCommas} from '../../UniversalFunctions/UniversalFunctions';

function makeSevDiv(color , num){
    return (
        <div class='shape' style={{ "textAlign":"center" , "display": "inline-block", "width": "50px" , "height":"50px" , "backgroundColor" : color}} >
            <div style={{"paddingLeft":"8px" , "paddingTop":"6px"}} class='labelReport'>
                {num}
            </div>
        </div>

    );
}

const reportTableBody = (props) => {

    let locationData = props.locationData;

    let rData = locationData.map((obj,index) => {
        let room = obj["room"];
        let low = obj["sev2"]; // system defintion probable
        let med = obj["sev3"];  //system definition low or medium
        let high = obj["sev4"]; //system definition high or critical
        let cost = obj["cost"];
        if (cost > 10){
            cost = "$" + cost.toFixed(0);
        }
        else{
            cost = "$" + cost.toFixed(2);
        }
        let gallons = numberWithCommas(obj["gallons"]);
        let lGallons = numberWithCommas(obj["lGallons"]);
        let lAlerts = obj["lAlerts"];

        let lowDiv = null;
        let medDiv = null;
        let highDiv = null;
        let noAlertDiv = null;
        if (low > 0){
            lowDiv = makeSevDiv("#FFDD00" , low);
        }

        if (med > 0){
            medDiv =  makeSevDiv("#FF6C11" , med);
        }

        if (high > 0){
            highDiv =  makeSevDiv("#E73C3E" , high);
        }

        if (lAlerts == 0){
            noAlertDiv = makeSevDiv("#56B947" , 0)
        }


        let alertsRaised = []
        alertsRaised.push(noAlertDiv);
        alertsRaised.push(lowDiv);
        alertsRaised.push(medDiv);
        alertsRaised.push(highDiv);




    return (<tr><td>{room}</td><td>{gallons}</td><td>{lGallons}</td><td>{cost}</td><td>{alertsRaised}</td></tr>);
    });

    return (
        <tbody>
            {rData}
        </tbody>
    );


}

export default reportTableBody;