import React from 'react';
import {numberWithCommas} from '../../UniversalFunctions/UniversalFunctions';

function headerBlock(label , label2 , url){
    return (<div className='container2' ><img width='100' height='100' src={url} /><p>{label}<br />{label2}</p></div>);
}

function fn (num , numType){
    if (numType == "cost"){
        return numberWithCommas(num.toFixed(2));
    }

    else if (numType == "costInt" ) { 
        return numberWithCommas(Math.ceil(num));
    }

    else if (numType == "int"){
        return numberWithCommas(num.toFixed(0));
    }
    else{
        
    }
}

const reportHead = (props) => {

    const roomNum = props.roomNum;
    const alertsRaised = props.alertsRaised;
    const gallonsUsed = props.gallonsUsed;
    const leakGallonsUsed = props.leakGallonsUsed;
    const cost = props.cost;
    const leakGallonsCost = props.leakGallonsCost;

    //+ startDate +  -  + endDate + 
    let hotelName = props.buildingName;
    let dateFormatted = props.dateLabel;
    const top = 
        (
            <center>
                <img src="https://mobius-labs-public-imgs.s3.amazonaws.com/flow-active.png" style={{"width" : "80%" , "maxWidth": "520px" }}/>
                <h2> {hotelName} </h2>
                <h3>{dateFormatted}</h3>
                <br />
                <br />
            </center>
        
        )


	const blockDeviceNum = headerBlock("Rooms Monitored" , fn(roomNum , "int") , "https://mobius-labs-public-imgs.s3.amazonaws.com/reportImages/favicon-256.png")
	const blockAlerts = headerBlock("Alerts Raised" , fn(alertsRaised , "int") , "https://mobius-labs-public-imgs.s3.amazonaws.com/reportImages/blue+alert.png")

	const blocksGallonsMeasured = headerBlock("Total Gallons Measured" , fn(gallonsUsed , "int") , "https://mobius-labs-public-imgs.s3.amazonaws.com/reportImages/blue+total+gallons.png")
	const blocksGallonsLeaked =  headerBlock("Total Gallons Leaked" , fn(leakGallonsUsed , "int") , "https://mobius-labs-public-imgs.s3.amazonaws.com/reportImages/blue+water+lost.png")


	const blocksTotalCost = headerBlock("Total Cost of Gallons Measured" , "$" + fn(cost , "costInt") , "https://mobius-labs-public-imgs.s3.amazonaws.com/reportImages/blue+total+cost.png")
	const blocksTotalCostLeaks = headerBlock("Total Cost of Gallons Leaked" , "$" + fn(leakGallonsCost , "costInt")  , "https://mobius-labs-public-imgs.s3.amazonaws.com/reportImages/blue+money+lost.png")

    let rValue = []
    rValue.push(top);
    rValue.push(blockDeviceNum);
    rValue.push(blockAlerts);
    rValue.push(blocksGallonsMeasured);
    rValue.push(blocksGallonsLeaked);
    rValue.push(blocksTotalCost);
    rValue.push(blocksTotalCostLeaks);


    return (
        <div>
            <center>
        {rValue}
        </center>
        </div>
	
    );


}

export default reportHead;