import React from 'react';


const reportTableHead = (props) => {

    const dateLabel = props.dateLabel;
    return (
        
        <thead style={{'textAlign':'left'}}>

            <tr style={{'height': '70px', 'fontWeight':'bold'}}>
                <th>Room <img width="22" height="17" src="https://mobius-labs-public-imgs.s3.amazonaws.com/Sort_both.svg.png" /></th>
                <th>Total Gallons Measured <img width="22" height="17" src="https://mobius-labs-public-imgs.s3.amazonaws.com/Sort_both.svg.png" /></th>
                <th>Total Gallons Leaked <img width="22" height="17" src="https://mobius-labs-public-imgs.s3.amazonaws.com/Sort_both.svg.png" /></th>
                <th>Total Cost of Gallons Measured <img width="22" height="17" src="https://mobius-labs-public-imgs.s3.amazonaws.com/Sort_both.svg.png" /></th>
                <th>Alerts Raised {dateLabel} <img width="22" height="17" src="https://mobius-labs-public-imgs.s3.amazonaws.com/Sort_both.svg.png" /></th>
            </tr>
        </thead>
	
    );


}

export default reportTableHead;