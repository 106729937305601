import React from 'react';
import './ReportTable.css';


const reportTableKey = (props) => {


    let reportData = props.data;

    return (
        <div id="reportTableHeader" style={{"marginBottom": "20px" , "height": "150px" , "max-width": "450px" , "border":"2px dotted #4CC0AC"}}>
            <p style={{"textAlign" :"left"}}><b>Key: Color Codes</b></p>
            <div className='shape shapeKey' style={{"textAlign" : "center" , "display": "inline-block"   , "backgroundColor" : "#56B947"  }} >
                <div className="largeShapeLabel labelReport" style={{"paddingLeft":"18px" , "paddingTop": "20px"}} >None</div>
                <div className="smallShapeLabel labelReport" >NP</div>
            </div>
            <div className='shape shapeKey' style={{"textAlign" : "center" , "display": "inline-block"  , "backgroundColor" : "#FFDD00"  }} >
                <div className="largeShapeLabel labelReport"  style={{"paddingLeft":"20px" , "paddingTop": "20px"}} >Low</div>
                <div className="smallShapeLabel labelReport">L</div>

            </div>
            <div className='shape shapeKey' style={{"textAlign" : "center" , "display": "inline-block" ,  "backgroundColor" : "#FF6C11"  }} >
                <div className="largeShapeLabel labelReport"  style={{"paddingLeft":"15px" , "paddingTop": "20px"}} >Medium</div>
                <div className="smallShapeLabel labelReport">M</div>

            </div>
            <div className='shape shapeKey' style={{"textAlign" : "center" , "display": "inline-block" ,  "backgroundColor" : "#E73C3E" }} >
                <div className="largeShapeLabel labelReport"  style={{"paddingLeft":"20px" , "paddingTop": "20px"}} >High</div>
                <div className="smallShapeLabel labelReport">H</div>
            </div>
        </div>
    );


}

export default reportTableKey;