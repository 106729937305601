import React, { Component } from 'react'
import { Button, Form, Table, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import axios from 'axios';
import config from '../../utilities/config';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

class WaterRateTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             demo1: 0,
             demo2: 0,
             demo3: 0,
             demo4: 0,
             selectedToChange: 29,
             newWaterRate: null,
             curWaterUnits: null,
             waterUnits: null,
             newelecCost:null
        }
    }

    componentDidMount() {
        // this.getWaterRates();

        //TEMP DEMO WATER RATE SOLUTION
        const curMultiplier = localStorage.getItem('mlDemoMultiplier');
        const curWaterUnits = localStorage.getItem('mlWaterUnits');
        if (curMultiplier == null || curWaterUnits == null) {
            this.setState({
                demoRate: 11.00,
                curWaterUnits: '100 Cubic Feet',
                waterUnits: '100 Cubic Feet'
            });
        }
        else {
            // const demoRate = (0.01470486008 * curMultiplier) * 748.1;
            // const demoRate = (0.01470486008 * curMultiplier);


            let demoRate = 0.00;
            let rate = parseFloat(0.01470486008 * curMultiplier); 
            const waterUnits = curWaterUnits.split(/(\s+)/).filter( e => e.trim().length > 0);
            const quant = parseInt(waterUnits[0]);
            const unit = waterUnits[1];
            if (unit === "Gallons") {
                demoRate = rate * quant;
            } else if (unit === "Cubic") {
                demoRate = rate * (quant * 7.481);
            }

            this.setState({
                demoRate: demoRate,
                curWaterUnits: curWaterUnits,
                waterUnits: curWaterUnits
            });
        }
    }
    

    getWaterRates = () => {
        // Demo 1
        axios({
            method: 'get',
            url: `${config.api}buildings/29/settings/cpg`,
            headers: {
            'Authorization': `Bearer ${config.token}`
            }
        })
        .then((response) => {
            const data = response['data'];
            const cpg = (data[0].costPerGallon * 748.1).toFixed(2);
            this.setState({
                demo1: cpg
            });
        })
        .catch((error) => {
            //console.log(error);
        });

        // Demo 2
        axios({
            method: 'get',
            url: `${config.api}buildings/30/settings/cpg`,
            headers: {
            'Authorization': `Bearer ${config.token}`
            }
        })
        .then((response) => {
            const data = response['data'];
            const cpg = (data[0].costPerGallon * 748.1).toFixed(2);
            this.setState({
                demo2: cpg
            });
        })
        .catch((error) => {
            //console.log(error);
        });

        // Demo 3
        axios({
            method: 'get',
            url: `${config.api}buildings/31/settings/cpg`,
            headers: {
            'Authorization': `Bearer ${config.token}`
            }
        })
        .then((response) => {
            const data = response['data'];
            const cpg = (data[0].costPerGallon * 748.1).toFixed(2);
            this.setState({
                demo3: cpg
            });
        })
        .catch((error) => {
            //console.log(error);
        });

        // Demo 4
        axios({
            method: 'get',
            url: `${config.api}buildings/105/settings/cpg`,
            headers: {
            'Authorization': `Bearer ${config.token}`
            }
        })
        .then((response) => {
            const data = response['data'];
            const cpg = (data[0].costPerGallon * 748.1).toFixed(2);
            this.setState({
                demo4: cpg
            });
        })
        .catch((error) => {
            //console.log(error);
        });
    }

    // updateWaterRates = () => {
    //     //console.log(this.state.selectedToChange);
    //     //console.log(this.state.newWaterRate);

    //     axios({
    //        method: 'post',
    //        url: `${config.api}/building/${this.state.selectedToChange}/settings/cpg/recalculate`,
    //        headers: {
    //           'Authorization': `Bearer ${config.token}`
    //        },
    //        data: `[{ "cpg": ${this.state.newWaterRate}, "sDate": "1970-01-01", "eDate": "2038-01-19" }]`
    //     })
    //     .then((response) => {
    //        const data = response['data'];
    //        Swal.fire({
    //            icon: 'success'
    //        })
    //     })
    //     .catch((error) => {
    //        //console.log(error);
    //        Swal.fire({
    //            icon: 'error',
    //            title: 'Error',
    //            text: 'Could not update ' 
    //        })
    //     });
    // }

    updateWaterRates = () => {
        // let newMultiplier = (this.state.newWaterRate / 748.1) / 0.01470486008;
        // localStorage.setItem('mlDemoMultiplier', newMultiplier);
        // localStorage.setItem('mlWaterUnits', this.state.waterUnits);

        let costPerGallon = 0.00;
        let rate = parseFloat(this.state.newWaterRate); 
        const waterUnits = this.state.waterUnits.split(/(\s+)/).filter( e => e.trim().length > 0);
        const quant = parseInt(waterUnits[0]);
        const unit = waterUnits[1];
        if (unit === "Gallons") {
            costPerGallon = rate/quant;
        } else if (unit === "Cubic") {
            costPerGallon = rate/(quant * 7.481);
        }
        //console.log(costPerGallon);
        //console.log(costPerGallon / 0.01470486008);

        localStorage.setItem('mlDemoMultiplier', costPerGallon / 0.01470486008);
        localStorage.setItem('mlWaterUnits', this.state.waterUnits);
        //TO DO: store electric cost

        Swal.fire({
            icon: 'success'
        })
        .then(() => window.location.reload());
    }
    
    render() {

        return (
            <div>
                <div>
                    <div style={{'paddingTop': '20px', 'textAlign': 'center'}}>
                        {/* <h2>Demo Water Rates</h2> */}
                        {/* <p>$$ per 100 cubic feet</p> */}
                        <h3>${this.state.demoRate} per {this.state.curWaterUnits}</h3>
                    </div>
                </div>
                <div>
                    {/* <Table responsive bordered striped>
                        <thead>
                            <tr>
                                <th>Hotel 1</th>
                                <th>Hotel 2</th>
                                <th>Commercial 1</th>
                                <th>Mixed Use 1</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{this.state.demoRate}</td>
                            <td>{this.state.demo1}</td>
                            <td>{this.state.demo2}</td>
                            <td>{this.state.demo3}</td>
                            <td>{this.state.demo4}</td>
                        </tr>
                        </tbody>
                    </Table> */}
                </div>
                    <div style={{'paddingTop': '20px', 'textAlign': 'center'}}>
                        {/* <h3>Update Water Rates</h3> */}
                        <p>Update Rate:</p>
                    </div>
                    <div style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        <Form style={{width: '50%', display: 'inline-block'}}>
                            <Form.Group>
                                {/* <Form.Control as="select" id="waterRateSelect" onChange={(e) => this.setState({selectedToChange: e.target.value})} defaultValue={this.state.selectedToChange} >
                                    <option value="29">Hotel 1</option>
                                    <option value="30">Hotel 2</option>
                                    <option value="31">Commercial 1</option>
                                    <option value="105">Mixed Use 1</option>
                                </Form.Control>
                                <br /> */}
                                
                                <Form.Control as="input" id="waterRateInput" onChange={(e) => this.setState({newWaterRate: e.target.value})} />
                                <br />
                                <p>per</p>
                                <br />
                                <Form.Control 
                                    id="unitInput1"
                                    required
                                    onChange={(event) => {
                                        this.setState({waterUnits: event.target.value});
                                    }}
                                    as="select"
                                >
                                    <option>{(this.state.waterUnits == null) ? '-' : this.state.waterUnits}</option>
                                    <option>100 Gallons</option>
                                    <option>100 Cubic Feet</option>
                                    <option>1000 Gallons</option>
                                    <option>1000 Cubic Feet</option>
                                </Form.Control>
                                <br />
                                <Form.Label>Electric Cost(per KWH):</Form.Label>
                                <Form.Control as="input" id="electricCostInput" onChange={(e) => this.setState({newelecCost: e.target.value})} />
                                <br />
                                <Button variant="primary" onClick={this.updateWaterRates} >Update</Button>
                            </Form.Group>
                        </Form>
                    </div>
            </div>
        )
    }
}

export default withRouter(WaterRateTable);
