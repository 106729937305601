import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView';
import AllDevicesTable from '../../components/Table/AllDevicesTable/AllDevicesTable';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import config from '../../utilities/config.js';


class AllDeviceView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDevices: [],
            loaded: true
        };;
    }
    
    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'AllDevices_view',
          })
        gtag('event','page_view')
        this.getAllDevices()
    }
    
    getAllDevices() {
        //Get all Users
        let url = `${config.api}get-all-devices`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
          )
          .then((response) => {
            //console.log("ALL RESPONSE LOG")
            //console.log(response)
            const data = response["data"];
            const devices = data.map((item) => {
                return item;
            });
            this.setState({
                allDevices: devices,
                loaded: true
            });
          },
          (error) => {
            //console.log(error);
          }
        );
    }


    render() {
        if (this.state.loaded) {
            Swal.close();
            return (
                    <Container>
                        <h2>All Devices</h2>
                        {/* <Button onClick={this.fixTheDevices.bind(this)} /> */}
                        <br />
                        <AllDevicesTable devices={this.state.allDevices} />
                    </Container>
                
            );
        }
        else {
            return (
                <div>
                    <LoadingView />
                </div>
            );
        }
    }
}

export default withRouter(AllDeviceView);