import React from 'react';
import './SummaryQS.css';
import SummaryInfoBlock from '../SummaryInfoBlock/SummaryInfoBlock';
import {numberWithCommas} from '../../UniversalFunctions/UniversalFunctions';


const summaryInfoBlock = (props) => {
        
        //sevenDayGallons={sevenDayGallons} sevenDayFlushes={sevenDayFlushes} sevenDayGallonsCost={sevenDayGallonsCost} sevenDayGallonsLost={sevenDayGallonsLost}

        const sevenDayGallons = props.sevenDayGallons;
        const sevenDayFlushes = props.sevenDayFlushes;
        const sevenDayGallonsCost = props.sevenDayGallonsCost.toFixed(2);
        const sevenDayGallonsLost = props.sevenDayGallonsLost;
        let info = [];
        //info.push(<SummaryInfoBlock className="col-sm-6" label="Total Flushes In Last 7 Days" stats={sevenDayFlushes} />);
        info.push(<SummaryInfoBlock className="col-sm-6" label="Measured Cost" stats={"$" + sevenDayGallonsCost} />);
        info.push(<SummaryInfoBlock className="col-sm-6" label="Gallons Used" stats={numberWithCommas(sevenDayGallons)} />);
        info.push(<SummaryInfoBlock className="col-sm-12" label="Gallons Leaked" stats={numberWithCommas(sevenDayGallonsLost)} />);
       
    
        return (

            <div style={{marginTop: "20px"}}>
                {info}
            </div>

        );
    
};

export default summaryInfoBlock;