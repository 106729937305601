import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Col, Row } from 'react-bootstrap';
import config from '../../utilities/config.js';

var autocompleteProperties = [];

class ModifyPropertyView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            selectedID: null,
            label: null,
            modifyForm: null
        };
    }

    componentDidMount() {
        gtag('config', config.gtag, {
            page_title: 'ModifyProperty_view',
          })
        gtag('event','page_view')
        this.getAllProperties()
    }

    clearform = () => {
        this.setState({
            modifyForm: null,
        })
    }

    getAllProperties() {
        //Get all Properties
        let url = `${config.api}get-all-properties`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                //console.log(response)
                const data = response["data"];
                autocompleteProperties = data.map((item) => {
                    return item;
                });

                this.setState({
                    loaded: true
                });

            },
                (error) => {
                    //console.log(error);
                }
            );
    }

    createModifyForm(selected) {
        //console.log(selected)
        this.setState({
            selectedID: selected.id,
            label: selected.label,
        });
        var modifyForm = (
            <Form>
                <Form.Group as={Row} controlId="modifyPropertyName">
                    <Form.Label column sm={2}>Name:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="label" onChange={this.handleInputChange} defaultValue={selected.label} />
                    </Col>
                </Form.Group>
                <Button variant="primary" onClick={this.handleSubmit} >Update Property</Button>
                <Button variant="danger" onClick={this.handleArchive} >Archive Property</Button>
                <Button variant="primary" onClick={this.clearform} >Clear</Button>
            </Form>
        )
        this.setState({
            modifyForm: modifyForm
        })
    }

    handleInputChange = (event) => {
        switch (event.target.id) {
            case "label":
                this.setState({
                    label: event.target.value
                });
                break;
        }
    }

    handleSubmit = () => {
        let data = `[{
                "id": ${this.state.selectedID}, 
                "label": "${this.state.label}"
            }]`

        axios({
            method: 'put',
            url: `${config.api}property`,
            headers: {
                'Authorization': `Bearer ${config.token}`,
                'Content-Type': 'text/plain'
            },
            data: data
        })
            .then((response) => {
                const data = response['data'];
                Swal.fire({
                    icon: 'success',
                    title: 'Success 👍🏼'
                });
            })
            .catch((error) => {
                //console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Whoops 😰',
                    text: error
                });
            });

    }

    handleArchive = () => {
        let data = `[{"id": ${this.state.selectedID}}]`

        axios({
            method: 'delete',
            url: `${config.api}property`,
            headers: {
                'Authorization': `Bearer ${config.token}`,
                'Content-Type': 'text/plain'
            },
            data: data
        })
            .then((response) => {
                const data = response['data'];
                Swal.fire({
                    icon: 'success',
                    title: 'Success 👍🏼'
                });
            })
            .catch((error) => {
                //console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Whoops 😰',
                    text: error
                });
            });

    }

    render() {
        if (this.state.loaded) {
            Swal.close();
            return (
                <Container>
                    <h2>Modify A Property</h2>
                    <Col style={{ marginTop: 12, padding: 12, marginBottom: 30 }}>
                        <ReactSearchAutocomplete
                            styling={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: '50%',
                                zIndex: '999',
                            }}
                            items={autocompleteProperties}
                            resultStringKeyName="label"
                            fuseOptions={{ keys: ["label"] }}
                            onSearch={() => { }}//ignored
                            onHover={() => { }}//ignored
                            onSelect={(item) => { this.createModifyForm(item) }}
                            onFocus={() => { }}//ignored
                            onClear={this.clearform}
                            maxResults={6}
                            showItemsOnFocus={true}
                            showNoResultsText="No Properties found"
                            formatResult={(item) => {
                                return (
                                    <span>{item.label}</span>
                                )
                            }}
                        />
                    </Col>
                    {this.state.modifyForm}
                </Container>
            )
        }
        else {
            return (
                <LoadingView />
            )
        }
    }
}

export default withRouter(ModifyPropertyView);