import React from 'react';
import Swal from 'sweetalert2';
import $ from 'jquery';

const alertStatButton = (props) => {


    function openAlertPage (e){

        let url = "/locationAlertStats/" + e.currentTarget.value;
        let win = window.open(url,'_blank');
        win.focus();
    }

    let id = props.id;


    return (

        <button value={id} className="btn-primary" onClick={openAlertPage} >
            More Info
        </button>

    );




};

export default alertStatButton;