import React from 'react';
import { withRouter } from 'react-router';

import './AlertBlock.scss';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import AlertBlockIcon from '../../../images/alertBlock.js';
import config from '../../../utilities/config';

const AlertBlock = (props) => {
    const problem = props.problem;

    let parts = problem.date.split("-");
    for (var i=0; i<3; i++) {
        parts[i] = parts[i].replace(/^0+/, '');
    }
    const dateString = parts[1] + "/" + parts[2] + "/" + parts[0].substring(2,4);

    const gallons = Number(problem.gallons).toLocaleString();
    const flushes = Number(props.flushCount).toLocaleString();

    return (
        <Container className="alertHistoryBlock" >
            <Row onClick={() => { 
            props.history.push(`/room-view?roomName=${problem.locationLabel}&building=${problem.building}`); 
            config.selectedBuilding = problem.building; 
            localStorage.setItem("mlBuildingID" , problem.building);
        }}>
                <Col xs={4} md={2}  className="alertBlockIcon">
                    <AlertBlockIcon className="sidebarIcon" fill={props.severityColor}/>
                </Col>
                <Col xs={8} md={10}  className="alertTable" >
                    <Table borderless responsive >
                        <thead>
                        </thead>
                        <tbody>
                            <tr className="alertBlockRow1">
                                <td>Date</td>
                                <td>Building</td>
                                <td>Location</td>
                                <td>Gallons Wasted</td>
                                <td>Flushes</td>
                            </tr>
                            <tr className="alertBlockRow2">
                                <td>{dateString}</td>
                                <td>{problem.buildingLabel}</td>
                                <td>{problem.locationLabel}</td>
                                <td>{gallons}</td>
                                <td>{flushes}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default withRouter(AlertBlock);